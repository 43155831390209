import React, { useState } from 'react'
import { useAppDispatch, useAppSelector } from '../redux.hooks'
import { useChatMessages } from '../useAddMessage/useChatMessages'
import { API_ENDPOINTS } from '../../constants/api.constant'
import Wallpaper from '../../assets/icons/wallpaper.svg'
import { ChatListInner } from '../../constants/styled.components.constants'
import { Box, Typography } from '@mui/material'
import { Attach } from '../../constants/icons.constants'
import API from '../../services/ajax.service'
import { addNewActiveCall } from '../../state/features/chat.feature'
import { IRenderedQuestionData } from '../../constants/interfaces.constant'

export const useChatHttp = () => {
  const dispatch = useAppDispatch()
  const [selectedFile, setSelectedFile] = useState<any>(null)
  const [fileColor, setFileColor] = useState<string>('')
  const { allQuestionsData } = useAppSelector((state) => state.chat) || []

  const { contractId, projectId } = useAppSelector((state) => state.user)
  const { addMessage, setMessages, callID } = useChatMessages()

  const [fileResponse, setFileResponse] = useState<any>(null)

  const handleFileDelete = () => {
    setSelectedFile(null)
    setFileColor('')
  }
  const getFileText = (text: string) => {
    return (
      <ChatListInner className='fileMessage'>
        <Box component='img' src={Wallpaper} alt='icon' />
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Typography variant='overline' style={{ padding: '0 8px' }}>
            {text}
          </Typography>
          <Attach />
        </Box>
      </ChatListInner>
    )
  }

  const handleMessageCalledActivity = async (
    note: string,
    files: File[] = [],
    selectedSubCategory: IRenderedQuestionData | undefined
  ) => {
    try {
      const messageData = {
        subDescription: selectedSubCategory?.text || '',
        note: note,
        projectId: projectId,
        contractId: contractId,
        serviceCallID: callID,
      }

      files.forEach((file) => {
        addMessage({
          className: 'file-message-wrapper',
          text: getFileText(file.name),
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      })

      setTimeout(() => {
        addMessage({
          title: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      }, 2500)

      const [activityResponse, fileResponse] = await Promise.allSettled([
        note
          ? API.getInstance().post(API_ENDPOINTS.SEND_ACTIVITY.replace(':serviceId', String(callID)), messageData, {
            serviceId: String(callID)
          })
          : null,
        Promise.all(
          files.map((file) => {
            const fileUploadFormData = new FormData()
            fileUploadFormData.append('file', file)
            return API.getInstance().post(
              API_ENDPOINTS.UPLOAD_FILES.replace(':serviceId', String(callID)),
              fileUploadFormData,
              {
                contractId: contractId,
                serviceCallID: callID?.toString() || '',
                fileName: file.name,
                sugDescription: selectedSubCategory?.text || ''
              }
            )
          })
        )
      ])
      setFileResponse(fileResponse?.status === 'fulfilled' ? fileResponse.value : null)

      const newActivityCall = {
        title: '',
        text: messageData.note,
        filename: null,
        isFile: 0,
        url: null,
        owner: 'user',
        timestamp: new Date().toISOString()
      }
      dispatch(addNewActiveCall({ callID, newActivityCall }))

      if (activityResponse.status === 'fulfilled' || fileResponse?.status === 'fulfilled') {
        addMessage({
          title: `בהמשך לפנייה ${callID} הודעתך נשלחה בהצלחה`,
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      } else {
        setTimeout(() => {
          addMessage({
            title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר',
            owner: 'chat',
            timestamp: Date.now(),
            subject: ''
          })
        }, 3000)
      }

      if (fileResponse?.status === 'fulfilled') {
        // const uploadedFileName = file ? file.name : ''

        setMessages((messages) => {
          const lastFileIndex = messages?.findLastIndex(({ className }: any) => className === 'file-message-wrapper')

          return messages.map((msg: any, msgIndex: number) => {
            if (lastFileIndex !== msgIndex) {
              return msg
            }
            const file = fileResponse.value[msgIndex]
            const path = file?.data?.path
            return {
              ...msg,

              text: (
                <ChatListInner key={msgIndex}>
                  <Box>
                    <Box component='img' src={Wallpaper} alt='icon' />

                    {fileResponse?.value.map((item, index) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between !important'
                        }}
                      >
                        <Typography variant='h6' style={{ padding: '0 8px' }}>
                          {item?.config?.params?.fileName}
                        </Typography>
                        <a key={index} className='file-link' href={item.data.path} target='_blank'>
                          להורדת קובץ
                        </a>
                      </Box>
                    ))}
                  </Box>
                </ChatListInner>
              )
            }
          })
        })
      }
      if (selectedFile) {
        handleFileDelete()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return {
    fileResponse,
    handleMessageCalledActivity,
    selectedFile,
    setSelectedFile,
    fileColor,
    setFileColor,
    handleFileDelete
  }
}
