import React, { useState } from 'react'
import { AmountPaidBubble, ModalBubbleAmountPaid } from '../../../../../constants/icons.constants'
import { PaymentsText, PaymentsTextNumber } from '../../../../../constants/styled.components.constants'
import UiModal from '../../../../UI/Modal/UiModal'
import ModalInnerCard from '../../modalInnerCard'
import { InnerContentWrapper, ItemIconsWrapper, ModalWrapper, TextDetail } from '../styles'
import { useAppSelector } from '../../../../../hooks/redux.hooks'
import { DetailButton } from '../../NextPayments/style'

const AmountPaid = () => {
  const [show, setShow] = useState(false)
  const { amountPaidData, paymentsInfoData } = useAppSelector((state) => state.payments)
  const totalPayment = amountPaidData?.reduce(
    (acc: number, obj: { totalPayment: string }) => acc + Number(obj.totalPayment),
    0
  )
  const interest = amountPaidData?.reduce((acc: number, obj: { interest: any }) => acc + Number(obj.interest), 0)
  const linkage = amountPaidData?.reduce((acc: number, obj: { linkage: any }) => acc + Number(obj.linkage), 0)
  const fund = amountPaidData?.reduce((acc: number, obj: { fund: any }) => acc + Number(obj.fund), 0)

  return (
    paymentsInfoData?.amountPaid && (
      <ItemIconsWrapper>
        <AmountPaidBubble />
        <InnerContentWrapper>
          <PaymentsText> הסכום ששולם </PaymentsText>
          <PaymentsTextNumber>{parseFloat(paymentsInfoData?.amountPaid).toLocaleString()} ₪</PaymentsTextNumber>

          <DetailButton onClick={() => setShow(true)}>פירוט</DetailButton>
        </InnerContentWrapper>

        <ModalWrapper>
          <UiModal
            handleClose={() => setShow(false)}
            open={show}
            icon={<ModalBubbleAmountPaid />}
            BubbleText={'הסכום ששולם'}
            className='amount-pay-modal'
          >
            <ModalInnerCard
              linkage={linkage?.toFixed(2)}
              totalPayment={totalPayment?.toFixed(2)}
              fund={fund?.toFixed(2)}
              interest={interest?.toFixed(2)}
            />
          </UiModal>
        </ModalWrapper>
      </ItemIconsWrapper>
    )
  )
}

export default AmountPaid
