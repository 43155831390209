import React from 'react'
import { Box, Grid, styled } from '@mui/material'
import Contract from './Contract'
import Guarantees from './Guarantees'
import OtherDocuments from './OtherDocuments'
import MobileAccordion from '../../UI/MobileAccordion/MobileAccordion'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import { theme } from '../../../constants/theme.constants'
import FaqPage from '../FAQ'

const Documents = () => {
  const { width, breakpoint } = UseResizeWindow()
  return (
    <>
      {width > breakpoint ? (
        <Box>
          <Box
            id='documents'
            sx={{
              padding: '72px 36px',
              display: 'flex',
              justifyContent: 'space-between',
              [theme.breakpoints.down('md')]: {
                display: 'block'
              }
            }}
          >
            <Grid style={{ padding: '0 0 0 1rem' }} xs={6} md={6} item>
              <Contract />
            </Grid>
            <Grid style={{ padding: '0 1rem 0 0' }} xs={6} md={6} item>
              <Guarantees />
              <OtherDocuments />
            </Grid>
          </Box>
          <Box>
            <FaqPage />
          </Box>
        </Box>
      ) : (
        <MobileWrapper>
          <MobileAccordion title={'חוזה'}>
            <Contract />
          </MobileAccordion>
          <MobileAccordion title={'ערבויות'}>
            <Guarantees />
          </MobileAccordion>
          <MobileAccordion title={'מסמכים נוספים'}>
            <OtherDocuments />
          </MobileAccordion>
          <MobileAccordion title={'שאלות ותשובות'}>
            <FaqPage />
          </MobileAccordion>
        </MobileWrapper>
      )}
    </>
  )
}

export default Documents

const MobileWrapper = styled('div')({
  display: 'block',
  '&.MuiAccordionDetails-root': {
    padding: '0px 5px 16px'
  },
  '& h2.mobile-view': {
    display: 'none'
  },
  '& .MuiAccordionDetails-root': {
    padding: '10px'
  }
})
