import { useMediaQuery, useTheme } from '@mui/material'
import React, { useState, useEffect } from 'react'

const breakpoint = 900

export const UseResizeWindow = () => {
  const theme = useTheme()
  const [width, setWidth] = useState(window.innerWidth)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResizeWindow)
    return () => {
      window.removeEventListener('resize', handleResizeWindow)
    }
  }, [])

  return { width, breakpoint, isMobile }
}
