import React from 'react'
import TenantsChangesItem from './TenantsChangesItem'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import { FlexWrapperTop, SectionTitle, WrapperList } from '../../../../constants/styled.components.constants'
import { IMaterialsChange } from '../../../../constants/interfaces.constant'
import { MainWrapper, NextMeetingInner, NextMeetingWrapper } from './style'

const ARRANGE_MEETING = 'לקביעת פגישה במשרדי דימרי באתר החברה'
const TENANTS_CHANGE = 'שינויי דיירים'
const MEETING_PAGE_LINK = 'https://www.dimri.co.il/%d7%a6%d7%95%d7%a8-%d7%a7%d7%a9%d7%a8/'

const TenantsChanges = () => {
  const tenantsData = useAppSelector((state) => state.finishingMaterials?.changes?.changes) || []

  return (
    <MainWrapper>
      <FlexWrapperTop className='mobile-view'>
        <SectionTitle>{TENANTS_CHANGE}</SectionTitle>
      </FlexWrapperTop>
      <NextMeetingWrapper>
        <NextMeetingInner>
          <a className='link-url' target='_blank' rel='noopener noreferrer' href={MEETING_PAGE_LINK}>
            {ARRANGE_MEETING}
          </a>
        </NextMeetingInner>
      </NextMeetingWrapper>
      <WrapperList>
        {tenantsData.map((data: IMaterialsChange, index: React.Key | number) => (
          <TenantsChangesItem key={index} changesPreview={data} />
        ))}
      </WrapperList>
    </MainWrapper>
  )
}

export default TenantsChanges
