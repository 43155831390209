import styled from 'styled-components'
import { theme } from '../../../constants/theme.constants'

export const NavBeforeLogin = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: 10,
  [theme.breakpoints.down('md')]: {
    justifyContent: 'right'
  }
})

export const LogoWrapper = styled('div')({
  width: '112px',
  height: '40px'
})

export const LogoImage = styled('img')({
  width: '100%',
  height: 'auto'
})
