import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import { FooterLink } from '../../../constants/styled.components.constants'
import { useThemeMobile } from '../../../hooks/themeMobile.hook'

function CompanyProfile() {
  const isMobile = useThemeMobile()

  return (
    <Box>
      <Typography variant='h4'>פרופיל חברה</Typography>
      <List>
        <ListItem sx={{ padding: '5px 0' }}>
          <FooterLink href='https://ir.dimri.co.il'> קשרי משקיעים </FooterLink>
        </ListItem>
        <ListItem sx={{ padding: '5px 0' }}>
          <FooterLink href='https://www.dimri.co.il/%d7%90%d7%97%d7%a8%d7%99%d7%95%d7%aa-%d7%aa%d7%90%d7%92%d7%99%d7%93%d7%99%d7%aa/'>
            {' '}
            אחריות תאגידית
          </FooterLink>
        </ListItem>
        <ListItem sx={{ padding: '5px 0' }}>
          <FooterLink href='https://www.dimri.co.il/%d7%94%d7%aa%d7%97%d7%93%d7%a9%d7%95%d7%aa-%d7%a2%d7%99%d7%a8%d7%95%d7%a0%d7%99%d7%aa/'>
            {' '}
            התחדשות עירונית{' '}
          </FooterLink>
        </ListItem>
      </List>
    </Box>
  )
}

export default CompanyProfile
