import React, { useCallback, useMemo, useState } from 'react'
import PhoneImg from '../../assets/icons/phoneLogin.svg'
import EmailImg from '../../assets/icons/emailLogin.svg'
import { Typography, Box } from '@mui/material'
import { goToPreviousStep, sendOtp } from '../../state/features/user.feature'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks'
import { debounce } from 'lodash'
import {
  LoginOptionWrapper,
  LoginSubTitle,
  LoginTitle,
  SecondLoginMainContainer,
  SecondStepChoiceBox,
  SecondStepLoginImg,
  StyledButton
} from './Login.styles'
import { useThemeMobile } from '../../hooks/themeMobile.hook'

const DEBOUNCE_TIME = 400

function SecondStepLogin() {
  const isMobile = useThemeMobile()

  const dispatch = useAppDispatch()
  const { firstStepLoginSession } = useAppSelector((state) => state.user)

  const [selectedMethod, setSelectedMethod] = useState<string | null>(null)

  const handleSelectedMethodDebounced = useMemo(
    () =>
      debounce(async (selectedMethod, signedGuid) => {
        try {
          await dispatch(sendOtp({ signedGuid, selectedOption: selectedMethod }))
        } catch (err) {
          console.error(err)
        }
      }, DEBOUNCE_TIME),
    [dispatch]
  )

  const handlePreviousPage = () => {
    dispatch(goToPreviousStep())
  }

  const handleSelectedMethod = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      const selectedMethod = event.currentTarget.id
      setSelectedMethod(selectedMethod)

      handleSelectedMethodDebounced(selectedMethod, firstStepLoginSession?.signedGuid)
    },
    [handleSelectedMethodDebounced, firstStepLoginSession?.signedGuid]
  )

  return (
    firstStepLoginSession && (
      <SecondLoginMainContainer isMobile={isMobile}>
        <Box>
          <LoginTitle isMobile={isMobile} className='bold'>
            נהדר!
          </LoginTitle>
          <LoginSubTitle className='custom-margin' isMobile={isMobile}>
            אנו רוצים לשלוח לך קוד. לאן לשלוח?
          </LoginSubTitle>
        </Box>

        <LoginOptionWrapper isMobile={isMobile}>
          <SecondStepChoiceBox
            isMobile={isMobile}
            onClick={handleSelectedMethod}
            id='phoneNumber'
            className={selectedMethod === 'phoneNumber' ? 'selected' : ''}
          >
            <SecondStepLoginImg src={PhoneImg} alt='phone icon' />
            <Typography sx={{ fontSize: isMobile ? '18px' : '24px' }} className='title'>
              לנייד
            </Typography>
            <Typography sx={{ fontSize: isMobile ? '20px' : '24px' }} className='subtitle'>
              {firstStepLoginSession?.loginOptions?.phoneNumber}
            </Typography>
          </SecondStepChoiceBox>

          <SecondStepChoiceBox
            isMobile={isMobile}
            onClick={handleSelectedMethod}
            className={selectedMethod === 'email' ? 'selected' : ''}
            id='email'
          >
            <SecondStepLoginImg src={EmailImg} alt='computer/email icon' />
            <Typography className='title'>לדוא״ל</Typography>
            <Typography className='subtitle'>{firstStepLoginSession?.loginOptions?.email}</Typography>
          </SecondStepChoiceBox>
        </LoginOptionWrapper>

        <StyledButton disableRipple onClick={handlePreviousPage}>
          כניסה עם משתמש אחר
        </StyledButton>
      </SecondLoginMainContainer>
    )
  )
}

export default SecondStepLogin
