import { useEffect, useRef } from 'react'

export const useChatScroll = (messages: any[]) => {
  const messageEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messageEl.current) {
      messageEl.current.scroll({ top: messageEl.current.scrollHeight, behavior: 'smooth' })
    }
  }, [messages])

  return messageEl
}
