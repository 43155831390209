import * as React from 'react'
import { Input as BaseInput } from '@mui/material'
import { InputElement } from '../../constants/styled.components.constants'
import { useEffect } from 'react'
import { OtpBox } from './Login.styles'
import { useThemeMobile } from '../../hooks/themeMobile.hook'

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasValue?: boolean
}

function OTP({
  separator,
  length,
  value,
  onChange
}: {
  separator: React.ReactNode
  length: number
  value: string
  onChange: React.Dispatch<React.SetStateAction<string>>
}) {
  const isMobile = useThemeMobile()
  const inputRefs = React.useRef<(HTMLInputElement | null)[]>(new Array(length).fill(null))

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0]?.focus()
    }
  }, [])

  const focusInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex]
    if (targetInput) {
      targetInput.focus()
    }
  }

  const selectInput = (targetIndex: number) => {
    const targetInput = inputRefs.current[targetIndex]
    if (targetInput) {
      targetInput.select()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
    const preventDefaultKeys = ['ArrowUp', 'ArrowDown', ' ', 'ArrowLeft', 'ArrowRight', 'Delete', 'Backspace']

    if (preventDefaultKeys.includes(event.key)) {
      event.preventDefault()
    }

    const updateFocus = (newIndex: number) => {
      focusInput(newIndex)
      selectInput(newIndex)
    }

    switch (event.key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case ' ':
        break

      case 'ArrowLeft':
      case 'Backspace':
        if (currentIndex > 0) {
          updateFocus(currentIndex - 1)
        }

        if (event.key === 'Backspace') {
          onChange((prevOtp) => prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1))
        }
        break

      case 'ArrowRight':
        if (currentIndex < length - 1) {
          updateFocus(currentIndex + 1)
        }
        break

      case 'Delete':
        onChange((prevOtp) => prevOtp.slice(0, currentIndex) + prevOtp.slice(currentIndex + 1))
        break

      default:
        break
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, currentIndex: number) => {
    const currentValue = event.target.value
    let indexToEnter = 0

    while (indexToEnter <= currentIndex) {
      if (inputRefs.current[indexToEnter]?.value && indexToEnter < currentIndex) {
        indexToEnter += 1
      } else {
        break
      }
    }
    onChange((prev) => {
      const otpArray = prev.split('')
      const lastValue = currentValue[currentValue.length - 1]
      otpArray[indexToEnter] = lastValue
      return otpArray.join('')
    })
    if (currentValue !== '') {
      if (currentIndex < length - 1) {
        focusInput(currentIndex + 1)
      }
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>, currentIndex: number) => {
    selectInput(currentIndex)
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, currentIndex: number) => {
    event.preventDefault()
    const clipboardData = event.clipboardData

    if (clipboardData.types.includes('text/plain')) {
      let pastedText = clipboardData.getData('text/plain')
      pastedText = pastedText.substring(0, length).trim()
      let indexToEnter = 0

      while (indexToEnter <= currentIndex) {
        if (inputRefs.current[indexToEnter]?.value && indexToEnter < currentIndex) {
          indexToEnter += 1
        } else {
          break
        }
      }

      const otpArray = value.split('')

      for (let i = indexToEnter; i < length; i += 1) {
        const lastValue = pastedText[i - indexToEnter] ?? ' '
        otpArray[i] = lastValue
      }

      onChange(otpArray.join(''))
    }
  }

  return (
    <OtpBox isMobile={isMobile}>
      {new Array(length).fill(null).map((_, index) => (
        <React.Fragment key={index}>
          <BaseInput
            type='number'
            inputProps={{
              'aria-label': `Digit ${index + 1} of OTP`,
              ref: (element: HTMLInputElement | null) => {
                inputRefs.current[index] = element
              },
              onKeyDown: (event) => handleKeyDown(event, index),
              onChange: (event) => handleChange(event, index),
              onClick: (event) => handleClick(event, index),
              onPaste: (event) => handlePaste(event, index),
              value: value[index] ?? ''
            }}
            sx={{
              '& input': {
                appearance: 'textfield',
                '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                  display: 'none'
                },
                '&::-moz-appearance': 'textfield'
              }
            }}
            inputComponent={InputElement as any}
            hasValue={!!value[index]}
          />
          {index === length - 1 ? null : separator}
        </React.Fragment>
      ))}
    </OtpBox>
  )
}

export default OTP
