/* eslint-disable functional/immutable-data */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { API_ENDPOINTS } from '../../constants/api.constant'
import { IFinishingMaterialsData, IFetchsuppliersData, ProgressStatusType, IMaterialSuppliersListData } from '../../constants/interfaces.constant'
import API from '../../services/ajax.service'

interface IFinishingMaterialsState {
  FinishingMaterialsData: IFinishingMaterialsData | null
  finishingMaterialsList: IMaterialSuppliersListData | null
  suppliersData: IFetchsuppliersData | null
  changes: IFinishingMaterialsData | null
  loading: boolean
  error: string | null
  progressStatusData: ProgressStatusType | null
  suppliersMaterialsMapper: Record<number, IMaterialSuppliersListData>;
}

const initialState: IFinishingMaterialsState = {
  progressStatusData: null,
  FinishingMaterialsData: null,
  finishingMaterialsList: null,
  suppliersData: null,
  suppliersMaterialsMapper: {},
  changes: null,
  loading: false,
  error: null
}

export const fetchPogressStatus = createAsyncThunk(
  'myApartment/fetchPogressStatus',
  (contractId: string, { rejectWithValue }) =>
    API.getInstance()
      .get(API_ENDPOINTS.GET_STATUS, { contractId })
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const fetchsuppliersData = createAsyncThunk(
  'finishingMaterials/fetchsuppliersData',
  (contractId: string, { rejectWithValue }) =>
    API.getInstance()
      .get(API_ENDPOINTS.SUPPLIERS, { contractId })
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const fetchMaterialSuppliersList = createAsyncThunk(
  'finishingMaterials/materialSuppliersList',
  async ({ contractId, subjectId }: { contractId: string; subjectId: number }, { rejectWithValue }) => {
    try {
      const response = await API.getInstance()
        .get(`${API_ENDPOINTS.MATERIAL_SUPPLIERS_LIST}?contractId=${contractId}&subjectId=${subjectId}`);
      
      return {
        data: [response.data],
        subjectId
      };
    } catch (err) {
      console.error('Error fetching material suppliers:', err);
      return rejectWithValue(err instanceof Error ? err.message : 'Failed to fetch suppliers');
    }
  }
);

export const fetchFinishingMaterials = createAsyncThunk(
  'finishingMaterials/fetchData',
  (contractId: string, { rejectWithValue }) =>
    API.getInstance()
      .get(API_ENDPOINTS.FINISHING_MATERIALS, { contractId })
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const finishingMaterialsSlice = createSlice({
  name: 'finishingMaterials',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPogressStatus.fulfilled, (state, { payload }) => {
      state.loading = false
      state.progressStatusData = payload
    })

    builder.addCase(fetchPogressStatus.rejected, (state, { error }) => {
      state.loading = false
      state.progressStatusData = null
      console.log({ error })
      state.error = ''
    })
    builder.addCase(fetchPogressStatus.pending, (state) => {
      state.loading = true
    })

    builder.addCase(fetchsuppliersData.fulfilled, (state, { payload }) => {
      state.loading = false      
      state.suppliersData = payload
    })

    builder.addCase(fetchsuppliersData.rejected, (state, { error }) => {
      state.loading = false
      state.suppliersData = null
      console.log({ error })
      state.error = ''
    })
    builder.addCase(fetchsuppliersData.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchFinishingMaterials.fulfilled, (state, { payload }) => {
      state.loading = false
      state.FinishingMaterialsData = payload
      state.changes = payload
    })
    builder.addCase(fetchFinishingMaterials.rejected, (state, { error }) => {
      state.loading = false
      state.FinishingMaterialsData = null
      console.log({ error })
      state.error = error.message || null
    })
    builder
    .addCase(fetchMaterialSuppliersList.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(fetchMaterialSuppliersList.fulfilled, (state, { payload }) => {
      state.loading = false;
      if (payload) {
        state.finishingMaterialsList = payload;
        state.suppliersMaterialsMapper[payload.subjectId] = {
          data: payload.data || [],
          subjectId: payload.subjectId
        };
      }
    })
    .addCase(fetchMaterialSuppliersList.rejected, (state, { error }) => {
      state.loading = false
      state.error = error.message || 'Failed to fetch suppliers'
    });
  }
})

export default finishingMaterialsSlice.reducer
