export enum PaymentKeys {
  RECEIPT_TOTAL_AMOUNT,
  PROPERTY_ACQUISITION_INTEREST,
  PROPERTY_ACQUISITION_LINKAGE,
  VAT,
  TOTAL_PAYMENT
}
export const getTooltipKeyString = (tooltipKey: PaymentKeys) => {
  switch (tooltipKey) {
    case PaymentKeys.RECEIPT_TOTAL_AMOUNT:
      return 'receipt_total_amount'
    case PaymentKeys.PROPERTY_ACQUISITION_INTEREST:
      return 'property_acquisition_interest'
    case PaymentKeys.PROPERTY_ACQUISITION_LINKAGE:
      return 'property_acquisition_linkage'
    default:
      console.error('Invalid tooltip key:', tooltipKey)
      return ''
  }
}
export const getTooltipCardText = (tooltipKey: PaymentKeys) => {
  switch (tooltipKey) {
    case PaymentKeys.RECEIPT_TOTAL_AMOUNT:
      return 'סכום קרן'
    case PaymentKeys.PROPERTY_ACQUISITION_INTEREST:
      return 'ריבית'
    case PaymentKeys.PROPERTY_ACQUISITION_LINKAGE:
      return 'הצמדה'
    case PaymentKeys.VAT:
      return 'מע״מ'
    case PaymentKeys.TOTAL_PAYMENT:
      return 'סה״כ'
    default:
      console.error('Invalid tooltip key:', tooltipKey)
      return ''
  }
}
