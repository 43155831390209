import React from 'react'
/*components*/
import { List, ListItem, ListItemText, styled } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'
import { QuestionType } from '../../../../constants/interfaces.constant'

type Props = {
  categoryList?: [] | any
  selectedSubCategory?: any
  onSubCategoryClick?: any
  selectedCategory?: any
  onCategoryClick?: any
  selectedMainCategory?: any
  onMainCategoryClick?: any
}
function Category({
  categoryList,
  onCategoryClick,
  selectedCategory,
  onSubCategoryClick,
  selectedSubCategory,
  selectedMainCategory,
  onMainCategoryClick
}: Props) {
  console.log(selectedMainCategory)
  return (
    <>
      {categoryList && (
        <React.Fragment>
          <MainWrapper className={selectedSubCategory ? 'chatstep' : ''}>
            <List sx={style} component='nav' aria-label='mailbox folders'>
              {!selectedCategory &&
                categoryList.map((item: any, index: number) => (
                  <ListItem button="true" key={index} onClick={() => {
                    if (selectedMainCategory || item?.type === QuestionType.GENERAL) {
                      onCategoryClick(item)
                    } else {
                      onMainCategoryClick(item)
                    }
                  }}
                    sx={listItemStyle} >
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              {selectedCategory &&
                !selectedSubCategory &&
                categoryList
                  .find(({ id }: any) => id === selectedCategory)
                  ?.items.map((item: any, index: number) => (
                    <ListItem button="true" key={index} sx={listItemStyle}>
                      <ListItemText onClick={() => {
                        onSubCategoryClick(item, categoryList
                          .find(({ id }: any) => id === selectedCategory)?.text)
                      }} primary={item.text} />
                    </ListItem>
                  ))}
            </List>
          </MainWrapper>
        </React.Fragment>
      )}
    </>
  )
}

export default Category

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
}

const listItemStyle = {
  background: Colors.white,
  borderRadius: "8.54px",
  my: "3.2px",
  height: "34px",
  textAlign: "start",
  padding: "8px 6px 0 0"
}
const MainWrapper = styled('div')({
  paddingBottom: "48px",
  '&.chatstep': {
    bottom: '6rem'
  },
  '& nav': {
    background: 'none',
    padding: '0',
    '& .MuiButtonBase-root.MuiListItem-gutters': {
      direction: 'rtl',
      textAlign: 'right',
      background: '#fff',
      borderRadius: '10px',
      margin: '4px 0'
    },
    '& hr': {
      display: 'none'
    },
    '& .MuiListItem-root': {
      paddingTop: '3px',
      paddingBottom: '3px'
    }
  }
})
