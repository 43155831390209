import React, { useMemo } from 'react'
import FirstStepLogin from './FirstStepLogin'
import SecondStepLogin from './SecondStepLogin'
import { useAppSelector } from '../../hooks/redux.hooks'
import ThirdStepLogin from './ThirdStepLogin'
import { FormEnterWrapper } from './Login.styles'
import { useThemeMobile } from '../../hooks/themeMobile.hook'

const privateArea = () => {
  const isMobile = useThemeMobile()

  const { currentStep } = useAppSelector((state) => state.user)

  const renderStepComponent = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <FirstStepLogin />
      case 2:
        return <SecondStepLogin />
      case 3:
        return <ThirdStepLogin />
      default:
        return null
    }
  }, [currentStep])

  return <FormEnterWrapper isMobile={isMobile}>{renderStepComponent}</FormEnterWrapper>
}

export default privateArea
