import React, { Dispatch, SetStateAction } from 'react'
import { CustomCloseIcon, StyledCard, StyledCardContent, StyledModal } from './style'
import { IMaterialsChange } from '../../../../../constants/interfaces.constant'
import ModalContent from './ModalContent'

interface ITenantsChangesModalProps {
  changesPreview: IMaterialsChange['changes']
  open: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

const TenantsChangesModal = ({ open, setOpenModal, changesPreview }: ITenantsChangesModalProps) => {
  return (
    <StyledModal
      open={open}
      onClose={() => {
        setOpenModal(false)
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledCard>
        <CustomCloseIcon onClick={() => setOpenModal(false)} />
        <StyledCardContent>
          <ModalContent changesPreview={changesPreview} />
        </StyledCardContent>
      </StyledCard>
    </StyledModal>
  )
}

export default TenantsChangesModal
