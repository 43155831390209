import React, { useMemo, useState } from 'react'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import Loader from '../../../UI/Loader/Loader'
import { Box, IconButton, Stack, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'

import {
  AmountSubTitle,
  AmountTitle,
  CustomDivider,
  DetailBox,
  DetailButton,
  InnerDetailBox,
  InnerWrapper,
  MainWrapper,
  PaymentAmountResult,
  PaymentDetailsCardStaticWrapper,
  PaymentMoreDetailsCardStaticWrapper,
  SubTitle
} from './style'
import CloseIcon from '../../../../assets/icons/Close.icon'
import { PaymentCardLabel, PaymentInfoLine, PaymentInfoTooltipLine } from './components'
import { PaymentKeys } from './consts'
import PaymentsNavigationButton from './paymentsNavigationButton.tsx'

function NextPayment() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const { amountPaidData, futurePaymentList, loading } = useAppSelector((state) => state.payments)
  const [openTooltipKey, setOpenTooltipKey] = useState<string | null>(null)
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<number | null>(null)
  const [visibleIndex, setVisibleIndex] = useState(0)

  const allPaymentListData = useMemo(() => {
    const parsedFuturePaymentListToAmountPaidData = futurePaymentList.map((futurePayment) => ({
      receptionNumber: '-',
      date: futurePayment.paymentDate,
      fund: futurePayment.amount,
      linkage: futurePayment.linkage,
      interest: futurePayment.interest || '0',
      vat: futurePayment.vat || '0',
      totalPayment: futurePayment.total,
      paidFor: futurePayment.futurePaymentFor
    }))
    return [...amountPaidData, ...parsedFuturePaymentListToAmountPaidData]
  }, [amountPaidData, futurePaymentList])

  const handlePrevClick = () => {
    setSelectedPaymentIndex(null)
    setVisibleIndex((prevIndex) => Math.max(prevIndex - 1, 0))
  }

  const handleNextClick = () => {
    setSelectedPaymentIndex(null)
    allPaymentListData.length && setVisibleIndex((prevIndex) => Math.min(prevIndex + 1, allPaymentListData.length - 1))
  }

  const formatPaymentAmount = (paymentItem: string) => {
    const totalPayment = parseFloat(paymentItem)
    return totalPayment < 0 ? `(${Math.abs(totalPayment).toLocaleString()} ₪)` : `${totalPayment.toLocaleString()} ₪`
  }

  if (loading) return <Loader />

  return (
    <MainWrapper>
      <InnerWrapper>
        {allPaymentListData
          .slice(visibleIndex, isMobile ? allPaymentListData.length : visibleIndex + 6)
          .map((paymentItem, index) => (
            <Box
              key={index}
              sx={{
                position: 'relative',
                minWidth: selectedPaymentIndex === index ? '270px' : '152px',
                marginLeft: '0.75rem',
                [theme.breakpoints.down('md')]: {
                  minWidth: '326px',
                  marginBottom: '0.688rem'
                }
              }}
            >
              {selectedPaymentIndex === index ? (
                <DetailBox>
                  <InnerDetailBox>
                    <PaymentCardLabel
                      date={paymentItem.date}
                      receptionNumber={paymentItem.receptionNumber}
                      selected={selectedPaymentIndex === index}
                    />
                    <Tooltip
                      title={paymentItem.paidFor && paymentItem.paidFor.length > 14 ? paymentItem.paidFor : ''}
                      arrow={false}
                      placement='top'
                      PopperProps={{
                        sx: {
                          [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                            marginBottom: '0px'
                          },
                          '& .MuiTooltip-tooltip': {
                            backgroundColor: 'white',
                            color: '#242424',
                            fontSize: '1rem',
                            border: '1px solid #0053A3',
                            borderRadius: '4px'
                          }
                        }
                      }}
                    >
                      <SubTitle className='truncate-text'>{paymentItem?.paidFor}</SubTitle>
                    </Tooltip>
                  </InnerDetailBox>
                  <IconButton
                    sx={{
                      position: 'absolute',
                      left: isMobile ? '24px' : '14px',
                      top: isMobile ? '40px' : '14px',
                      '&:hover': { backgroundColor: 'transparent' }
                    }}
                    onClick={() => setSelectedPaymentIndex(null)}
                  >
                    <CloseIcon />
                  </IconButton>
                  <PaymentMoreDetailsCardStaticWrapper>
                    <PaymentInfoTooltipLine
                      paymentItem={paymentItem}
                      openTooltipKey={openTooltipKey}
                      setOpenTooltipKey={setOpenTooltipKey}
                      paymentKey={PaymentKeys.RECEIPT_TOTAL_AMOUNT}
                    />
                    <PaymentInfoTooltipLine
                      paymentItem={paymentItem}
                      openTooltipKey={openTooltipKey}
                      setOpenTooltipKey={setOpenTooltipKey}
                      paymentKey={PaymentKeys.PROPERTY_ACQUISITION_INTEREST}
                    />
                    <PaymentInfoTooltipLine
                      paymentItem={paymentItem}
                      openTooltipKey={openTooltipKey}
                      setOpenTooltipKey={setOpenTooltipKey}
                      paymentKey={PaymentKeys.PROPERTY_ACQUISITION_LINKAGE}
                    />
                    <CustomDivider />
                    <PaymentInfoLine paymentKey={PaymentKeys.TOTAL_PAYMENT} paymentItem={paymentItem} />
                  </PaymentMoreDetailsCardStaticWrapper>
                </DetailBox>
              ) : (
                <PaymentDetailsCardStaticWrapper>
                  <Stack direction='row' justifyContent={isMobile ? 'space-between' : 'none'} alignItems="flex-start" >
                    <PaymentCardLabel date={paymentItem.date} receptionNumber={paymentItem.receptionNumber} selected={selectedPaymentIndex === index} />
                    {isMobile && <DetailButton onClick={() => setSelectedPaymentIndex(index)}>פירוט</DetailButton>}
                  </Stack>
                  <Stack direction={isMobile ? 'row' : 'column'} justifyContent={isMobile ? 'space-between' : 'start'} alignItems={isMobile ? 'flex-end' : ''}>
                    <Box>
                      <AmountTitle>סכום כולל מע״מ</AmountTitle>
                      <PaymentAmountResult coloredText={paymentItem.date === '01.01.1900'}>
                        {formatPaymentAmount(paymentItem.totalPayment)}
                      </PaymentAmountResult>
                    </Box>
                    <Box>
                      <AmountTitle>משולם עבור</AmountTitle>
                      <Tooltip
                        title={paymentItem.paidFor && paymentItem.paidFor.length > 14 ? paymentItem.paidFor : ''}
                        arrow={false}
                        placement='top'
                        PopperProps={{
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
                              marginBottom: '0px'
                            },
                            '& .MuiTooltip-tooltip': {
                              backgroundColor: 'white',
                              color: '#242424',
                              fontSize: '1rem',
                              border: '1px solid #0053A3',
                              borderRadius: '4px'
                            }
                          }
                        }}
                      >
                        <AmountSubTitle className='truncate-text'>{paymentItem?.paidFor}</AmountSubTitle>
                      </Tooltip>
                    </Box>
                  </Stack>
                  {!isMobile && <DetailButton onClick={() => setSelectedPaymentIndex(index)}>פירוט</DetailButton>}
                </PaymentDetailsCardStaticWrapper>
              )}
            </Box>
          ))}
      </InnerWrapper>

      {!isMobile && allPaymentListData.length >= 6 && (
        <>
          <PaymentsNavigationButton
            handleButtonClick={handleNextClick}
            isDisabled={visibleIndex === allPaymentListData.length - 6}
          />
          <PaymentsNavigationButton
            handleButtonClick={handlePrevClick}
            isDisabled={visibleIndex === 0}
            isFlipped={true}
          />
        </>
      )}
    </MainWrapper>
  )
}

export default NextPayment
