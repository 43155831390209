import React from 'react'

interface IArrowIconProps {
  width?: string
  height?: string
  fill?: string
}

const ArrowIcon: React.FC<IArrowIconProps> = ({ height = '9', width = '20', fill = 'black' }) => (
  <svg width={width} height={height} viewBox='0 0 15 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      id='Arrow 7'
      d='M6.72331 8.20717C7.11384 8.59769 7.747 8.59769 8.13753 8.20717L14.5015 1.84321C14.892 1.45268 14.892 0.819517 14.5015 0.428993C14.111 0.0384682 13.4778 0.0384683 13.0873 0.428993L7.43042 6.08585L1.77356 0.428994C1.38304 0.0384694 0.749875 0.0384694 0.359351 0.428994C-0.0311735 0.819518 -0.0311734 1.45268 0.359351 1.84321L6.72331 8.20717ZM6.43042 7.00006L6.43042 7.50006L8.43042 7.50006L8.43042 7.00006L6.43042 7.00006Z'
      fill={fill}
    />
  </svg>
)

export default ArrowIcon
