import { styled } from '@mui/material'
import { Colors } from '../../../constants/styles.constants'
import { theme } from '../../../constants/theme.constants'

export const MainWrapper = styled('div')({
  padding: '4rem 0 3rem 0',
  margin: '0 2rem',
  borderBottom: `2px solid ${Colors.lightBrownSecond}`
})
export const MobileBackGroundWrapper = styled('div')({
  background: '#EAF2FA',
  height: '160px',
  padding: '55px 17px 0 17px',
  borderRadius: '6px',
  POSITION: 'ABSOLUTE',
  WIDTH: '100%',
  LEFT: 0,
  RIGHT: 0,
  MARGIN: 'auto'
})
export const MainWrapperInner = styled('div')({
  position: 'relative',
  padding: '3rem 0 0 0',

  [theme.breakpoints.down('md')]: {
    display: 'block',
    padding: '1rem 0'
  }
})
export const InnerWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})
export const StepProgress = styled('div')({
  position: 'relative',
  width: '28%',
  margin: '0 4px',
  '&.current-step': {
    [theme.breakpoints.down('md')]: {
      width: '100%',
      margin: '0'
    }
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    margin: '0'
  }
})
export const ProgressText = styled('span')({
  display: 'block',
  fontSize: '18px',
  fontWeight: '300',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  color: ' #49382A',
  padding: '0 0 10px 0'
})

export const InnerContent = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '8px 0rem',
  margin: '8px 0 0 0'
})
export const Dot = styled('span')({
  height: '25px',
  width: '25px',
  backgroundColor: Colors.lightBrownSecond,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const InnerDot = styled('span')({
  height: '18px',
  width: '18px',
  backgroundColor: Colors.orange,
  borderRadius: '50%',
  margin: '2px 1px 1px 1px'
})
export const Text = styled('p')({
  fontSize: '18px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'right',
  color: '#49382a',
  padding: '0 1rem',
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '0'
  }
})
export const CurrentStepText = styled('p')({
  display: 'none',
  fontSize: '15px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'right',
  color: Colors.black,
  padding: '0 1rem'
})
export const TempWrapper = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column'
})

export const MobileWrapper = styled('div')({
  width: '95%',
  margin: '5rem auto 0 auto',
  '& .slick-slider.slick-initialized': {
    '.slick-active.slick-current': {
      width: '100%'
    },
    '.slick-arrow': {
      width: '10px',
      color: 'black!important',
      zIndex: 99,
      top: '65%',

      '&.slick-prev': {
        left: '5px',
        '&:before': {
          content: '"<" !important',
          color: 'black !important',
          fontSize: '15px'
        }
      },
      '&.slick-next': {
        right: '5px',
        left: 'unset !important',
        '&:before ': {
          content: '">" !important',
          color: 'black !important',
          fontSize: '15px'
        }
      }
    }
  }
})
export const ImageBox = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '250px',
  height: '150px',
  margin: 'auto',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    height: '100px'
  }
})
export const Image = styled('img')({
  width: '108px',
  height: '85px',
  margin: 'auto',
  zIndex: '9',
  '&.current': {
    transform: 'scale(1.5)',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(1.5)'
    }
  },
  '&.small': {
    filter: 'grayscale(1)',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(1.5)'
    }
  },
  [theme.breakpoints.down('md')]: {
    transform: 'scale(1.5)',
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    top: '2rem'
  }
})
