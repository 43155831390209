import { styled } from '@mui/material'
import { Colors } from '../../../../../constants/styles.constants'
import { theme } from '../../../../../constants/theme.constants'

export const ToolTipContent = styled('div')({
  display: 'flex',
  alignItems: 'flex-start'
})

export const InnerCard = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  ':nth-child(3)': {
    borderBottom: `2px solid ${Colors.softBrown}`,
    padding: '0px 0 10px 0'
  },
  ':nth-child(4)': {
    padding: '10px 0 0px 0'
  }
})

export const CardText = styled('p')({
  fontSize: '25px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: Colors.darkBrown,
  padding: ' 0 0 4px 0',
  '&.total-upcomingPayments': {
    color: Colors.orange
  },
  '&.bold-section': {
    fontWeight: 'bold'
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px'
  }
})
