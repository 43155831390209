import { Box } from '@mui/material'
import { styled } from 'styled-components'
import { theme } from '../../../../../../constants/theme.constants'

export const MainContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '800px',
  height: '591px',
  borderRadius: '8px',
  direction: 'rtl',
  [theme.breakpoints.down('md')]: {
    width: '326px',
    height: '712px'
  }
})

export const GeneralDataBox = styled(Box)({
  overflow: 'auto',
  paddingRight: '18px',
  '&::-webkit-scrollbar-track ': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
    backgroundColor: '#EAF2FA'
  },
  '&::-webkit-scrollbar': {
    width: '9px',
    backgroundColor: '#f5f5f5',
    margin: '0 8px',
    [theme.breakpoints.down('md')]: {
      width: '4px'
    }
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    backgroundColor: '#0053A3'
  }
})
