import React from 'react'
import { LoginErrorMessageBox } from '../../constants/styled.components.constants'
import ErrorIcon from '../../assets/icons/loginError.svg'
import { Typography } from '@mui/material'

interface ErrorLoginProps {
  loginError: string
  customTop?: string
  customRight?: string
}

const ErrorLogin: React.FC<ErrorLoginProps> = ({ loginError, customTop, customRight }) => {
  return (
    <LoginErrorMessageBox className='error-container' sx={{ paddingTop: customTop, paddingRight: customRight }}>
      <img src={ErrorIcon} />
      <Typography>{loginError}</Typography>
    </LoginErrorMessageBox>
  )
}

export default ErrorLogin
