import React from 'react'
import IconButton from '@mui/material/IconButton'
import { ChevronLeft } from '../../../../../constants/icons.constants'
import { Box } from '@mui/material'

interface NavigationButtonProps {
  handleButtonClick: () => void
  isDisabled: boolean
  isFlipped?: boolean
}

const PaymentsNavigationButton: React.FC<NavigationButtonProps> = ({ handleButtonClick, isDisabled, isFlipped }) => {
  return (
    <Box position={'absolute'} top='50%' right='119px' sx={{ transform: isFlipped ? 'scaleX(-1)' : 'scaleX(1)' }}>
      <IconButton
        sx={{
          height: '32px',
          width: '32px',
          borderRadius: '50%',
          background: '#4295E5',
          '&:hover': { background: '#4295E5' },
          '&:disabled': {
            background: '#A0A0A0',
            cursor: 'not-allowed'
          },
          display: isDisabled ? 'none' : 'flex'
        }}
        onClick={handleButtonClick}
        disabled={isDisabled}
      >
        <ChevronLeft width='5' height='10' fill='white' />
      </IconButton>
    </Box>
  )
}

export default PaymentsNavigationButton
