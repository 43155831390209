import React from 'react'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import { PaymentCard } from './style'
import ModalLine from './modalLine'

interface IBalanceProps {
  fund?: string
  linkage?: string
  interest?: string
  vat?: string
  totalPayment?: string
}

function ModalInnerCard({ fund, linkage, interest, vat, totalPayment }: IBalanceProps) {
  const tooltips = useAppSelector((state) => state.global?.tooltipsList)

  const getTooltipTextValueByKey = (tooltipKey: string) => {
    return tooltips?.find((tooltip) => {
      tooltip.key === tooltipKey})?.value || ''
  }

  return (
    <PaymentCard>
      <ModalLine
        moneyAmount={fund}
        tooltipTitle={getTooltipTextValueByKey('receipt_total_amount')}
        text='סכום קרן'
        hasTooltip
      />
      <ModalLine
        moneyAmount={interest}
        tooltipTitle={getTooltipTextValueByKey('property_acquisition_interest')}
        text='ריבית'
        hasTooltip
      />
      <ModalLine
        moneyAmount={linkage}
        tooltipTitle={getTooltipTextValueByKey('property_acquisition_linkage')}
        text='הצמדה'
        hasTooltip
      />
      {vat && <ModalLine text='מע”מ' moneyAmount={vat} />}
      <ModalLine text='סה”כ' moneyAmount={totalPayment} />
    </PaymentCard>
  )
}

export default ModalInnerCard
