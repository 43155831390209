import React from 'react'
import { Box } from '@mui/material'
import { InnerAccordion } from './style'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  withScroll?: boolean
}

const TabPanel = ({ children, value, index, withScroll = false }: TabPanelProps) => {
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <Box sx={{ margin: '16px 0 0' }}>
        <InnerAccordion withScroll={!!withScroll}>{children}</InnerAccordion>
      </Box>
    </div>
  )
}

export default TabPanel
