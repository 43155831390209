import { styled } from '@mui/material/styles'
import { Box, Typography } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'

export const SubjectContainer = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  padding: '42px 0 0 50px',
  position: 'relative'
}))

export const SubjectWrapper = styled(Box, {
  shouldForwardProp: (prop: string) => !['isMobile', 'isActive'].includes(prop)
})<{
  isMobile?: boolean
  isActive?: boolean
}>(({ isMobile, isActive }) => ({
  margin: '0 0 10px 10px',
  cursor: 'pointer',
  borderRadius: '8px',
  border: `1px solid ${isActive ? Colors.lightBlueBorder : Colors.lightBlueBorder}`,
  padding: isMobile ? '0 16px' : '8px 16px',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: isActive ? Colors.lightBlueBackground : 'transparent',
  '&:hover': {
    border: `1px solid ${Colors.lightBlueBorder}`,
    background: Colors.lightBlueBackground
  }
}))

export const IconWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  position: 'absolute',
  left: 0,
  height: '30px',
  width: '30px',
  background: Colors.BorderGrey,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}))

export const SubjectText = styled(Typography, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  fontSize: isMobile ? '20px' : '22px',
  fontWeight: isMobile ? 400 : 600,
  color: Colors.brown
}))
