import { styled } from '@mui/material'
import { theme } from '../../../../constants/theme.constants'
import { Colors } from '../../../../constants/styles.constants'

export const CircularProgressbarWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  padding: '0 1rem',
  textAlign: 'center',
  [theme.breakpoints.down('md')]: {
    display: 'block',
    margin: 'auto',
    padding: '0rem'
  },
  '& .CircularProgressbar': {
    '& .CircularProgressbar-trail': {
      stroke: Colors.white
    }
  }
})
export const CircularProgressInner = styled('div')({
  position: 'relative',
  margin: 'auto',
  [theme.breakpoints.down('sm')]: {
    width: '75%'
  }
})

export const ListItem = styled('li')({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  padding: '1rem 0.5rem',
  width: '20%',
  [theme.breakpoints.down('xl')]: {
    width: '25%',
    padding: '0px'
  },
  [theme.breakpoints.down('lg')]: {
    margin: 'auto'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    padding: '1rem'
  }
})
export const ProgressbarTextWrapper = styled('div')({
  position: 'absolute',
  top: '10%',
  left: '0',
  right: '0',
  margin: 'auto',
  transform: 'translate(-20%, 100%)',
  textAlign: 'right',
  width: '60%',
  [theme.breakpoints.down('xl')]: {
    top: '5%'
  },
  [theme.breakpoints.down('lg')]: {
    top: '5%',
    width: '80%',
    left: '-35px'
  },
  [theme.breakpoints.down('md')]: {
    top: '10%',
    left: '-9vw'
  }
})
