import React, { useState } from 'react'
import { AmountLeftToPayBubble, ModalBubblePayments} from '../../../../../constants/icons.constants'
import { PaymentsText, PaymentsTextNumber } from '../../../../../constants/styled.components.constants'
import { useAppSelector } from '../../../../../hooks/redux.hooks'
import UiModal from '../../../../UI/Modal/UiModal'
import ModalInnerCard from '../../modalInnerCard'
import { InnerContentWrapper, ItemIconsWrapper, ModalWrapper, TextDetail } from '../styles'
import { DetailButton } from '../../NextPayments/style'

const AmountLeftToPay = () => {
  const { futurePaymentList, paymentsInfoData } = useAppSelector((state) => state.payments)
  const [show, setShow] = useState(false)
  const totalPayment = futurePaymentList?.reduce(
    (acc: number, obj: { total: string }) => acc + Number(obj.total),
    0
  )
  const interest = futurePaymentList?.reduce((acc: number, obj: { interest: any }) => acc + Number(obj.interest), 0)
  const linkage = futurePaymentList?.reduce((acc: number, obj: { linkage: any }) => acc + Number(obj.linkage), 0)
  const fund = futurePaymentList?.reduce((acc: number, obj: { amount: any }) => acc + Number(obj.amount), 0)

  return (
    paymentsInfoData?.amountLeftToPay && (
      <ItemIconsWrapper>
        <AmountLeftToPayBubble />
        <InnerContentWrapper>
          <PaymentsText> יתרה לסיום חוזה </PaymentsText>
          <PaymentsTextNumber>{parseFloat(paymentsInfoData?.amountLeftToPay).toLocaleString()} ₪ </PaymentsTextNumber>
          <DetailButton onClick={() => setShow(true)}>פירוט</DetailButton>
        </InnerContentWrapper>

        <ModalWrapper>
          <UiModal
            handleClose={() => setShow(false)}
            open={show}
            icon={<ModalBubblePayments />}
            BubbleText={'יתרה לסיום חוזה'}
            className='amount-pay-modal'
          >
            <ModalInnerCard
              linkage={linkage?.toFixed(2)}
              totalPayment={totalPayment?.toFixed(2)}
              fund={fund?.toFixed(2)}
              interest={interest?.toFixed(2)}
            />
          </UiModal>
        </ModalWrapper>

      </ItemIconsWrapper>
    )
  )
}

export default AmountLeftToPay
