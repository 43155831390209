export const idValidation = (id: string): boolean => {
  if (id.length !== 9 || isNaN(Number(id))) return false

  let sum = 0
  for (let i = 0; i < id.length; i++) {
    const digit = parseInt(id[i], 10)
    const step = digit * ((i % 2) + 1)
    sum += step > 9 ? step - 9 : step
  }

  return sum % 10 === 0
}

export const passportValidation = (passport: string): boolean => /^[a-zA-Z0-9]{6,9}$/.test(passport)

export const getErrorMessage = (type: string, data: string) => {
  let errorMessage = ''
  if (type === 'personalId') {
    errorMessage = idValidation(data) ? '' : 'מספר ת.ז שגוי, נסו שוב  '
  } else if (type === 'passport') {
    errorMessage = passportValidation(data) ? '' : 'מספר דרכון שגוי, נסו שוב'
  } else {
    errorMessage = idValidation(data) ? '' : 'מספר ח.פ שגוי, נסו שוב'
  }
  return errorMessage
}
