import React from 'react'
import Footer from '../Layout/Footer/Footer.component'
import Box from '@mui/material/Box'
import Hero from '../../assets/images/home.jpg'
import PrivateArea from './privateArea'
import { HeroImage, HeroWrapper } from './Login.styles'

function login() {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center'
        }}
      >
        <HeroWrapper>
          <PrivateArea />
          <HeroImage src={Hero} alt='cover image' />
        </HeroWrapper>
      </Box>
      <Footer />
    </>
  )
}

export default login
