import React from 'react'

interface IArrowIconProps {
  width?: string
  height?: string
  fill?: string
}

const SearchIcon: React.FC<IArrowIconProps> = ({ height = '28', width = '28', fill = 'none' }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 28 28' fill={fill}>
    <path
      d='M24.1617 22.505L20.195 18.55C21.4748 16.9195 22.1692 14.9061 22.1667 12.8333C22.1667 10.9874 21.6193 9.18287 20.5937 7.64802C19.5682 6.11316 18.1105 4.91688 16.405 4.21046C14.6996 3.50404 12.823 3.31921 11.0125 3.67934C9.20201 4.03947 7.53896 4.92838 6.23367 6.23367C4.92838 7.53896 4.03947 9.20201 3.67934 11.0125C3.31921 12.823 3.50404 14.6996 4.21046 16.405C4.91688 18.1105 6.11316 19.5682 7.64802 20.5937C9.18287 21.6193 10.9874 22.1667 12.8333 22.1667C14.9061 22.1692 16.9195 21.4748 18.55 20.195L22.505 24.1617C22.6135 24.271 22.7425 24.3578 22.8847 24.417C23.0268 24.4763 23.1793 24.5068 23.3333 24.5068C23.4874 24.5068 23.6398 24.4763 23.782 24.417C23.9242 24.3578 24.0532 24.271 24.1617 24.1617C24.271 24.0532 24.3578 23.9242 24.417 23.782C24.4763 23.6398 24.5068 23.4874 24.5068 23.3333C24.5068 23.1793 24.4763 23.0268 24.417 22.8847C24.3578 22.7425 24.271 22.6135 24.1617 22.505ZM5.83334 12.8333C5.83334 11.4489 6.24388 10.0955 7.01305 8.94435C7.78222 7.7932 8.87547 6.89599 10.1546 6.36618C11.4336 5.83637 12.8411 5.69774 14.199 5.96784C15.5568 6.23794 16.8041 6.90462 17.7831 7.88359C18.7621 8.86256 19.4287 10.1098 19.6988 11.4677C19.9689 12.8256 19.8303 14.233 19.3005 15.5121C18.7707 16.7912 17.8735 17.8845 16.7223 18.6536C15.5712 19.4228 14.2178 19.8333 12.8333 19.8333C10.9768 19.8333 9.19634 19.0958 7.88359 17.7831C6.57084 16.4703 5.83334 14.6899 5.83334 12.8333Z'
      fill='#46392C'
    />
  </svg>
)

export default SearchIcon
