import { AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import React from 'react'

import ArrowIcon from '../../../../assets/icons/arrow'

import { AccordionSubTitle, AccordionTitle, CustomAccordionContainer } from './styles'
import { IAccordionProps } from './types'
import { useThemeMobile } from '../../../../hooks/themeMobile.hook'

const CustomAccordion: React.FC<IAccordionProps> = ({
  title,
  details,
  backgroundColor = '#EEECE7',
  titleColor,
  detailsColor,
  containerPadding = '0px',
  arrowIcon = <ArrowIcon fill='#2D3648' />
}) => {
  const isMobile = useThemeMobile()
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <CustomAccordionContainer
      elevation={0}
      expanded={expanded === 'panelOpen'}
      onChange={handleChange('panelOpen')}
      sx={{ background: backgroundColor, padding: containerPadding }}
    >
      <AccordionSummary
        expandIcon={arrowIcon}
        sx={{ height: '55px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <AccordionTitle isMobile={isMobile} titleColor={titleColor || '#2D3648'} variant={'body2'}>
          {title}
        </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          backgroundColor: expanded === 'panelOpen' ? '#fff' : 'transparent',
          color: detailsColor,
          border: '1px solid #EEECE7',
          padding: '8px 10px 8px 0',
          borderRadius: '0px 0px 8px 8px'
        }}
      >
        <AccordionSubTitle isMobile={isMobile} detailsColor={detailsColor || '#2D3648'} variant='body2'>
          {details}
        </AccordionSubTitle>
      </AccordionDetails>
    </CustomAccordionContainer>
  )
}

export default CustomAccordion
