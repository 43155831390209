import React from 'react'
import { Stack, Box, useMediaQuery } from '@mui/material'
import { CustomDivider } from '../../style'
import { IChangesDetails } from '../../../../../../constants/interfaces.constant'
import { theme } from '../../../../../../constants/theme.constants'
import { NormalText, MainTitle, SmallText } from './style'

const GeneralData = ({ item }: { item: IChangesDetails }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Stack>
      <MainTitle className='offer-sub-title'>{item.rooms.join(',')}</MainTitle>
      <CustomDivider />
      <Stack direction='column' padding={'6px 0'} position='relative'>
        <NormalText>{item.subject}</NormalText>
        <SmallText>תאור</SmallText>
        {/* <NormalText width={{ sm: '90%' }}>{item.subject}</NormalText> */}
        {isMobile ? (
          <Stack direction='row'>
            <Stack direction='column' paddingLeft='32px'>
              <SmallText>כמות</SmallText>
              {/* <NormalText>{item.quantityChanges}</NormalText> */}
            </Stack>
            <Stack direction='column'>
              <SmallText>תאריך תוספות</SmallText>
              {/* <NormalText>{item.date}</NormalText> */}
            </Stack>
          </Stack>
        ) : (
          <Box position='absolute' top={'22px'} left={'30px'} display='flex' textAlign='center'>
            <Stack direction='column'>
              <SmallText>כמות</SmallText>
              {/* <NormalText>{item.quantityChanges}</NormalText> */}
            </Stack>
          </Box>
        )}
      </Stack>
      <CustomDivider />
    </Stack>
  )
}

export default GeneralData
