import React, { useEffect } from 'react'
import Router from './Router/Router'
import { useAppDispatch } from './hooks/redux.hooks'
import { getSilentLogin } from './state/features/user.feature'

const App: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getSilentLogin())
  }, [])

  return (
    <>
      <Router />
    </>
  )
}

export default App
