import styled from 'styled-components'
import { theme } from '../../../../../constants/theme.constants'

export const AccordionWrapperInner = styled('div')({
  width: '65%',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
})

export const DataWrapper = styled('div')({
  display: 'flex',
  flexDirection:'row',
  justifyContent: 'space-between'
})

export const DataWrapperInner = styled('div')({
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: 0
})
