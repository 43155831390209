import CryptoJS from 'crypto-js'

const appEncryptKey = '6FTEXrZo34Ljsp2tDaFDw9tn3pgpRGyu'

export const EncryptManager = {
  encrypt: (source: string): string => {
    const iv = CryptoJS.lib.WordArray.random(16)
    const encrypted = CryptoJS.AES.encrypt(source, CryptoJS.enc.Utf8.parse(appEncryptKey), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return `${encrypted.toString()}:${iv.toString(CryptoJS.enc.Base64)}`
  }
}