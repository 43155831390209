import React, { useState } from 'react'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import Loader from '../../../UI/Loader/Loader'
import AccordionNotChosenMaterials from './notChosenMaterials'
import AccordionChosenMaterials from './chosenMaterials'
import { Box, styled, Tab, Tabs } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'
import { SectionTitle } from '../../../../constants/styled.components.constants'
import TabPanel from './TabPanel'

const FinishingMaterials = () => {
  const { FinishingMaterialsData: materialsDataSlice, loading } = useAppSelector((state) => state.finishingMaterials)
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  if (loading) return <Loader />

  return (
    <div style={{ position: 'relative', padding: '0 0 0 18px' }}>
      <SectionTitle className='mobile-view'> חומרי גמר </SectionTitle>
      <Box
        sx={{
          width: '100%',
          [theme.breakpoints.down('md')]: {
            padding: '0'
          }
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', border: 'none' }}>
          <CustomTabs value={value} onChange={handleChange} aria-label='basic tabs example'>
            <CustomTab
              disableRipple
              label={
                <TabWrapper className='tab-wrapper'>
                  <p>נותר לבחור</p>
                  <span>{materialsDataSlice?.notChosenMaterials.length}</span>
                </TabWrapper>
              }
              id='simple-tab-1'
              aria-controls='simple-tabpanel-1'
            />

            <CustomTab
              label={
                <TabWrapper className='tab-wrapper'>
                  <p>חומרים שנבחרו</p>
                  <span>{materialsDataSlice?.ChosenMaterials.length}</span>
                </TabWrapper>
              }
              id='simple-tab-0'
              aria-controls='simple-tabpanel-0'
            />
          </CustomTabs>
        </Box>
        <TabPanel value={value} index={1}>
          {materialsDataSlice?.ChosenMaterials &&
            materialsDataSlice.ChosenMaterials.map((material, index) => (
              <AccordionChosenMaterials material={material} key={index} />
            ))}
        </TabPanel>
        <TabPanel withScroll={true} value={value} index={0}>
          {materialsDataSlice?.notChosenMaterials &&
            materialsDataSlice.notChosenMaterials?.map((material, index) => (
              <AccordionNotChosenMaterials key={index} material={material} />
            ))}
        </TabPanel>
      </Box>
    </div>
  )
}

export default FinishingMaterials

const TabWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& p': {
    padding: '3px 10px',
    color: Colors.blue
  },
  '& span': {
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    color: Colors.blue
  }
})

const CustomTabs = styled(Tabs)({
  '.MuiTabs-flexContainer': {
    display: 'inline-block',
    background: Colors.lightBlueBG,
    borderRadius: '30px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '3px 16px'
    },
    /**styles applying to the selected tabWrapper */
    '& .Mui-selected .tab-wrapper': {
      display: 'flex',
      alignItems: 'center',
      background: Colors.white,
      padding: '7px 0px 7px 3px',
      borderRadius: '19px',
      [theme.breakpoints.down('md')]: {
        padding: '3px 16px'
      },
      '& span': {
        backgroundColor: Colors.blue,
        color: Colors.white
      }
    }
  },
  '.MuiTabs-indicator': {
    display: 'none'
  }
})

const CustomTab = styled(Tab)({
  [theme.breakpoints.down('md')]: {
    padding: '0',
    minHeight: '36px'
  }
})
