import React from 'react'
import { styled } from '@mui/material'
import { DynamicText, ItemText } from '../../../../constants/styled.components.constants'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import { theme } from '../../../../constants/theme.constants'
import CustomTooltip from '../../../UI/customToolTip'
import * as CONSTS from './consts'

const ContractSummary = () => {
  const tooltips = useAppSelector((state) => state.global?.tooltipsList)
  const { estimatedDate, lotNumber, priceWithTax, signDate, deliveryChangeDate } =
    useAppSelector((state) => state.contract.contractInfoData?.purchaseOverview) || {}
  const getTooltipTextValueByKey = (tooltipKey: string) => {
    return tooltips?.find((tooltip) => tooltip.key === tooltipKey)?.value || ''
  }

  return (
    <>
      {deliveryChangeDate ? (
        <>
          <WrapperInner className='space-between children-cardBody'>
            <ItemText>{CONSTS.DELIVERY_CHANGE_DATE}</ItemText>
            <DynamicText>{deliveryChangeDate}</DynamicText>
          </WrapperInner>
        </>
      ) : (
        <>
          <WrapperInner className='space-between children-cardBody'>
            <ItemText>{CONSTS.SIGN_DATE}</ItemText>
            <DynamicText>{signDate}</DynamicText>
          </WrapperInner>
          <WrapperInner>
            <ItemText>{CONSTS.APROX_DATE}</ItemText>
            <CustomTooltip title={getTooltipTextValueByKey('contract_delivery_date')} text={estimatedDate} />
          </WrapperInner>
        </>
      )}
      <WrapperInner>
        <ItemText>{CONSTS.APARTMENT_COST}</ItemText>
        <CustomTooltip
          title={getTooltipTextValueByKey('contract_apartment_price')}
          text={parseFloat(priceWithTax || '0').toLocaleString() + '₪'}
        />
      </WrapperInner>
      <WrapperInner>
        {lotNumber && (
          <>
            <ItemText>{CONSTS.FROM_LOT}</ItemText>
            <DynamicText>{lotNumber}</DynamicText>
          </>
        )}
      </WrapperInner>
    </>
  )
}

export default ContractSummary
const WrapperInner = styled('div')({
  width: '25%',
  padding: '10px 0rem 0 0',
  '& .small-gap': {
    padding: '0 0 0 6px'
  },
  '& .space-between': {
    padding: '0 8px'
  },
  '& .space-between .MuiButtonBase-root-JvZdr.lcfWbe:hover': {
    backgroundColor: 'none'
  },
  [theme.breakpoints.down('md')]: {
    width: '50%'
  }
})
