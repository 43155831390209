import React, { useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material'
import {
  AccordionWrapper,
  AccordionWrapperInner,
  DateWrapper,
  DynamicText,
  InnerContent,
  InnerItem,
  ItemText
} from '../../../../../constants/styled.components.constants'
import InnerAccordion from '../../../../UI/InnerAccordion/InnerAccordion'
import { ChosenMaterialsType } from '../../../../../constants/interfaces.constant'

function AccordionChosenMaterials({ material }: { material: ChosenMaterialsType }) {
  const { chosen_date, subjectName, room, invite_date, model, supplierName } = material

  return (
    <AccordionWrapper>
      <Accordion>
        <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <AccordionWrapperInner>
            <InnerItem>
              <InnerAccordion title='תיאור' subTitle={subjectName} />
              <InnerAccordion title='חדר' subTitle={room} />
            </InnerItem>
            <DateWrapper>
              <ItemText> תאריך בחירה</ItemText>
              <DynamicText>{chosen_date}</DynamicText>
            </DateWrapper>
          </AccordionWrapperInner>
        </AccordionSummary>
        <AccordionDetails className='suppliersData'>
          <>
            <InnerContent>
              <Content>
                <InnerAccordion title=' תאריך הזמנה' subTitle={invite_date} />
                <InnerAccordion title='שם הספק' subTitle={supplierName} />
                <InnerAccordion title='דגם' subTitle={model} />
              </Content>
              <ExtraContent>
                <p>הנחיות נוספות לבציוע</p>
                <p>-</p>
              </ExtraContent>
            </InnerContent>
          </>
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  )
}

export default AccordionChosenMaterials

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
)

const Content = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})
const ExtraContent = styled('div')({
  padding: '2rem 0 1rem 0'
})

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))
