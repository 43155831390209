import { IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { DynamicText } from '../../../constants/styled.components.constants'
import { Info } from '../../../constants/icons.constants'

interface ICustomTooltipProps {
  text?: string
  title: string
}

const CustomTooltip = ({ text, title }: ICustomTooltipProps) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Tooltip
      dir='rtl'
      open={isOpen}
      title={title}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onMouseDown={(e) => {
        e.preventDefault()
      }}
      componentsProps={{
        tooltip: {
          sx: {
            marginTop: '0px !important',
            maxWidth: '200px'
          }
        }
      }}
    >
      <IconButton style={{ padding: '0' }}>
        <DynamicText className='small-gap'>{text}</DynamicText> <Info />
      </IconButton>
    </Tooltip>
  )
}

export default CustomTooltip
