import React from 'react'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import { useAppSelector } from '../../../hooks/redux.hooks'
import { Grid, styled } from '@mui/material'
import FinishingMaterials from './FinishingMaterials'
import TenantsChanges from './TenantsChanges'
import MobileAccordion from '../../UI/MobileAccordion/MobileAccordion'
import { theme } from '../../../constants/theme.constants'
import { Colors } from '../../../constants/styles.constants'

function MyApartment() {
  const { width, breakpoint } = UseResizeWindow()
  const finishingMaterialsData = useAppSelector((state) => state.finishingMaterials.FinishingMaterialsData)
  return (
    <>
      {width > breakpoint ? (
        <MainWrapper id='myApartment'>
          <Grid
            item
            xs={6}
            md={12}
          >
            <FinishingMaterials />
          </Grid>
          <Grid item xs={12} md={12}>
            <TenantsChanges />
          </Grid>
        </MainWrapper>
      ) : (
        <MobileWrapper>
          <MobileAccordion title={'חומרי גמר'} extraData={finishingMaterialsData?.notChosenMaterials.length}>
            <FinishingMaterials />
          </MobileAccordion>
          <MobileAccordion title={'שינויי דיירים'}>
            <TenantsChanges />
          </MobileAccordion>
        </MobileWrapper>
      )}
    </>
  )
}

export default MyApartment

const MainWrapper = styled('div')({
  margin: '0 32px',
  padding: '72px 0 36px',
  borderBottom: `2px solid ${Colors.lightBrownSecond}`,
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
  [theme.breakpoints.down('xl')]: {
    margin: '0 1rem'
  }
})

const MobileWrapper = styled('div')({
  display: 'block',

  '&.MuiAccordionDetails-root': {
    padding: '0px 5px 16px'
  },
  '& h2.mobile-view': {
    display: 'none'
  },
  '& .MuiAccordionDetails-root': {
    padding: '10px'
  }
})
