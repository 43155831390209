import { styled } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'

export const BorderBoxWrapper = styled('div')({
  height: '14px',
  backgroundColor: Colors.grayBg,
  borderRadius: '10px',
  [theme.breakpoints.down('md')]: {}
})

export const BorderBox = styled('section')({
  position: 'relative',
  height: '100%',
  borderRadius: '10px 10px 10px 10px',
  '&.current': {
    backgroundColor: Colors.orengeText,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
})
export const StepText = styled('div')({
  textAlign: 'right',
  position: 'absolute',
  top: '0px',
  left: '0',
  color: Colors.white,
  right: '8px',
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: '300',
  letterSpacing: '1px'
})
