import React from 'react'
import { Stack, Typography, useMediaQuery } from '@mui/material'
import { theme } from '../../../../../../constants/theme.constants'
import { CustomDivider } from '../../style'
import { IMaterialsChange } from '../../../../../../constants/interfaces.constant'
import { GeneralDataBox, MainContainer } from './styles'
import GeneralData from '../ModalGeneralData'

const ModalContent = ({ changesPreview }: { changesPreview: IMaterialsChange['changes'] }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { changesDetails, addonsDate, changeEstimateID, total } = changesPreview

  return (
    <MainContainer>
      <Stack direction='column' spacing={0.5} marginBottom='13px'>
        <Typography fontSize={isMobile ? '22px' : '44px'} fontWeight={600} color={'#473B33'}>
          הצעה מס’ {changeEstimateID}
        </Typography>
        <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'}>
          עלות שינוי ₪{parseFloat(total).toLocaleString()}
        </Typography>
        <Typography fontSize={'22px'} fontWeight={400} color={'#473B33'} paddingBottom='15px'>
          תאריך הצעת מחיר {addonsDate}
        </Typography>
        <CustomDivider />
      </Stack>
      <GeneralDataBox>
        {changesDetails.map((item, index) => (
          <GeneralData item={item} key={index} />
        ))}
      </GeneralDataBox>
    </MainContainer>
  )
}

export default ModalContent
