import React from 'react'
import styled from 'styled-components'
import { Download } from '../../../../constants/icons.constants'
import { ItemText, DynamicText, SectionTitle, IconWrapper } from '../../../../constants/styled.components.constants'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import CardInfo from '../../../UI/CardInfo/cardInfo'
import Loader from '../../../UI/Loader/Loader'

function OtherDocuments() {
  const { filesData, loading } = useAppSelector((state) => state.global)

  if (loading) return <Loader />

  return (
    <>
      <div style={{ padding: '1rem 0 0 0' }}>
        <SectionTitle className='mobile-view'>מסמכים נוספים</SectionTitle>

        {filesData?.length ? (
          <ScrollContainer className='srcoll'>
            {filesData &&
              filesData.map((item, index) => (
                <CardInfo key={index}>
                  <>
                    <div className='inner-card-info'>
                      <DynamicText>{item.name}</DynamicText>
                      <ItemText>{new Date(item.created).toLocaleDateString()}</ItemText>
                    </div>
                    <IconWrapper>
                      <a className='pdf-download' href={item.url} target='_blank'>
                        <Download />
                      </a>
                    </IconWrapper>
                  </>
                </CardInfo>
              ))}
          </ScrollContainer>
        ) : (
          <p>אין מידע נוסף</p>
        )}
      </div>
    </>
  )
}

export default OtherDocuments

const ScrollContainer = styled('div')({
  height: '225px',
  overflowY: 'auto',
  direction: 'ltr',
  padding: '0 10px 0 0'
})
