import { styled } from 'styled-components'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'

export const ItemIconsWrapper = styled('li')({
  position: 'relative',
  width: '208px',
  height: '180px',
  [theme.breakpoints.down('xl')]: {
    margin: 'auto',
    height: 'auto'
  }
})
export const InnerContentWrapper = styled('div')({
  position: 'absolute',
  top: '-10px',
  left: '-2rem',
  right: '0',
  margin: 'auto',
  textAlign: 'right',
  transform: 'translate(0%, 50%)',
  width: '70%',
  height: 'auto',
  [theme.breakpoints.down('md')]: {
    top: '5px',
    left: '0',
    right: '60px'
  }
})
export const TextDetail = styled('p')({
  textAlign:'center',
  fontSize: '18px',
  color: Colors.blue,
  textDecoration: "underline",
  width: '100px',
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'none'
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'center'
  }
})

export const ModalWrapper = styled('div')({
  '& .boQUZm': {
    border: 'none',
    width: '300px',
    minHeight: '229px',
    [theme.breakpoints.down('md')]: {
      left: '65%'
    }
  }
})
