export const appealStatus = {
  open: 'פתוח',
  closed: 'סגור',
  onWork: 'בטיפול'
}

export const appealLabels = {
  date: 'תאריך פנייה',
  id: 'מספר פנייה',
  status: 'סטטוס פנייה'
}

export const APPEALS = 'פניות'
export const NEW_APPEAL = 'פנייה חדשה'
export const SHOW_ALL_APPEALS = 'הצגת כל הפניות'
export const NO_RELEVANT_INFO = 'אין מידע רלוונטי'
