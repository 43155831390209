import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from '../../services/ajax.service'
import { API_ENDPOINTS, ERROR_CODE } from '../../constants/api.constant'
import { getErrorMessage } from '../../utils/index'
import { loginConstants } from '../../constants/login.constant'
import { EncryptManager } from '../helpers'

export interface IUser {
  info: any
  id: number
  name: string
  contractId: string | any
  projectId: string | any
  username: string
  email: string
  role: string
  personalInfo: {
    phoneNumber: string
  }
  addressData: {
    city: string
    street: string
    toSend: boolean
  }
  billing: {
    toSend: boolean
  }
  contactList: []
}
export interface IVerification {
  success: boolean
  verification: string | null
}
export interface IUserError {
  error: boolean
  errorCode: number | null
  errorMessage: string
}
export interface IUserState {
  timestamp: any
  personalInfo: any
  isAuth: boolean
  contractId: string
  projectId: number | any
  verification: any
  user: IUser | null
  loading: boolean
  error: IUserError
  otpValidated: boolean
  isPasseordChanged: boolean
  loginError?: string
  currentStep: number,
  isPageError?: boolean,
  firstStepLoginSession: Partial<{
    loginOptions: {
      email: string
      phoneNumber: string
    }
    signedGuid: string
  }>
  secondStepVerification: string
  otpMethod: string
}
export interface ICredentials {
  email: string
  password: string
}
export interface IOtpArgs {
  verification: any
  otp: string | undefined
  contractId: string | null | undefined
}
export interface IPassword {
  password: string
}

export const getProfileData = createAsyncThunk('user/getProfileData', (otpArgs: IOtpArgs, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.PROFILE_DATA, otpArgs)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
)

export const signupWithPassword = createAsyncThunk(
  '/user/signupWithPassword',
  (credentials: IPassword, { rejectWithValue }) =>
    API.getInstance()
      .put(API_ENDPOINTS.SET_PASSWORD, credentials)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const initiateLogin = createAsyncThunk(
  'user/initiateLogin',
  async ({ data, type }: { data: any; type: string }, { dispatch, rejectWithValue }) => {
    try {
      let field = loginConstants[type]?.dbField
      const requestData = { [field]: data }

      const headers = {
        'x-auth-token': EncryptManager.encrypt(new Date().toISOString().split('T')[0])
      };

      const response = await API.getInstance()
        .post(API_ENDPOINTS.INITIATE_LOGIN, requestData, {}, { ...headers })

      const { loginOptions, signedGuid } = response.data
      dispatch(setFirstLoginSession({ loginOptions, signedGuid }))
      dispatch(setLoginError(''))
      dispatch(setStep(2));
      return response.data.verification
    } catch (err: any) {
      let errorMessage: string = getErrorMessage(type, data)
      if (err?.data?.message === 'Not found') {
        dispatch(setFakeErrorOtpPage(3))
      }
      dispatch(setLoginError(errorMessage))
      rejectWithValue(err)
    }
  }
)

export const sendOtp = createAsyncThunk('user/sendOtp', (data: any, { dispatch, rejectWithValue }) => {
  const { selectedOption } = data
  dispatch(setOTPMethod(selectedOption))
  return API.getInstance()
    .post(API_ENDPOINTS.SEND_OTP, data)
    .then(({ data }) => {
      const { verification } = data
      dispatch(setSecondStepLoginSession(verification))
      dispatch(setStep(3))
      return data.verification //check what this is
    })
    .catch((err) => {
      dispatch(setLoginError('הייתה תקלה, יש לנסות שנית'))
      rejectWithValue(err)
    })
})

export const validateOtp = createAsyncThunk('user/validateOtp', (data: any, { dispatch, rejectWithValue }) => {
  return API.getInstance()
    .post(API_ENDPOINTS.VALIDATE_OTP, data)
    .then((response) => {
      dispatch(setIsAuth(true))
      localStorage.setItem('userToken', response.data.info.token)
      return response.data
    })
    .catch((err) => {
      dispatch(setIsAuth(false))
      dispatch(setLoginError('הקוד שהוזן שגוי, יש לנסות שנית'))
      rejectWithValue(err)
    })
})

export const getSilentLogin = createAsyncThunk('user/getSilentLogin', (_, { dispatch, rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.SILENT_LOGIN)
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      dispatch(setIsAuth(false))
      localStorage.removeItem('userToken')
      rejectWithValue(err)
    })
)

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  (credentials: Partial<ICredentials>, { rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.FORGOT_PASSWORD, credentials)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

const initialState: IUserState = {
  isAuth: true,
  verification: {},
  personalInfo: null,
  user: null,
  otpValidated: false,
  isPasseordChanged: false,
  loading: false,
  error: {
    error: false,
    errorCode: null,
    errorMessage: ''
  },
  contractId: '',
  projectId: '',
  timestamp: null,
  loginError: '',
  currentStep: 1,
  isPageError: false,
  firstStepLoginSession: {},
  secondStepVerification: '',
  otpMethod: ''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserUnauthorized: (state) => {
      state.error.errorCode = ERROR_CODE.unauthorized
    },
    updateClientAndProjectIDs: (state, action) => {
      if (state.user) {
        const { contractId, projectId } = action.payload
        state.contractId = contractId
        state.projectId = projectId
        localStorage.setItem('contractId', state.contractId)
        localStorage.setItem('projectId', state.projectId)
      }
    },
    logout: (state) => {
      state.user = null
      state.isAuth = false
      localStorage.removeItem('userToken')
      localStorage.removeItem('contractId')
      localStorage.removeItem('projectId')
    },
    setTimeStamp: (state, action) => {
      state.timestamp = action.payload
    },
    setLoginError: (state, action) => {
      state.loginError = action.payload
    },
    goToNextStep: (state) => {
      state.currentStep = state.currentStep + 1
    },
    goToPreviousStep: (state) => {
      state.currentStep = state.currentStep - 1
    },
    setStep: (state, action) => {
      state.currentStep = action.payload
    },
    setFakeErrorOtpPage: (state, action) => {
      state.currentStep = action.payload
      state.isPageError = true
    },
    setFirstLoginSession: (state, action) => {
      state.firstStepLoginSession = action.payload
    },
    setSecondStepLoginSession: (state, action) => {
      state.secondStepVerification = action.payload
    },
    setOTPMethod: (state, action) => {
      state.otpMethod = action.payload
    },
    setIsAuth: (state, action) => {
      state.isAuth = action.payload
    }
  },
  extraReducers: {
    [getProfileData.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [getProfileData.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.personalInfo = payload
      state.isAuth = true
    },
    [getProfileData.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
      state.isAuth = false
    },
    [signupWithPassword.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [signupWithPassword.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.isPasseordChanged = true
      state.isAuth = true
    },
    [signupWithPassword.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
      state.isAuth = false
    },
    [getSilentLogin.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [getSilentLogin.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.user = payload

      if (!state.contractId || !state?.projectId) {
        state.contractId = localStorage.getItem('contractId') || payload?.info?.data[0]?.contractId
        state.projectId = localStorage.getItem('projectId') || payload?.info?.data[0]?.projectId
      }
      if (payload) {
        localStorage.setItem('userToken', payload.info.token)
        state.isAuth = true
      }
      else {
        state.isAuth=false
      }
    },
    [getSilentLogin.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
      state.isAuth = false
    },
    [validateOtp.pending.type]: (state) => {
      state.loading = true
    },
    [validateOtp.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.contractId = localStorage.getItem('contractId') || payload?.info?.data[0]?.contractId
      state.user = payload
      state.isAuth = true
      state.error.error = false
      state.otpValidated = true
      localStorage.setItem('userToken', payload.info.token)
    },
    [validateOtp.rejected.type]: (state) => {
      state.loading = false
      state.isAuth = false
      state.error.error = true
    },
    [forgotPassword.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [forgotPassword.fulfilled.type]: (state, { payload }) => {
      state.loading = false
    },
    [forgotPassword.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
    }
  }
})

export const {
  setTimeStamp,
  setUserUnauthorized,
  logout,
  updateClientAndProjectIDs,
  setLoginError,
  goToNextStep,
  goToPreviousStep,
  setStep,
  setFakeErrorOtpPage,
  setFirstLoginSession,
  setSecondStepLoginSession,
  setOTPMethod,
  setIsAuth
} = userSlice.actions

export default userSlice.reducer
