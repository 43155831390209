import React, { useState, useEffect, useMemo, useRef, useContext, useCallback } from 'react'
import { useBlocker } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import { useSearchParams } from 'react-router-dom'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import { useGetTimeMessage } from '../../../hooks/useGetTimeMessage/useGetTimeMessage'
import API from '../../../services/ajax.service'
import { Button, Grid, List, ListItem, ListItemText, styled } from '@mui/material'
import MyAppeals from '../MyAppeals/MyAppeals'
import Category from './Category/Category'
import ChatList from '../ChatSideBar/ChatList'
import Loader from '../../UI/Loader/Loader'
import {
  ChatListInner,
  DimanicText,
  ResponseTitle,
  SmallText,
  TitleFlexWrap
} from '../../../constants/styled.components.constants'
import { theme } from '../../../constants/theme.constants'
import { Colors } from '../../../constants/styles.constants'
import ModalChat from './ModalChat/ModalChat'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import ChatBackgroundSreen from '../ChatBackground/ChatBackgroundSreen'
import { API_ENDPOINTS } from '../../../constants/api.constant'
import { useChatHttp } from '../../../hooks/useChatHttp/useChatHttp'
import { getAllQuestions, setSelectedCategory, setSelectedRooms } from '../../../state/features/chat.feature'
import { ChatContext, IChatMessageType } from './context'
import customerServiceSvg from "../../../assets/icons/customerService.svg"
import { IRenderedQuestionData, QuestionType } from '../../../constants/interfaces.constant'
import _ from 'lodash'
import { Box } from '@mui/system'
import { CHAT_TEXTS } from '../../../constants/chat.constants'
import { addYears, getDate, isToday, parse, startOfToday, subYears } from 'date-fns'
import Rooms from './Rooms'
import UploadMultiplyFiles from '../FormInput/UploadMultiplyFiles'

type InitialStateType = {
  title: string | any
  text: JSX.Element | any
  owner: number | string | any
  timestamp: number | any
  subject?: string
}[]

const ChatOnLine = () => {
  const dispatch = useAppDispatch()
  const [showChatList, setShowChatList] = useState(false)
  const { width, breakpoint, isMobile } = UseResizeWindow()
  const { allQuestionsData, loading, questionData } = useAppSelector((state) => state.chat) || []
  const { selectedRooms, selectedCategory, selectedFiles } = useAppSelector(state => state.chat)
  const user = useAppSelector((state) => state.user?.user?.info?.username)
  const {
    draft,
    setDraft,
    clearDraft,
    addMessage,
    updateMessageText,
    messages,
    setMessages,
    setCallID,
    callID,
    statusID,
    setStatusID,
    selectedMainCategory,
    setSelectedMainCategory,
  } = useChatMessages()

  const { fileResponse, fileColor, setFileColor, handleMessageCalledActivity, selectedFile, setSelectedFile } =
    useChatHttp()
  const { contractId, projectId } = useAppSelector((state: { user: any }) => state.user)
  const { contractInfoData } = useAppSelector((state) => state.contract)
  const timeStatement = useGetTimeMessage()
  const [searchParams, setSearchParams] = useSearchParams()
  const [isBlock, setIsBlock] = useState<boolean>(false)
  const blocker = useBlocker(!!!callID)
  const [openModal, setOpenModal] = useState(false)
  const [formData, setFormData] = useState<{ text?: string; file?: File }[]>([])
  const [error, setError] = useState<string>('')
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>()
  const [visibleForm, setVisibleForm] = useState(false)
  const [answerButton, setAnswerButton] = useState(false)
  const [serviceCallID, setServiceCallID] = useState(null)
  const [selectedOption, setSelectedOption] = useState(false)
  const [selectedID, setSelectedID] = useState(false)
  const deliveryChangeDate = useRef<Date | null>(null);
  const contractRooms = useRef<string[]>([]);
  const _selectedRooms = useRef<string[]>([]);
  const _messages = useRef<any[]>([]);
  const _selectedMainCategory = useRef<any>();
  const _selectedCategory = useRef<any>();
  const [showNewChatMessage, setShowNewChatMessage] = useState<boolean>(false)
  const [listDataChanged, setListDataChanged] = useState(false)
  const [titleName, setTitleName] = useState<any>()
  const [openAppealStepDone, setOpenAppealStepDone] = useState<boolean>(false)
  const groupedNotes = useMemo(() => {
    return formData.map(({ text }) => text).join(' ')
  }, [formData])

  const groupedFiles = useMemo(() => {
    return formData.map(({ file }) => file).filter(Boolean) as File[]
  }, [formData])

  const [lastGroupedNotes, setLastGroupedNotes] = useState('')
  const [lastGroupedFiles, setLastGroupedFiles] = useState<string[]>([])

  const toggleChatView = () => {
    setShowChatList(!showChatList)
  }

  const questionDataTitle = useMemo(() => {
    if (callID) {
      return allQuestionsData?.find((call: any) => call.callID === callID)?.subject
    }
    return callID
      ? allQuestionsData?.length && allQuestionsData[0]?.subject
      : selectedSubCategory
        ? selectedSubCategory?.text
        : 'פנייה חדשה'
  }, [allQuestionsData, selectedSubCategory, callID])

  function getChatStatus(allQuestionsData: any, statusID: any) {
    if (allQuestionsData?.length) {
      switch (allQuestionsData[0].statusID) {
        case -3:
          return 'פתוח'
        case -2:
          return 'בטיפול'
        case -1:
          return 'סגור'
        default:
          return ''
      }
    }
    return ''
  }

  useEffect(() => {
    const dlv: string | undefined = _.get(contractInfoData, 'purchaseOverview.deliveryChangeDate')
    if (_.isString(dlv)) {
      try {
        deliveryChangeDate.current = parse(dlv, 'dd.MM.yyyy', new Date())
      } catch (e) {
        console.log('ERROR::cant calculate deliveryChangeDate')
      }
    }
    if (!_.isNil(contractInfoData) && contractInfoData?.rooms) {
      contractRooms.current = _.get(contractInfoData, 'rooms')
    }
  }, [contractInfoData])

  useEffect(() => {
    _selectedRooms.current = selectedRooms
    _selectedMainCategory.current = selectedMainCategory
    _messages.current = messages
    _selectedCategory.current = selectedCategory
  }, [selectedRooms, selectedMainCategory, messages, selectedCategory])

  const messageChatStatus = useMemo(() => {
    return getChatStatus(allQuestionsData, statusID)
  }, [allQuestionsData, callID, statusID])

  const handleStepSubject = ({ text, title }: any) => {
    const isBedeq = _selectedMainCategory.current?.type === QuestionType.BEDEQ
    addMessage({
      title: <span style={titleWrapperStyle}><img src={customerServiceSvg} width="30px" />
        <p style={titleStyle}>
          {title || text}
        </p>
      </span>,
      text: `בחרת לפנות אלינו בנושא ${isBedeq ? 'שנות בדק' : text} ${isBedeq ? 'ו' + text : ''} ${isBedeq ? ',ב' + _selectedRooms.current.join(', ') : ''}.`,
      owner: 'chat',
      timestamp: Date.now(),
      subject: text
    })
  }

  const onCategoryClick = (item: any) => {
    const { type, expired, text, items, needPhoto, id } = item
    dispatch(setSelectedCategory(item))
    if (type === QuestionType.BEDEQ && (messages.length > 0)) {
      try {
        if (!_.isNil(deliveryChangeDate.current) && _.isNumber(expired)) {
          const expiredDate = addYears(deliveryChangeDate.current, expired)
          if (expiredDate < new Date()) {
            setMessages([{
              ...messages[0],
              text: 'איך נוכל לעזור לך?',
              owner: "chat",
              timestamp: Date.now(),
            }])
            //updateMessageText(0, 'איך נוכל לעזור לך?')
            addMessage({
              title: <span style={titleWrapperStyle}><img src={customerServiceSvg} width="30px" />
                <p style={titleStyle}>
                  {CHAT_TEXTS.expiredBedeqTitle}
                </p>
              </span>,
              text: CHAT_TEXTS.expiredBedeq,
              owner: 'chat',
              timestamp: Date.now(),
              subject: 'text'
            })
            return false;
          }
        }
      } catch (e) { console.log(e) }

      setMessages([{
        ...messages[0],
        owner: "chat",
        timestamp: Date.now(),
        text: 'איך נוכל לעזור לך?',
      }])
      if (!_selectedRooms.current.length) {
        addMessage({
          title: customerServiceTitle,
          text: (
            <ChatListInner>
              <SmallText style={{ fontSize: "18px" }}>{`בחרת לפנות אלינו בנושא שנות בדק, ו${text}, יש לציין מיקום בדירה`}</SmallText>
              <Rooms
                selectedCategory={selectedCategory}
                rooms={contractRooms.current}
                onSelectRooms={(rooms: string[]) => {
                  dispatch(setSelectedRooms(rooms))
                  _selectedRooms.current = rooms
                  onCategoryClick(item)
                }}
              />
            </ChatListInner>
          ),
          owner: 'chat',
          timestamp: Date.now(),
          subject: text
        })
        return false
      }
    }
    updateMessageText(0, 'איך נוכל לעזור לך?')
    if (items.length === 0) {
      handleStepSubject({ text })
      if (needPhoto) {
        uploadMedia()
      } else {
        setVisibleForm(true)
      }
    } else {
      addMessage({
        title: customerServiceTitle,
        text: (
          <ChatListInner>
            <SmallText style={{ fontSize: "18px" }}>{`בחרת לפנות אלינו לגבי ${text} בחרֿֿֿ/י כעת תת נושא`}</SmallText>
            <Category
              categoryList={prepareCategoriesList(type === QuestionType.BEDEQ)}
              onSubCategoryClick={onSubCategoryClick}
              selectedSubCategory={selectedSubCategory}
              selectedCategory={id}
              selectedMainCategory={_selectedMainCategory.current}
            />
          </ChatListInner>
        ),
        owner: 'chat',
        timestamp: Date.now(),
        subject: text
      })
    }
  }

  const onMainCategoryClick = (item: any) => {
    const { text } = item
    setSelectedMainCategory(item)
    setMessages([
      //...newRequestState,
      {
        title: <Box>{`היי ${user}, ${timeStatement}`}</Box>,
        text: (
          <ChatListInner>
            <SmallText>איך נוכל לעזור לך?</SmallText>
          </ChatListInner>
        ),
        owner: 'chat',
        timestamp: Date.now(),
        subject: ''
      },
      {
        title: <span style={titleWrapperStyle}>
          <img src={customerServiceSvg} width="30px" />
          <p style={titleStyle}>{CHAT_TEXTS.customersService}</p>
        </span>,
        text: (
          <ChatListInner>
            <Box paddingBottom={2}><SmallText>{CHAT_TEXTS.subSubjectBedeqTitle}</SmallText></Box>
            <Category
              categoryList={prepareCategoriesList(true)}
              onSubCategoryClick={onSubCategoryClick}
              selectedSubCategory={selectedSubCategory}
              selectedCategory={undefined}
              onCategoryClick={onCategoryClick}
              selectedMainCategory={item}
              onMainCategoryClick={onMainCategoryClick}
            />
          </ChatListInner>
        ),
        owner: 'chat',
        timestamp: Date.now(),
        subject: ''
      }
    ])
  }

  const prepareCategoriesList = (bedek?: boolean) => {
    let list: any = _.isNull(questionData) ? [] : [...questionData]
    if (!bedek) {
      list = list?.filter((category: any) =>
        category?.type !== QuestionType.BEDEQ
      )
      const hasBedek = _.some(questionData, { type: QuestionType.BEDEQ })
      const bedekMainCategory = hasBedek ? [{
        empCode: 0,
        id: 0,
        items: [],
        text: 'שנת בדק',
        type: QuestionType.BEDEQ,
      }] : []

      list = [
        ...list,
        ...bedekMainCategory,
      ]

    } else {
      list = list?.filter((category: any) =>
        category?.type === QuestionType.BEDEQ
      )
    }
    return list
  }

  const onSubCategoryClick = (item: any, category?: string) => {
    const { text, needPhoto } = item
    setSelectedSubCategory(item)
    updateMessageText(
      1,
      `בחרת לפנות אלינו בנושא ${category},  תת נושא ${text}`
    )
    handleStepSubject({ text, title: CHAT_TEXTS.customersService })
    if (needPhoto) {
      uploadMedia()
    } else {
      setVisibleForm(true)
    }
    setVisibleForm(true)
  }

  const updateImages = () => {
    const isBedeq = _selectedMainCategory.current?.type === QuestionType.BEDEQ
    const { text } = _selectedCategory.current
    setMessages([
      //...newRequestState,
      {
        title: <Box>{user}</Box>,
        text: (
          <AddMediaWrap>
            <UploadMultiplyFiles
              handleSubmit={updateImages}
              readonly
            />
          </AddMediaWrap>
        ),
        owner: 'user',
        timestamp: Date.now(),
        subject: ''
      },
      {
        title: customerServiceTitle,
        text: `.בחרת לפנות אלינו בנושא ${isBedeq ? 'שנות בדק' : text} ${isBedeq ? 'ו' + text : ''} ${isBedeq ? ',ב' + _selectedRooms.current.join(', ') : ''}. כתב/י כעת את נושא הפניה`,
        owner: 'chat',
        timestamp: Date.now(),
        subject: text,
      }
    ])
    setVisibleForm(true)
  }

  const uploadMedia = () => {
    addMessage({
      title: customerServiceTitle,
      text: (
        <AddMediaWrap>
          <div>{CHAT_TEXTS.uploadPhoto}</div>
          <UploadMultiplyFiles
            handleSubmit={updateImages}
          />
        </AddMediaWrap>
      ),
      owner: 'chat',
      timestamp: Date.now(),
      subject: 'text'
    })
  }


  const newRequestState: InitialStateType = useMemo(() => {
    return [
      {
        title: <Box fontWeight={600}>{`היי ${user}, ${timeStatement}`}</Box>,
        text: (
          <ChatListInner>
            <Box paddingBottom={2}><SmallText>איך נוכל לעזור לך?</SmallText></Box>
            <Category
              categoryList={prepareCategoriesList()}
              onSubCategoryClick={onSubCategoryClick}
              selectedSubCategory={selectedSubCategory}
              selectedCategory={null}
              onCategoryClick={onCategoryClick}
              selectedMainCategory={_selectedMainCategory.current}
              onMainCategoryClick={onMainCategoryClick}
            />
          </ChatListInner>
        ),
        owner: 'chat',
        timestamp: Date.now(),
        subject: ''
      }
    ]
  }, [questionData])

  useEffect(() => {
    /*setCallID(undefined)
    if (!messages?.length) {
      uploadMedia()
    } return*/

    if (callID === null && !messages?.length) {
      setMessages(newRequestState)
      setCallID(null)
    } else {
      if (messages.length) return
      const serviceCall = allQuestionsData?.find((serviceCall: any) => {
        return serviceCall.callID === callID
      })

      let messagesList: any[] = []

      //TODO: FIX
      messagesList = messagesList.concat(
        ...(serviceCall?.ActivityCalls?.map((activityCall: any) => {
          if (!!activityCall?.isFile) {
            return {
              title: `${user}`,
              className: 'file-message-wrapper',
              text: activityCall.filename,
              owner: activityCall.owner,
              url: activityCall.url,
              fileId: activityCall.fileId,
              timestamp: activityCall.timestamp,
              subject: ''
            }
          } else
            return {
              title: `${user}`,
              text: (
                <ChatListInner>
                  <SmallText>{activityCall?.text}</SmallText>
                </ChatListInner>
              ),
              owner: activityCall.owner,
              timestamp: activityCall.timestamp,
              subject: serviceCall?.subject ?? ''
            }
        }) ?? [])
      )
      setMessages(messagesList)

      //uploadMedia()
    }
  }, [callID, questionData, messages])

  const handleInputData = (message: { text?: string; file?: File }) => {
    if (!message.text?.trim() && !message.file) {
      setError('שדה זה חייב להכיל תווים/הודעה')
      setTimeout(() => {
        setError('')
      }, 3000)
      return
    }
    setFormData((formData) =>
      formData.concat({
        text: message.text,
        file: message.file
      })
    )
  }

  //  console.log('fileResponse chatonline', fileResponse)
  const uploadServiceInProcessFile = async () => {
    try {
      await handleMessageCalledActivity(groupedNotes || '', groupedFiles, selectedSubCategory)
      setLastGroupedNotes(groupedNotes || '')
      // const fileNames = groupedFiles.map((file) => file.name)
      // setLastGroupedFiles(fileNames)
      setLastGroupedFiles(groupedFiles as any)
    } catch (error) {
      console.log('Error submitting message:', error)
    }
  }

  const submitHandleMessageCalledActivity = async () => {
    try {
      await handleMessageCalledActivity(groupedNotes || '', groupedFiles, selectedSubCategory)
      setLastGroupedNotes(groupedNotes || '')
      // const fileNames = groupedFiles.map((file) => file.name)
      // setLastGroupedFiles(fileNames)
      setLastGroupedFiles(groupedFiles as any)
    } catch (error) {
      console.log('Error submitting message:', error)
    }
  }

  useEffect(() => {
    if (!groupedNotes.length && !groupedFiles.length) return
    if (groupedNotes) {
      addMessage({
        title: `${user}`,
        text: groupedNotes,
        owner: 'user',
        timestamp: Date.now(),
        subject: ''
      })
    }
    if (callID) {
      submitHandleMessageCalledActivity()
    } else {
      addMessage({
        type: 'openAppealStep',
        title: customerServiceTitle,
        text: (
          <ChatListInner>
            <MainWrapper>
              <>
                <p style={{ fontSize: "18px" }}>מעולה, משהו נוסף?</p>
                <List sx={style} component='nav' aria-label='mailbox 2 folders'>
                  <ListItem button sx={listItemStyle}>
                    <ListItemText onClick={() => handleClick(0)} primary='כן, אני רוצה להרחיב' />
                  </ListItem>
                  <ListItem button sx={listItemStyle}>
                    <ListItemText onClick={() => handleClick(1)} primary='לא, אפשר להתחיל לטפל בפניה' />
                  </ListItem>
                </List>
              </>
            </MainWrapper>
          </ChatListInner>
        ),

        owner: 'chat',
        timestamp: Date.now(),
        subject: ''
      })
    }
    setTimeout(() => setVisibleForm(true), 1200)
  }, [formData])

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setOpenModal(true)
    }
  }, [callID, blocker, setOpenModal])

  useEffect(() => {
    setIsBlock(callID === null)
  }, [callID, setIsBlock])

  const clearNote = () => {
    setFormData([])
  }

  const handleClick = async (index: any) => {
    setOpenAppealStepDone(true)
    if (callID) {
      const messageData = {
        subDescription: selectedSubCategory?.text || '',
        note: groupedNotes,
        projectId: projectId,
        contractId: contractId,
        serviceCallID: callID
      }
      try {
        const { data } = await API.getInstance().post(API_ENDPOINTS.SEND_ACTIVITY, messageData, {
          serviceId: String(callID)
        })
        setAnswerButton(true)
        setIsBlock(false)
        setFormData([])
      } catch (err) {
        console.log(err)
      }
    } else {
      if (index === 0) {
        setVisibleForm(true)
        addMessage({
          title: customerServiceTitle,
          text: '?מה תרצה להוסיף',
          owner: 'chat',
          timestamp: Date.now(),
          subject: ''
        })
      } else if (index === 1) {
        setSelectedOption(!selectedOption)

        //if current service call has a guid (not ServiceCallID) then change message to הודעה נשלחה
        // addMessage({
        //   text: 'ההודעה נשלחה אנא המתן מספר שניות לקבלת מספר פנייה :)',
        //   owner: 'chat',
        //   timestamp: Date.now(),
        //   subject: ''
        // })

        const messageData = {
          sugCode: selectedCategory?.id,
          subCode: selectedSubCategory?.subCode || selectedCategory?.subCode,
          subDescription: selectedSubCategory?.text || selectedCategory?.subDescription,
          note: groupedNotes,
          // empCode: selectedSubCategory?.empCode || selectedCategory?.empCode,
          projectId: projectId,
          contractId: contractId,
          rooms: _selectedRooms.current
        }
        try {
          //if current service call has a guid (not ServiceCallID) send as activity by params related to API so it will
          //be added onto current service call and not create a new one

          const { data, guid } = await API.getInstance().post(API_ENDPOINTS.SEND_QUESTION, messageData)

          if (!_.isEmpty(selectedFiles)) {
            const [fileResponse] = await Promise.allSettled([
              Promise.all(
                _.values(selectedFiles).map((file) => {
                  const fileUploadFormData = new FormData()
                  fileUploadFormData.append('file', file)
                  return API.getInstance().post(
                    API_ENDPOINTS.UPLOAD_FILES.replace(':serviceId', data?.guid),
                    fileUploadFormData,
                    {
                      contractId: contractId,
                      serviceCallID: data?.guid,
                      fileName: file.name,
                      sugDescription: selectedSubCategory?.text || ''
                    }
                  )
                })
              )
            ])
          }
          addMessage({
            title: customerServiceTitle,
            text: (
              <MainWrapper className='show-box'>
                <SmallText>תודה על פנייתך, פנייתך תטופל בהקדם האפשרי, תודה על הסבלנות</SmallText>
              </MainWrapper>
            ),
            owner: 'chat',
            timestamp: Date.now(),
            subject: ''
          })
          setServiceCallID(data?.ServiceCallID)
          setCallID(data?.ServiceCallID || 0) // Set temporary callID if null
          setIsBlock(false)
          clearDraft()
          setFormData([])
          if (data) {
            setShowNewChatMessage(true)
            setListDataChanged(data)
            setTitleName(data?.data?.subject)
            clearNote()
          }
        } catch (err) {
          addMessage({
            title: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר',
            owner: 'chat',
            timestamp: Date.now(),
            subject: ''
          })
          console.log(err)
        }
      }
    }
  }

  if (loading) {
    return <Loader />
  }
  return (
    <>
      {openModal ? (
        <ModalChat
          onBlockerReset={() => {
            blocker.reset?.()
            setOpenModal(false)
          }}
          onBlockerproceed={() => {
            blocker.proceed?.()
            setOpenModal(false)
          }}
        />
      ) : (
        ''
      )}
      <Grid
        item
        sx={{
          width: '100%',
          margin: '5rem 0 0 0',
          padding: '0 2rem',
          [theme.breakpoints.down('md')]: {
            padding: '1rem 0 0 0',
            margin: '5rem 0 0 0 !important',
            display: 'block'
          },
          [theme.breakpoints.down('xl')]: {
            margin: '5rem 0 0 0'
          }
        }}
        container
        spacing={3}
      >
        {width > breakpoint ? (
          <MyAppeals
            showChatList={showChatList}
            setVisibleForm={setVisibleForm}
            visibleForm={visibleForm}
            newRequestState={newRequestState}
            setSelectedCategory={setSelectedCategory}
          />
        ) : (
          ''
        )}
        <Grid
          container
          spacing={2}
          sx={{
            [theme.breakpoints.down('md')]: {
              flexDirection: 'column-reverse',
              width: '95%',
              margin: 'auto'
            }
          }}
        >
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              [theme.breakpoints.down('md')]: {
                paddingBottom: '1rem'
              }
            }}
            style={{
              paddingTop: '0'
            }}
          >
            <ChatList
              clearNote={clearNote}
              fileResponse={fileResponse}
              lastGroupedFiles={lastGroupedFiles}
              lastGroupedNotes={lastGroupedNotes}
              onBlockerReset={() => {
                setIsBlock(false)
              }}
              setSelectedFile={setSelectedFile}
              setFileColor={setFileColor}
              setVisibleForm={setVisibleForm}
              serviceCallID={serviceCallID}
              newRequestState={newRequestState}
              selectedSubCategory={selectedSubCategory}
              setSelectedSubCategory={setSelectedSubCategory}
              allQuestionsData={allQuestionsData}
              loading={loading}
              showChatList={showChatList}
              toggleChatView={toggleChatView}
              setShowChatList={setShowChatList}
              showNewChatMessage={showNewChatMessage}
              listDataChanged={listDataChanged}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={8}
            style={{
              paddingTop: '0'
            }}
          >
            <TitleFlexWrap>
              {isMobile && (
                <Button onClick={toggleChatView} startIcon={<ArrowForwardIosIcon sx={{ fill: '#473B33' }} />} />
              )}

              <DimanicText className='padd-2'>
                {questionDataTitle ? questionDataTitle : titleName}
                {/* {questionDataTitle} */}
              </DimanicText>
              {callID && messageChatStatus && <ResponseTitle>{messageChatStatus} </ResponseTitle>}
            </TitleFlexWrap>

            <ChatBackgroundSreen
              fileColor={fileColor}
              setFileColor={setFileColor}
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              allQuestionsData={allQuestionsData}
              callID={callID}
              error={error}
              handleInputData={handleInputData}
              visibleForm={visibleForm}
              openAppealStepDone={openAppealStepDone}
              setOpenAppealStepDone={setOpenAppealStepDone}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ChatOnLine

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper'
}

const titleStyle = {
  fontSize: "18px",
  fontWeight: "600"
}

const titleWrapperStyle = {
  display: "flex",
  gap: "12.3px"
}

const listItemStyle = {
  background: Colors.white,
  borderRadius: "8.54px",
  height: "34px",
  my: "3.2px",
  textAlign: "start",
  padding: "8px 6px 0 0"
}

const MainWrapper = styled('div')({
  paddingBottom: "12px",
  '&.chatstep': {
    bottom: '6rem'
  },
  '& nav': {
    background: 'none',
    padding: '0',

    '& .MuiListItem-padding.MuiListItem-button': {
      direction: 'rtl',
      textAlign: 'center',
      background: '#fff',
      borderRadius: '10px',
      margin: '4px 0'
    }
  },
  '& hr': {
    display: 'none'
  },
  '& .MuiButtonBase-root': {
    background: Colors.white,
    borderRadius: '10px',
    textAlign: 'right',
    minHeight: '40px',
    height: '40px'
  }
})

const AddMediaWrap = styled('div')({
  marginBottom: 8,
  width: '100%',
  maxWidth: 'inherit',
})

const customerServiceTitle = <span style={titleWrapperStyle}>
  <img src={customerServiceSvg} width="30px" />
  <p style={titleStyle}>{CHAT_TEXTS.customersService}</p>
</span>
