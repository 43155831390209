import React from 'react'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes.constants'
import { useAppSelector } from '../../hooks/redux.hooks'

interface IPrivateRoute {
  children: JSX.Element
}

export const PrivateRoute: React.FC<IPrivateRoute> = ({ children }) => {
  const { isAuth } = useAppSelector((state) => state.user)

  if (isAuth) {
    return children
  }
  return <Navigate to={{ pathname: ROUTES.ROOT }} />
}
