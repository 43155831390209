import React, { useEffect, useRef, useState } from 'react'
import { Button, styled, Divider, Box, Paper, InputBase, IconButton } from '@mui/material'
import { Colors } from '../../../constants/styles.constants'
import { Attach } from '../../../constants/icons.constants'
import { useChatHttp } from '../../../hooks/useChatHttp/useChatHttp'
import { servicesType } from '../../../constants/interfaces.constant'
import _ from 'lodash'
import { Delete, DeleteOutline } from '@mui/icons-material'
import { fontSize } from '@mui/system'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import { setSelectedFiles, setSelectedImages } from '../../../state/features/chat.feature'

type Props = {
  handleSubmit: () => void
  readonly?: boolean
}

function UploadMultiplyFiles({ handleSubmit, readonly }: Props) {
  const [files, setFiles] = useState<Record<string, File>>({});
  const [images, setImages] = useState<Record<string, ArrayBuffer>>({});
  const { selectedFiles, selectedImages } = useAppSelector(state => state.chat)
  const dispatch = useAppDispatch();

  const handleFileChange = (event: any) => {
    const file = event.target.files[0]
    dispatch(setSelectedFiles({ ...selectedFiles, [file.name]: file })); // Set the image as a data URL
    //setSelectedFile(file)
    const reader = new FileReader();
    reader.onload = () => {
      dispatch(setSelectedImages({ ...selectedImages, [file.name]: reader.result })); // Set the image as a data URL
    };
    reader.readAsDataURL(file); // Read the file as a Data URL
  }

  const handleFileDelete = (name: string) => {
    dispatch(setSelectedFiles({ ..._.omit(files, name) }));
    dispatch(setSelectedImages({ ..._.omit(images, name) }));
  }

  useEffect(() => {
    setImages(selectedImages)
    setFiles(selectedFiles)
  }, [selectedFiles, selectedImages])

  return (
    <FormWrapper>
      <MediaWrapper>
        {_.keys(images).map((name, index) => {
          return files[name].type.includes('video') ?
            <ImgWrapper key={index}>
              <video
                src={images[name]}
                alt="Preview"
              />
              {!readonly && <DeleteOutline onClick={() => handleFileDelete(name)} />}
            </ImgWrapper> :
            <ImgWrapper key={index}>
              <img
                src={images[name]}
                alt="Preview"
              />
              {!readonly && <DeleteOutline onClick={() => handleFileDelete(name)} />}
            </ImgWrapper>
        })}
        {!readonly && <label htmlFor="raised" style={{ display: 'block' }}>
          <AddMediaSign >+
            <input
              style={{ display: 'none' }}
              name='file'
              type='file'
              onChange={handleFileChange}
              accept="image/*,video/*"
              id="raised"
            />
          </AddMediaSign>
        </label>}
      </MediaWrapper>
      {!readonly && <Button variant='contained' onClick={handleSubmit}>שלח</Button>}
    </FormWrapper>
  )
}

export default UploadMultiplyFiles

const MediaItemStyle = {
  width: 60,
  height: 60,
  borderRadius: 4,
}

const ImgWrapper = styled('div')({
  position: 'relative',
  '& img': MediaItemStyle,
  '& video': MediaItemStyle,
  '& svg': {
    position: 'absolute',
    right: '-12px',
    top: '-12px',
    background: '#fff',
    padding: '3px',
    color: 'rgba(141, 187, 223, 1)',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
  }
})

const FileInput = styled('input')({
  position: 'relative',
  width: 23,
  zIndex: 99
})

const IconWrapper = styled('div')({
  position: 'absolute',
  fontSize: 11,
  top: 0,
  left: -1,
  zIndex: 999,
  cursor: 'pointer',
  background: '#A29C96',
  display: 'initial',
  padding: '0px 5px',
  borderRadius: '50%'
})

const FormWrapper = styled('div')({
  display: 'flex',
  gap: 20,
  flexDirection: 'column',
  '& button': {
    backgroundColor: 'rgba(0, 83, 163, 1)',
    fontSize: '1rem',
    padding: 2,
    //width: '-webkit-fill-available',
  }
})

const MediaWrapper = styled('div')({
  display: 'flex',
  gap: 20,
  marginTop: 20,
})


const AddMediaSign = styled('div')({
  width: 60,
  height: 60,
  paddingBottom: 60,
  background: 'rgba(232, 241, 249, 1)',
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'center',
  fontSize: 50,
  fontFamily: 'math',
  color: '#fff',
  cursor: 'pointer',
})
