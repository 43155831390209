export interface IChatMessageType {
  className?: string
  timestamp: number | string
  title?: string
  text?: any
  owner: string
  subject: string
  createDate?: Date | undefined
  statusID?: number
  file?: any,
  userAnswer?:any
}

export type InitialStateType = {
  title: string
  text: any
  owner: string
  timestamp: number
  subject: string
}[]

export interface ChatDataProps {
  selectedSubCategory?: IRenderedQuestionData
  visibleForm?: boolean
  selectedCategory?: ICategory
  showChatList?: boolean
  titleName?: string
  lastGroupedNotes?: string
}
export interface IRenderedQuestionData {
  id: number
  empCode: number
  subCode: number
  subDescription: string
  representativeName: string
  text: string
  items: any[]
  type?:QuestionType
  expired?:number
  needPhoto?: boolean
  needVideo?: boolean
}

export interface ICategory {
  id: number
  text: string
  subCode: number
  empCode: number
  subDescription: string
}

export interface IPaymentData {
  amountPaid: string
  amountLeftToPay: string
  totalPrice: string
  percentage: string
  nextPaymentDate: string
  nextPaymentAmount: string
  currentBalance: string
  upcomingPayments: string
  updatedToDate: boolean | null
}

export type nextPaymentsListType = {
  date: string
  monthlyInterest: number
  paymentCode: number
  priceWithVat: string
  purposeOfPayment: boolean | null
  paymentNumber: boolean | null
  status: number
}

export type nextPaymentsType = {
  date: string
  monthlyInterest: number
  paymentCode: number
  priceWithVat: string
  purposeOfPayment: boolean | null
  paymentNumber: boolean | null
  status: number
}
export interface INextListData {
  paymentsList: nextPaymentsListType[]
  nextPayment: nextPaymentsType[]
  totalPayment: number
}

export type guaranteesListType = {
  id: string
  amount: string
  numberOfGuarantee: string
  startDate: string
}
export interface IGuaranteesData {
  guaranteesList: guaranteesListType[]
}

export type balanceListType = {
  fund: string
  linkage: string
  interest: string
  vat: string
  debtFor: string
  totalPayment: string
}
export interface IBalanceData {
  balanceList: balanceListType[]
}

export type IAmountPaidData = {
  receptionNumber: string
  date: string
  fund: string
  linkage: string
  interest: string
  vat: string
  totalPayment: string
  paidFor: string
}

export interface IFuturePayment {
  amount: string
  futurePaymentFor: string
  id: number
  linkage: string
  paymentDate: string
  interest: string
  vat: string
  total: string
}
export interface IQuestionData {
  sugCode: number
  sugDescription: string
  subCode: number
  subDescription: string
  empCode: number
  representativeName: string
  employeeEmail: string
  value: string
  type?:QuestionType
  expired?:number
  needPhoto?: boolean
  needVideo?: boolean
}

export enum QuestionType {
  GENERAL = 'general',
  BEDEQ = 'bedeq'
}
export interface IAllQuestionsData {
  services: servicesType[] | undefined
}
export type servicesType = {
  firstMessages?: IChatMessageType[]
  callID?: number
  subject?: string | null
  descrption?: string
  createDate: string
  statusID?: number
  ActivityCalls?: ActivityCallsType[]
  owner?: string
  text?: string
  timestamp?: string
}
export type ActivityCallsType = {
  Notes?: string
  Details?: string
  statusID?: number
  owner?: string
  title?: string
  text?: string
  timestamp: string
}

export type ParkingPlace = {
  propertyName: any
  name: string
  floor: string
}
export type ApartmentType = {
  address: string
  building: string
  PropertyName: string
  name: string
}
export type storeRoomType = {
  name: string
  floor: string
}

export interface IContractData {
  purchaseOverview: {
    signDate?: string
    estimatedDate?: string
    priceWithTax: string
    lotNumber: string
    deliveryChangeDate?: string
  }
  parking: ParkingPlace[]
  apartment: ApartmentType
  storeRoom: storeRoomType
  rooms: string[]
}

export type ChosenMaterialsType = {
  name: string
  subjectName: string
  supplierName: string
  subjectId: number
  lastTimeToChoose: string
  type: string
  lastDateToChoose: string
  chosen_date: string
  invite_date: string
  room: string
  model: string
  note: string
}

export interface AccordionNotChosenMaterialsProps {
  material: NotChosenMaterialsType;
  suppliersMaterialsData?: IMaterialSuppliersListData;
}

export type NotChosenMaterialsType = {
  name: string
  subjectName: string
  supplierName: string
  subjectId: number
  lastDateToChoose: string
  lastTimeToChoose: string
  type: string
  chosen_date: string
  invite_date: string
  room: string
  model: string
  note: string
}

export type ProgressStatusType = {
  status: {
    basement: number
    form4: any
    permit: any
    structure: number
    finishing: number
  }
}
export type StatusType = {
  basement: number
  form4: any
  permit: any
  structure: number
  finishing: number
}
export interface IFinishingMaterialsData {
  progressStatus: ProgressStatusType
  status: StatusType
  changes: IMaterialsChange[]
  ChosenMaterials: ChosenMaterialsType[]
  notChosenMaterials: NotChosenMaterialsType[]
}

export interface IMaterialSuppliersListData {
  data: IFinishingMaterialsList[];
  subjectId: number;
}

export interface IFinishingMaterialsList {
 supplierName: string;
  supplierId: string;
  specialization: string[];
  siteUrl: string;
  about: string | null;
  address: string;
  city: string;
  Street: string;
  Latitude: string | null;
  Longtitude: string | null;
  phone: string | null;
  wazeLink: string | null;
}
export interface IMaterialsChange {
  changes: {
    priceWithoutVat: number
    vat: number
    total: string
    changeEstimateID: string 
    totalWorkingDays: number 
    description: string
    bonusCode: number
    addonsDate: string
    changesDetails: IChangesDetails[]
  }
}

export interface IChangesDetails {
  rooms: string[]
  subject: string
  totalPricewithoutVat: number 
  totalVat: number
  totalPrice: number
}[]

export interface IToolTipData {
  key: string
  value: string
}
export interface IAppointmentData {
  success: boolean
  data: {
    url: string
  }
}
export interface IAppointmentOfficeData {
  success: boolean
  data: {
    future: futureData[]
  }
}

export type futureData = {
  id: string
  created: string
  cancelled: string
  status: string
  from: string
  to: string
  staffname: string
  service: string
  servicename: string
}
export interface IFilesData {
  name: string
  url: string
  created: string
  uploadToApp: boolean
}
export type suppliersListType = {
  supplierName: string
  supllierId: string
  specialization: string[]
  siteUrl: string
  address: string
  city: string
  Street: string
  Latitude: string
  Longtitude: string
  phone: string
  wazeLink: string
}
export interface IFetchsuppliersData {
  suppliersList: suppliersListType[]
}
