import React from 'react'
import { Info } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useState } from 'react'
import { CardText, InnerCard, ToolTipContent } from './style'

interface ICustomTooltipProps {
  tooltipTitle?: string
  text: string
  moneyAmount?: string
  hasTooltip?: boolean
}

const ModalLine = ({ tooltipTitle, text, moneyAmount, hasTooltip }: ICustomTooltipProps) => {
  const [open, setOpen] = useState(false)
  return (
    <InnerCard>
      {hasTooltip && (
        <ToolTipContent>
          <CardText>{text}</CardText>
          <Tooltip
            dir='rtl'
            disableTouchListener
            title={tooltipTitle}
            open={open}
            onClose={() => setOpen(false)}
            onMouseEnter={() => setOpen(true)}
            onMouseLeave={() => setOpen(false)}
            onClick={() => setOpen(!open)}
            onMouseDown={(e) => {
              e.preventDefault()
              setOpen(!open)
            }}
            componentsProps={{
              tooltip: {
                sx: {
                  marginTop: '0px !important',
                  maxWidth: '200px'
                }
              }
            }}
          >
            <IconButton style={{ width: '31px', padding: '5px' }}>{moneyAmount && <Info />}</IconButton>
          </Tooltip>
        </ToolTipContent>
      )}
        {!hasTooltip && (<CardText>{text}</CardText>)}
      <CardText>₪{parseFloat(moneyAmount!).toLocaleString()}</CardText>
    </InnerCard>
  )
}

export default ModalLine
