import React, { useEffect, useState } from 'react'
import { Checkbox, Typography, Box } from '@mui/material'
import { goToPreviousStep, sendOtp, setLoginError, setStep, validateOtp } from '../../state/features/user.feature'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks'
import OTP from './OtpInput'
import ErrorLogin from './ErrorLogin'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes.constants'
import { useThemeMobile } from '../../hooks/themeMobile.hook'
import { InitialLoginButton, LoginSubtitle, LoginTitle, ThirdLoginMainContainer } from './Login.styles'

function ThirdStepLogin() {
  const isMobile = useThemeMobile()

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { firstStepLoginSession, secondStepVerification, otpMethod, isPageError, loginError } = useAppSelector(
    (state) => state.user
  )

  const [otp, setOtp] = React.useState('')
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [title, setTitle] = useState('')
  const [subTitle, setSubTitle] = useState('')

  useEffect(() => {
    if (otpMethod === 'email') {
      setTitle('בדוא״ל')
      setSubTitle('לכתובת הדוא״ל המוזנת במערכת')
    } else if (otpMethod === 'phoneNumber') {
      setTitle('לנייד')
      setSubTitle('לנייד המוזן במערכת')
    }

    return () => {
      dispatch(setLoginError(null))
    }
  }, [otpMethod, dispatch])

  const handlePreviousPage = () => {
    if (secondStepVerification) {
      dispatch(goToPreviousStep())
      dispatch(setLoginError(''))
    } else {
      dispatch(setStep(1))
    }
  }

  const handleResendOtp = async () => {
    try {
      const response = await dispatch(
        sendOtp({ signedGuid: firstStepLoginSession?.signedGuid, selectedOption: otpMethod })
      )
    } catch (err) {
      console.error(err)
    }
  }

  const handleValidateOtp = async () => {
    try {
      const response = await dispatch(validateOtp({ verification: secondStepVerification, otp }))
      navigate(`${ROUTES.DASHBOARD}/#progressStages`)
    } catch (err) {
      console.error(err)
    }
  }

  const isButtonDisabled = !isCheckboxChecked || otp.length !== 6

  return (
    <ThirdLoginMainContainer isMobile={isMobile}>
      <Box className='top-section'>
        <LoginTitle isMobile={isMobile}>שלחנו לך קוד אימות {title}</LoginTitle>
        {isPageError ? (
          <LoginSubtitle isMobile={isMobile} className='subtitle'>
            הקוד נשלח לפרטי המשתמש במערכת
          </LoginSubtitle>
        ) : (
          <LoginSubtitle isMobile={isMobile} className='subtitle'>
            הקוד נשלח {subTitle}, לפעמים לוקח מס׳ שניות עד קבלת ההודעה
          </LoginSubtitle>
        )}
      </Box>

      <Box className='otp-container'>
        <OTP separator={<span> </span>} value={otp} onChange={setOtp} length={6} />

        {loginError && (
          <ErrorLogin customTop={'68px'} customRight={isMobile ? 'unset' : '25px'} loginError={loginError} />
        )}
      </Box>

      <Box className='submit-container'>
        <Box className='checkbox-container'>
          <Checkbox required onChange={(e) => setIsCheckboxChecked(e.target.checked)} />
          <Typography>
            קראתי והבנתי את{' '}
            <Typography
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              component='a'
              href='https://www.dimri.co.il/%d7%aa%d7%a0%d7%90%d7%99-%d7%a9%d7%99%d7%9e%d7%95%d7%a9/'
              target='_blank'
              rel='noopener noreferrer'
            >
              תנאי השימוש והסודיות
            </Typography>
          </Typography>
        </Box>
        <InitialLoginButton disabled={isButtonDisabled} className='third-step-login' onClick={handleValidateOtp}>
          <Typography>כניסה</Typography>
        </InitialLoginButton>
      </Box>

      <Box className='bottom-section'>
        <Typography sx={{ cursor: 'pointer' }} onClick={handlePreviousPage}>
          חזרה לשלב הקודם
        </Typography>
        {isPageError ? null : (
          <Typography sx={{ cursor: 'pointer' }} onClick={handleResendOtp}>
            שלחו לי קוד שוב
          </Typography>
        )}
      </Box>
    </ThirdLoginMainContainer>
  )
}

export default ThirdStepLogin
