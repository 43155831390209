import { styled } from 'styled-components'
import { theme } from '../../../constants/theme.constants'
import { Colors } from '../../../constants/styles.constants'

export const MainWrapper = styled('div')({
  position: 'relative',
  padding: '4rem 2rem 0 2rem',
  [theme.breakpoints.down('xl')]: {
    padding: '4rem 1rem 0 1rem'
  }
})

export const ListWrapper = styled('div')({
  width: '100%',
  margin: 0,
  padding: 0,
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
})

export const ListPayment = styled('ul')({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  listStyle: 'none',
  padding: '0',
  '& .amount-pay-modal .modal-main-wrap': {
    top: '66%',
    right: '-100%',
    [theme.breakpoints.down('md')]: {
      right: '-195px'
    }
  },
  [theme.breakpoints.down('lg')]: {
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  [theme.breakpoints.down('md')]: {
    display: 'block'
  }
})

export const NextpaymentWrapper = styled('div')({
  [theme.breakpoints.down('md')]: {
    textAlign: 'center'
  },
  '& .MuiButtonBase-root.MuiButton-root': {
    width: '170px',
    maxWidth: '170px',
    fontSize: 22,
    fontWeight: 'normal',
    lineHeight: 'normal',
    textAlign: 'center',
    color: Colors.white,
    padding: '6px 34px 6px 34px',
    borderRadius: '20px',
    backgroundColor: Colors.lightBlueButton,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      margin: '2rem'
    }
  }
})

export const MobileWrapper = styled('div')({
  [theme.breakpoints.down('md')]: {
    display: 'block',

    '&.MuiAccordionDetails-root': {
      padding: '0px 5px 16px'
    },
    '& h2.mobile-view': {
      display: 'none'
    },
    '& .MuiAccordionDetails-root': {
      padding: '10px'
    }
  }
})

export const NextPaymentModal = styled('div')({
  background: '#e2eef8',
  width: '100%',
  position: 'absolute',
  margin: 'auto',
  bottom: '0px',
  left: '0',
  right: '0',
  height: '100%',
  borderRadius: '8px',
  zIndex: '99',
  [theme.breakpoints.down('md')]: {
    position: 'fixed',
    height: '100vh'
  }
})

export const SpaceBetweenWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '1.5rem 1.5rem 0.125rem 1.5rem'
})

export const SpanArrow = styled('span')({
  fontSize: '22px',
  lineHeight: '26px',
  cursor: 'pointer'
})
