import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux.hooks'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material'
import {
  AccordionWrapper,
  AccordionWrapperInner,
  DateWrapper,
  DynamicText,
  InnerItem,
  ItemText
} from '../../../../../constants/styled.components.constants'
import Loader from '../../../../UI/Loader/Loader'
import InnerAccordion from '../../../../UI/InnerAccordion/InnerAccordion'
import { NotChosenMaterialsType, IFinishingMaterialsList } from '../../../../../constants/interfaces.constant'
import { AccordionDetails, InnerContent, InnerPhoneWrapper, LinkUrl } from './style'
import { fetchMaterialSuppliersList } from '../../../../../state/features/finishingMaterials.features'

function AccordionNotChosenMaterials({ material }: { material: NotChosenMaterialsType }) {
  const dispatch = useAppDispatch()
  const { name: title, lastDateToChoose, room, subjectId } = material
  const { loading, suppliersMaterialsMapper } = useAppSelector((state) => state.finishingMaterials)
  const { contractId } = useAppSelector((state) => state.user)
  const formattedDate = lastDateToChoose?.split('-')?.join('.')

  useEffect(() => {
    if (subjectId && !suppliersMaterialsMapper[subjectId]) {
      dispatch(
        fetchMaterialSuppliersList({
          contractId,
          subjectId
        })
      )
    }
  }, [subjectId, contractId, dispatch, suppliersMaterialsMapper])

  const currentSuppliers = suppliersMaterialsMapper[subjectId]

  const renderSuppliersList = () => {
    if (!currentSuppliers?.data?.[0]) return null

    const responseData = currentSuppliers.data[0] as any

    if (responseData.error) {
      return <InnerContent>לא נמצאו ספקים מתאימים אנא צור קשר עם שירות לקוחות</InnerContent>
    }

    const suppliersList = responseData.suppliersList
    if (!Array.isArray(suppliersList)) return null

    return suppliersList
      .filter((supplier: IFinishingMaterialsList) => supplier.specialization.includes(title))
      .map((supplier: IFinishingMaterialsList, index: number) => (
        <InnerContent key={`${supplier.supplierId}-${index}`}>
          <InnerAccordion title={supplier.supplierName} subTitle={`${supplier.Street}, ${supplier.city}`} />
          <InnerPhoneWrapper>
            {supplier.phone && <InnerAccordion title='טלפון' subTitle={supplier.phone} />}
            {supplier.siteUrl && (
              <LinkUrl href={supplier.siteUrl} className='link-to' target='_blank' rel='noopener noreferrer'>
                אתר הספק
              </LinkUrl>
            )}
          </InnerPhoneWrapper>
        </InnerContent>
      ))
  }

  return (
    <AccordionWrapper>
      <CustomAccordion>
        <CustomAccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
          <AccordionWrapperInner className='accordionNotChosenMaterials'>
            <DateWrapper>
              <ItemText>מועד אחרון לבחירה</ItemText>
              <DynamicText>{formattedDate}</DynamicText>
            </DateWrapper>
            <InnerItem>
              <InnerAccordion title='חדר' subTitle={room} />
              <InnerAccordion title='תיאור' subTitle={title} />
            </InnerItem>
          </AccordionWrapperInner>
        </CustomAccordionSummary>

        <AccordionDetails className='suppliersData'>
          {loading ? (
            <Loader />
          ) : (
            renderSuppliersList() || <InnerContent>לא נמצאו ספקים מתאימים אנא צור קשר עם שירות לקוחות</InnerContent>
          )}
        </AccordionDetails>
      </CustomAccordion>
    </AccordionWrapper>
  )
}

export default AccordionNotChosenMaterials

const CustomAccordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const CustomAccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme?.palette?.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  }
}))
