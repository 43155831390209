export enum Environment {
  Local = 'local',
  Development = 'development',
  Production = 'production'
}

export const SERVER_URLS = {
  [Environment.Local]: 'http://localhost:3000',
  [Environment.Development]: 'https://dimri-dev-server.azurewebsites.net',
  [Environment.Production]: 'https://api.dimriapps.com'
}
export const WP_SERVER_URLS = {
  [Environment.Local]: 'https://dimridev.wpengine.com',
  [Environment.Development]: 'https://dimridev.wpengine.com',
  [Environment.Production]: 'https://dimri.co.il/'
}

export enum API_ENDPOINTS {
  SEND_ACTIVITY = '/user/services/:serviceId/message',
  UPLOAD_FILES = '/user/services/:serviceId/file',
  SEND_FILE = 'user/uploadFiles',
  GET_STATUS = '/myApartment/getApartmentProgress/',
  GET_FILES = '/user/getFiles',
  SEND_QUESTION = '/user/services/create',
  GET_ALL_QUESTIONS = '/user/services/all',
  GET_QUESTION = '/generals/questions',
  AMOUNT_PAID = '/payments/amountPaid',
  BALANCE_LIST = '/payments/balance',
  SUPPLIERS = '/myApartment/suppliers',
  MATERIAL_SUPPLIERS_LIST = '/myApartment/materialSuppliersList',
  APPOINTMENT = '/myApartment/tor4you/getURL',
  GET_APPOINTMENT = '/myApartment/tor4you/getAppointments',
  TOOL_TIP = '/services/generalsService/tooltip?key=',
  TOOL_TIP_ALL = '/generals/tooltips',
  TERMS_OF_USE = '/wp-json/wp/v2/pages?include=49',
  DICTIONARY_TABLE = '/wp-json/app/GetTable?table_id=2',
  INITIATE_LOGIN = '/auth/initiateLogin',
  SEND_OTP = '/auth/sendOtpV2',
  GENERATE_OTP = '/auth/generateOtp',
  VALIDATE_OTP = '/auth/validateOtpV2',
  LOGIN = '/auth/login',
  PROFILE_DATA = '/profile',
  SILENT_LOGIN = '/user/silentLogin',
  FORGOT_PASSWORD = '/auth/forgotPassword',
  GET_PAYMENT_OVERVIEW = '/payments',
  GET_PAYMENT_SCHEDULE = '/payments/paymentsList',
  GET_GUARANTEES = '/payments/guarantees',
  CONTRACT_OVERVIEW = '/myApartment/contractDetails',
  FINISHING_MATERIALS = '/myApartment/tenantChanges',
  SET_PASSWORD = '/user/setPassword',
  GET_FUTURE_PAYMENT_LIST = '/payments/futurePayments',
  FAQ = '/open/faq',
  GET_ACTIVITY_FILE = '/open/files/get',
}

export const ERROR_CODE = {
  wrongInput: 400,
  unauthorized: 401,
  forbidden: 403,
  pageNotFound: 404,
  serverError: 500
}
