import React from 'react'
import { Colors } from '../../../../constants/styles.constants'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { PaymentsText, PaymentsTextNumber } from '../../../../constants/styled.components.constants'
import { CircularProgressInner, CircularProgressbarWrapper, ListItem, ProgressbarTextWrapper } from './style'

type CIrcularProgressProps = {
  totalPrice?: string
  percentage?: number
}

const CircularProgress = ({ totalPrice, percentage }: CIrcularProgressProps) => {
  return (
    <ListItem>
      <CircularProgressbarWrapper>
        <CircularProgressInner>
          <CircularProgressbar
            value={percentage || 0}
            styles={{
              path: {
                transformOrigin: 'center center',
                stroke: Colors.orange,
                position: 'relative'
              },
              text: {
                fontSize: 22,
                fontWeight: 800,
                animation: 'fadein 2s',
                fill: Colors.black
              }
            }}
          />
          <ProgressbarTextWrapper className='progressbar-mobile-view'>
            <PaymentsText> עלות רכישה</PaymentsText>
            <PaymentsTextNumber>{parseFloat(totalPrice || '').toLocaleString()} ₪</PaymentsTextNumber>
          </ProgressbarTextWrapper>
        </CircularProgressInner>
      </CircularProgressbarWrapper>
    </ListItem>
  )
}

export default CircularProgress
