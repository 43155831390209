import React, { useEffect, useState } from 'react'
import Chatbackground from '../../../assets/images/Chatbackground.png'
import styled from 'styled-components'
import ChatCard from '../ChatOnLine/ChatCard/ChatCard'
import FormInput from '../FormInput/FormInput'
import { useChatScroll } from '../../../hooks/useChatScroll/useChatScroll'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import { theme } from '../../../constants/theme.constants'
import { servicesType } from '../../../constants/interfaces.constant'
import { Box } from '@mui/material'
import { isSameDay, isToday } from 'date-fns'
import _ from 'lodash'

type Props = {
  handleInputData?: any
  visibleForm?: boolean
  error?: string
  fileResponse?: any
  allQuestionsData?: servicesType[]
  selectedFile?: any
  setSelectedFile?: any
  setFileColor?: any
  fileColor?: any
  callID?: any
  openAppealStepDone?: boolean
  setOpenAppealStepDone?: any
}
function ChatBackgroundSreen({
  allQuestionsData,
  fileResponse,
  error,
  handleInputData,
  visibleForm,
  selectedFile,
  setSelectedFile,
  setFileColor,
  fileColor,
  openAppealStepDone,
  setOpenAppealStepDone
}: Props) {
  const { messages, callID } = useChatMessages()
  // const sortedMessages = messages && messages.slice().sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0))
  const [sortedMessages, setSortedMessages] = useState<any[]>(
    _.isArray(messages) ?
      messages?.slice().sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0))
      : [])
  const ref = useChatScroll(sortedMessages)

  useEffect(() => {
    if (!messages) return;

    const updatedMessages = _.isArray(messages) ?
      messages.slice().sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0)).map((item, index) => {
        if (item?.type === 'openAppealStep' && openAppealStepDone) {
          item.text = <p key={index} style={{ fontSize: "18px" }}>מעולה, משהו נוסף?</p>
          setOpenAppealStepDone(false)
        }
        return item;
      }) : messages;

    setSortedMessages(updatedMessages);
  }, [messages, openAppealStepDone]);

  return (
    <ChatBackground>
      <div
        className='sreen-chat'
        ref={ref}
        style={{
          width: '100%',
          overflow: 'scroll',
          scrollbarWidth: 'none'
        }}
      >
        {_.isArray(sortedMessages) &&
          sortedMessages.map((item: any, index: any) => {
            return (
              <span key={index}>
                {(index === 0 ||
                  (index > 0 &&
                    !isSameDay(new Date(item?.timestamp), new Date(sortedMessages[index - 1]?.timestamp)))) && (
                    <Box sx={{ textAlign: 'center', marginRight: '3rem' }}>
                      {isToday(new Date(item?.timestamp)) ? 'היום' : new Date(item?.timestamp).toLocaleDateString()}
                    </Box>
                  )}
                <ChatCard
                  fileId={item?.fileId}
                  url={item?.url}
                  className={item?.className}
                  fileResponse={fileResponse}
                  //key={index}
                  item={item}
                  subject={item?.subject}
                  title={item?.title}
                  owner={item?.owner}
                  text={item?.text}
                  timestamp={item?.timestamp}
                  filename={item?.filename}
                />
              </span>
            )
          })}
      </div>

      {(visibleForm || (allQuestionsData?.find(item => item.callID === callID)?.statusID !== -1 && callID)) && (
        <FormInput
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          callID={callID}
          allQuestionsData={allQuestionsData}
          error={error}
          handleInputData={handleInputData}
          setFileColor={setFileColor}
          fileColor={fileColor}
          hideFileInput={!!!callID}
        />
      )}
    </ChatBackground>
  )
}

export default ChatBackgroundSreen

const ChatBackground = styled('div')({
  position: 'relative',
  backgroundImage: `url(${Chatbackground})`,
  width: '100%',
  height: '80svh',
  overflow: 'auto',
  overflowY: 'auto',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  flexDirection: 'column',
  [theme.breakpoints.down('xl')]: {
    height: '73svh'
  },
  [theme.breakpoints.down('md')]: {
    height: '82svh'
  }
})
