export const CHAT_TEXTS = {
  howCanWeHelpYou: 'איך נוכל לעזור לך?',
  customersService: 'שרות לקוחות',
  emptyFieldError: 'שדה זה חייב להכיל תווים/הודעה',
  anyThingElse: 'מעולה!🤓 משהו נוסף?',
  addMore: 'כן, אני רוצה להרחיב',
  continueAppeal: 'לא, אפשר להתחיל לטפל בפניה',
  msgSent: 'ההודעה נשלחה אנא המתן מספר שניות לקבלת מספר פנייה :)',
  addWhat: 'מה תרצה להוסיף',
  msgDidntSend: 'הודעתך לא נשלחה אנא נסה שוב מאוחר יותר',
  newAppeal: 'פנייה חדשה',
  uploadPhoto: 'נא להעלות תמונה',
  tyForComing: ' תודה על פנייתך,',
  subSubjectBedeqTitle: 'בחרת לפנות אלינו בנושא שנת בדק, תבחר/י בבקשה תת נושא',
  expiredBedeqTitle: 'תקופת האחריות הסתיימה',
  expiredBedeq: 'נראה  שתקופת האחריות שלך הסתיימה, לכן לא ניתן לפתוח קריאה חדשה בנושא זה. ניתן לפנות לתמיכה למחלקת בדק bedek@dimri.co.il',
}

export const getStepSubjectText = (subject: string) =>
  `בחרת ב${subject} כתב/י כעת את נושא הפניה. לתשומת ליבך: ניתן יהיה לצרף קבצים נלווים לאחר קבלת מספר הפניה במערכת.`

export const getChooseSubCategoryText = (Category: string) =>
  `בחרת לפנות אלינו לגבי ${Category} 👍 בחרֿֿֿ/י כעת תת נושא`

export const getPostSentMsg = (ID: string) =>
  `מספר הפניה במערכת ${ID}. פנייתך תטופל בהקדם האפשרי, תודה על הסבלנות. ניתן
  כעת לצרף קבצים לפניה.`

export const newActivityCallText = (subject: string, callID: string) => {
  return {
    callSubject: `בחרת ב${subject} כתב/י כעת את נושא הפניה. לתשומת ליבך: ניתן יהיה לצרף קבצים נלווים לאחר קבלת מספר הפניה במערכת.`,
    messageSent: 'הודעתך נשלחה, נא המתן מספר שניות לקבלת אישור',
    thanks: ` תודה על פנייתך`,
    callBeingHandled: `מספר הפניה במערכת ${callID}. פנייתך תטופל בהקדם האפשרי, תודה על הסבלנות. ניתן כעת לצרף קבצים לפניה.`
  }
}
