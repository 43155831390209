export enum Colors {
  orengeText = '#ff4c01',
  lightBlueTwo = '#ccdded',
  GreenBoderColor = '#77bc20',
  lightBrownSecond = '#f5f4f2',
  softBrown = '#eeece7',
  transparent = 'transparent',
  textColor = '#2f2722',
  blue = '#0053a3',
  lightBlueRadio = '#8DBBDF',
  lightBlueButton = '#4295e5',
  lightBlueBG = '#e2eef8',
  softBlue = '#eff4f9',
  disabledBlueLoginButton = '#E8F1F9',
  lightBlue = '#2487e5',
  darkBlue = '#324754',
  primary = '#9d9f8f',
  primaryLight = '#efeeeb',
  secondary = '#f79780',
  white = '#ffffff',
  red = '#f61e1e',
  orange = '#ff4c00',
  black = '#242424',
  lightBlack = '#3b3b3b',
  footerFontText = '#49382a',
  borderFooter = '#423a32',
  gray = '#acacac',
  veryLightBrown = '#9C8E83',
  lightBrown = '#6f655b',
  radioButtonBrown = '#645F5B',
  primaryBrowncolor = '#555',
  darkBrown = '#473b33',
  lighterBrown = '#efeeeb',
  header = '#999184',
  cardBackground = '#f4f3f0',
  cardText = '#3a3a3a',
  activeLink = '#9d9f8f',
  loginCoverBg = '#e0e1db',
  brown = '#473b33',
  grayBg = '#eae9e4',
  disabledLoginButtonText = '#CBC9C0',
  yellowBorderLogin = '#FDD3A4',
  checkboxOrange = '#EC947A',
  DarkerBlue = '#2D3648',
  BorderGrey = '#F1F7FC',
  lightBlueBackground = '#D1E3F8',
  lightBlueBorder = '#84B9EE',
  lightGreenStatusBackground = '#DBEBD0',
  lightRedStatusBackground = '#FBEAE4',
  lightBlueStatusBackround = '#E8F1F9'
}

export enum FontFamilies {
  regular = 'AlmoniRegular',
  light = 'AlmoniLight',
  demiBold = 'AlmoniDemiBold'
}

export enum ButtonAppearance {
  primary,
  secondary
}
