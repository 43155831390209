import React, { useRef, useState } from 'react'
/*components*/
import { List, ListItem, ListItemIcon, ListItemText, styled } from '@mui/material'
import { Colors } from '../../../constants/styles.constants'
import { QuestionType } from '../../../constants/interfaces.constant'
import { ItemInnerWrapper } from '../../../constants/styled.components.constants'
import _ from 'lodash'
import { Check } from '@mui/icons-material'
import { textAlign } from '@mui/system'

type Props = {
  rooms: string[] | null
  onSelectRooms: any
  selectedCategory: any
}
function Rooms({
  rooms,
  onSelectRooms,
  selectedCategory
}: Props) {
  const [selectedRooms, setSelectedRooms] = useState<string[]>([])
  return (
    <>
      {rooms && (
        <React.Fragment>
          <MainWrapper className={true ? 'chatstep' : ''}>
            <List sx={style} component='nav' aria-label='mailbox folders'>
              {rooms.map((item: any, index: number) =>
                <ListItem button key={index} sx={listItemStyle} className={selectedRooms.includes(item) ? 'selected' : ''} chec>
                  <ListItemText onClick={() => {
                    if (selectedRooms.includes(item)) {
                      setSelectedRooms(_.without(selectedRooms, item))
                    } else {
                      setSelectedRooms([...selectedRooms, item])
                    }
                    //onSelectRoom(item)
                  }} primary={item} />
                  {selectedRooms.includes(item) && <ListItemIcon>
                    <Check fontSize='small' sx={{ color: 'rgba(0, 83, 163, 1);' }} />
                  </ListItemIcon>}
                </ListItem>
              )}
              <ListItem button sx={listItemStyle} className={`send-button ${selectedRooms.length ? 'enable' : ''}`} >
                <ListItemText
                  primary={'שלח'}
                  onClick={() => {
                    onSelectRooms(selectedRooms)
                  }}
                />
              </ListItem>
            </List>
          </MainWrapper>
        </React.Fragment>
      )}
    </>
  )
}

export default Rooms

const style = {
  width: '100%',
  maxWidth: 360,
  bgcolor: 'background.paper',
}

const listItemStyle = {
  cursor: 'pointer',
  background: Colors.white,
  borderRadius: "8.54px",
  my: "3.2px",
  height: "34px",
  textAlign: "start",
  padding: "8px 6px 0 0",
  '&.selected': {
    background: 'rgba(232, 241, 249, 1)',
  },
  '&.send-button': {
    background: 'rgba(232, 241, 249, 1)',
    textAlign: 'center',
    color: Colors.white,
    '&.enable': {
      background: 'rgba(0, 83, 163, 1)',
    }
  }
}

const MainWrapper = styled('div')({
  paddingBottom: "48px",
  '&.chatstep': {
    bottom: '6rem'
  },
  '& nav': {
    background: 'none',
    padding: '0',
    '& .MuiButtonBase-root.MuiListItem-gutters': {
      direction: 'rtl',
      textAlign: 'right',
      background: '#fff',
      borderRadius: '10px',
      margin: '4px 0'
    },
    '& hr': {
      display: 'none'
    },
    '& .MuiListItem-root': {
      paddingTop: '3px',
      paddingBottom: '3px'
    }
  }
})
