import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../../constants/routes.constants'
/*components*/
import Logo from '../../../assets/Logo/dimri_logo.svg'
import { LogoImage, LogoWrapper, NavBeforeLogin } from './Header.styles'

function NavBarBeforeLogin() {
  return (
    <NavBeforeLogin>
      <LogoWrapper>
        <Link to={ROUTES.DIMRI}>
          <LogoImage src={Logo} alt='logo-site-dimri' />
        </Link>
      </LogoWrapper>
    </NavBeforeLogin>
  )
}

export default NavBarBeforeLogin
