import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../constants/theme.constants'
import { DynamicText, ItemText } from '../../../constants/styled.components.constants'

type Props = {
  title?: string
  subTitle?: string
}

function InnerWrapperCard({ title, subTitle }: Props) {
  return (
    <InnerWrapCard>
      <ItemText> {title}</ItemText>
      <DynamicText>{subTitle}</DynamicText>
    </InnerWrapCard>
  )
}

export default InnerWrapperCard
export const InnerWrapCard = styled('div')({
  width: '49%',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
})
