import React from 'react'
import { Box, Stack } from '@mui/material'
import Payments from './Payments'
import Appeal from './Appeal'
import Documents from './Documents'
import MyApartment from './MyApartment'
import ProgressStages from './ProgressStages'
import { theme } from '../../constants/theme.constants'

function DashboardContent() {
  return (
    <Box
      sx={{
        width: '100%',
        margin: '2rem auto 0 auto',
        [theme.breakpoints.down('md')]: {
          margin: '1rem auto 0 auto',
          padding: '1rem 0 0 0',
          display: 'block'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          [theme.breakpoints.down('md')]: {
            margin: 0,
            padding: 0
          }
        }}
      >
        <Box
          sx={{
            paddingLeft: 0,
            [theme.breakpoints.down('xl')]: {
              padding: '0 10px'
            },
            [theme.breakpoints.down('md')]: {
              width: '100%',
              padding: 0
            }
          }}
        >
          <ProgressStages />
        </Box>

        <Box>
          <MyApartment />
        </Box>

        <Box>
          <Appeal />
        </Box>

        <Box>
          <Payments />
        </Box>

        <Box>
          <Documents />
        </Box>
      </Stack>
    </Box>
  )
}

export default DashboardContent
