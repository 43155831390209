import React, { useCallback } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import { useAppSelector } from '../../../hooks/redux.hooks'
import { Accordion, AccordionSummary, Button, styled } from '@mui/material'
import MobileAccordion from '../../UI/MobileAccordion/MobileAccordion'
import AccordionContentCorrespondences from './Correspondences'
import {
  AccordionWrapper,
  ButtonText,
  DynamicText,
  FlexWrapperTop,
  Plus,
  SectionTitle
} from '../../../constants/styled.components.constants'
import { ROUTES } from '../../../constants/routes.constants'
import Loader from '../../UI/Loader/Loader'
import { isNumber } from 'lodash'
import { useChatMessages } from '../../../hooks/useAddMessage/useChatMessages'
import { AccordionSummaryInner, HeaderContainer, MobileWrapper, WrapperList } from './styles'
import * as CONSTS from './constants'

const Appeal = () => {
  const navigate = useNavigate()
  const { width, breakpoint } = UseResizeWindow()
  const { setCallID } = useChatMessages()
  const { allQuestionsData, loading } = useAppSelector((state) => state.chat)

  const toNewAppealChat = useCallback(() => {
    navigate({ pathname: ROUTES.NEWCHAT })
    setCallID(undefined)
  }, [navigate, setCallID])

  const handleExistCall = useCallback(
    (callID = undefined) => {
      if (isNumber(callID)) {
        setCallID(callID)
        navigate({ pathname: ROUTES.NEWCHAT })
      }
    },
    [navigate, setCallID]
  )

  if (loading) {
    return <Loader />
  }

  const topNewestQuestion = allQuestionsData?.slice(0, 3)

  return (
    <>
      {width > breakpoint ? (
        <div style={{ padding: '72px 36px 0' }} id='appeal'>
          <HeaderContainer>
            <FlexWrapperTop>
              <SectionTitle>{CONSTS.APPEALS}</SectionTitle>

              <Button variant='outlined' onClick={toNewAppealChat}>
                <Plus>+</Plus>
                {CONSTS.NEW_APPEAL}
              </Button>
            </FlexWrapperTop>
            {allQuestionsData && allQuestionsData?.length > 0 ? (
              <Link to='onlinechat'>
                <DynamicText>{CONSTS.SHOW_ALL_APPEALS}</DynamicText>
              </Link>
            ) : (
              ''
            )}
          </HeaderContainer>

          {topNewestQuestion?.length ? (
            <WrapperList>
              {topNewestQuestion?.map((item: any, index: any) => (
                <AccordionContentCorrespondences
                  statusID={item.statusID}
                  key={index}
                  callID={item.callID}
                  createDate={item.createDate}
                  subject={item.subject}
                  handleClick={() => handleExistCall(item.callID)}
                />
              ))}
            </WrapperList>
          ) : (
            <AccordionWrapper className='appeal'>
              <Accordion>
                <AccordionSummary aria-controls='panel1d-content' id='appeal-header'>
                  <AccordionSummaryInner>
                    <DynamicText>
                      <p>{CONSTS.NO_RELEVANT_INFO}</p>
                    </DynamicText>
                  </AccordionSummaryInner>
                </AccordionSummary>
              </Accordion>
            </AccordionWrapper>
          )}
        </div>
      ) : (
        <MobileWrapper>
          <MobileAccordion title={CONSTS.APPEALS}>
            <>
              <HeaderContainer>
                <FlexWrapperTop>
                  <Button variant='outlined' onClick={toNewAppealChat}>
                    <Plus>+</Plus>
                    <ButtonText>{CONSTS.NEW_APPEAL}</ButtonText>
                  </Button>
                </FlexWrapperTop>
                <Link to='onlinechat'>
                  <DynamicText>{CONSTS.SHOW_ALL_APPEALS}</DynamicText>
                </Link>
              </HeaderContainer>
              {allQuestionsData ? (
                <WrapperList>
                  {allQuestionsData &&
                    allQuestionsData
                      .slice(0, 3)
                      .map((item: any, index: any) => (
                        <AccordionContentCorrespondences
                          key={index}
                          callID={item?.callID}
                          createDate={item?.createDate}
                          subject={item?.subject}
                          statusID={item?.statusID}
                          handleClick={() => handleExistCall(item.callID)}
                        />
                      ))}
                </WrapperList>
              ) : (
                <p>{CONSTS.NO_RELEVANT_INFO}</p>
              )}
            </>
          </MobileAccordion>
        </MobileWrapper>
      )}
    </>
  )
}

export default Appeal
