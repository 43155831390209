import * as React from 'react'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import { AccordionWrapper, DynamicText, ItemText } from '../../../../constants/styled.components.constants'
import { theme } from '../../../../constants/theme.constants'
import { appealLabels, appealStatus } from '../constants'

interface IAppealProps {
  callID: number
  createDate: string
  subject: string
  statusID: number
  handleClick?: () => void
}

function AccordionContentCorrespondences({ statusID, callID, createDate, subject, handleClick }: IAppealProps) {
  const getStatus = () => {
    switch (statusID) {
      case -3:
        return appealStatus.open
      case -2:
        return appealStatus.onWork
      case -1:
        return appealStatus.closed
      default:
        return ''
    }
  }

  return (
    <AccordionWrapper className='appeal' onClick={handleClick}>
      <AccordionSummary aria-controls='panel1d-content' id='appeal-header'>
        <AccordionSummaryInner>
          <DynamicText>{subject}</DynamicText>
          <DataWrapper>
            <DataWrapperInner>
              <ItemText className='mobile-view'>{appealLabels.date}</ItemText>
              <DynamicText>{new Date(createDate).toLocaleDateString()}</DynamicText>
            </DataWrapperInner>
            <DataWrapperInner>
              <ItemText className='mobile-view'>{appealLabels.id}</ItemText>
              <DynamicText className='mobile-view'>{callID}</DynamicText>
            </DataWrapperInner>
            <DataWrapperInner>
              <ItemText className='mobile-view'>{appealLabels.status}</ItemText>
              <DynamicText className='mobile-view'> {getStatus()}</DynamicText>
            </DataWrapperInner>
          </DataWrapper>
        </AccordionSummaryInner>
      </AccordionSummary>
    </AccordionWrapper>
  )
}

export default AccordionContentCorrespondences

const AccordionSummaryInner = styled('div')({
  width: '35%',
  '& : .MuiAccordionSummary-content': {
    display: 'block'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& .mobile-view': {
      display: 'none'
    }
  }
})
const DataWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})
const DataWrapperInner = styled('div')({
  justifyContent: 'flex-start',
  flexDirection: 'column',
  padding: 0
})

const AccordionSummary = styled((props: AccordionSummaryProps) => <MuiAccordionSummary {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))
