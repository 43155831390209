import { styled } from '@mui/material/styles'
import { Box } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'

export const CustomDivider = styled(Box)({
  border: `1px solid #EAF2FA`,
  width: '100%'
})
export const MainWrapper = styled('div')({
  padding: '0 1rem 0 0',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  }
})
export const NextMeetingWrapper = styled('div')({
  background: Colors.lightBrownSecond,
  border: `1px solid ${Colors.lightBrownSecond}`,
  width: '60%',
  padding: ' 9px 1rem',
  borderRadius: '8px',
  margin: '0 0 10px 0',
  '&:hover': {
    background: Colors.white
  },
  [theme.breakpoints.down('xl')]: {
    width: '70%'
  },
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
})

export const NextMeetingInner = styled('div')({
  display: 'flex',
  alignItems: 'center',
  minHeight: 50,
  '& a.link-url': {
    color: Colors.darkBrown,
    textDecoration: 'none'
  }
})
