import React, { useState } from 'react'
import { ModalBubblePayments, UpcomingPaymentsBubble } from '../../../../../constants/icons.constants'
import { PaymentsText, PaymentsTextNumber } from '../../../../../constants/styled.components.constants'
import { useAppSelector } from '../../../../../hooks/redux.hooks'
import UiModal from '../../../../UI/Modal/UiModal'
import ModalInnerCard from '../../modalInnerCard'
import { InnerContentWrapper, ItemIconsWrapper, ModalWrapper, TextDetail } from '../styles'
import { DetailButton } from '../../NextPayments/style'

const UpcomingPayments = () => {
  const { balanceData, paymentsInfoData } = useAppSelector((state) => state.payments)
  const balanceDataList = balanceData?.balanceList[0]
  const [show, setShow] = useState(false)
  return (
    paymentsInfoData?.upcomingPayments && (
      <ItemIconsWrapper>
        <UpcomingPaymentsBubble />
        <InnerContentWrapper>
          <PaymentsText>יתרתי נכון להיום</PaymentsText>
          <PaymentsTextNumber className='orange-color'>
            {parseFloat(paymentsInfoData?.currentBalance).toLocaleString()} ₪
          </PaymentsTextNumber>

          <DetailButton onClick={() => setShow(true)}>פירוט</DetailButton>
        </InnerContentWrapper>

        <ModalWrapper>
          <UiModal
            handleClose={() => setShow(false)}
            open={show}
            icon={<ModalBubblePayments />}
            BubbleText={'יתרתי נכון להיום'}
            className='amount-pay-modal'
          >
            <ModalInnerCard
              fund={balanceDataList?.fund}
              linkage={balanceDataList?.linkage}
              interest={balanceDataList?.interest}
              totalPayment={balanceDataList?.totalPayment}
            />
          </UiModal>
        </ModalWrapper>
      </ItemIconsWrapper>
    )
  )
}
export default UpcomingPayments
