import { styled } from 'styled-components'
import { Colors } from '../../../../../constants/styles.constants'
import { theme } from '../../../../../constants/theme.constants'

import MuiAccordionDetails from '@mui/material/AccordionDetails'

export const InnerContent = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: Colors.softBlue,
  padding: '1.5rem',
  minHeight: '67px',
  margin: '3px 0',
  direction: 'rtl',
  [theme.breakpoints.down('md')]: {
    padding: '8px'
  }
})
export const InnerPhoneWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center'
})

export const LinkUrl = styled('a')({
  textDecoration: 'none',
  fontSize: '22px',
  fontWeight: '300',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: '#4295e5',
  backgroundColor: '#fff',
  padding: '5px 10px',
  borderRadius: '6px',
  margin: '0 10px',
  transition: 'all 0.2s ease-in-out',
  '&:hover': {
    backgroundColor: Colors.softBrown,
    color: Colors.darkBrown
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
    margin: 0
  }
})

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: '1px solid rgba(0, 0, 0, .125)'
}))
