import { styled } from '@mui/material'
import { theme } from '../../../../constants/theme.constants'

export const FlexAlignItems = styled('div')({
  display: 'flex',
  alignItems: 'center'
})
export const KeyWrapper = styled('div')({
  background: '#EEECE7',
  padding: '7px 1rem ',
  display: 'flex',
  alignItems: 'center',
  borderRadius: 20,
  [theme.breakpoints.down('md')]: {
    margin: '1rem 0',
    padding: '5px 8px'
  }
})
export const DateKey = styled('p')({
  fontsize: '22px',
  fontWeight: 600,
  lineheight: '32px',
  color: '#00468A  ',
  padding: '0 8px'
})
