import { styled } from '@mui/material/styles'
import { Typography, Box } from '@mui/material'
import { Colors } from '../../../constants/styles.constants'

export const FaqTitle = styled(Typography)(() => ({
  fontSize: '44px',
  color: Colors.DarkerBlue,
  fontWeight: 600,
  lineHeight: '54px'
}))

export const FaqSubTitle = styled(Typography, { shouldForwardProp: (prop) => prop !== 'isMobile' })<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  fontSize: isMobile ? '20px' : '30px',
  color: '#6F655B',
  fontWeight: 600
}))

export const MoreQuestionsButton = styled(Box)(() => ({
  height: '55px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
  background: '#EEECE7',
  cursor: 'pointer'
}))
