import React from 'react'
import { SectionTitle, UserText } from '../../../../constants/styled.components.constants'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import { useGetTimeMessage } from '../../../../hooks/useGetTimeMessage/useGetTimeMessage'
import { IconKey } from '../../../../constants/icons.constants'
import { IContractData } from '../../../../constants/interfaces.constant'
import { DateKey, FlexAlignItems, KeyWrapper } from './style'

type Props = {
  contractInfoData: IContractData | null
}

function TopSection({ contractInfoData }: Props) {
  const timeStatement = useGetTimeMessage()
  const { user, loading } = useAppSelector((state) => state.user)

  if (loading) return

  return (
    <>
      <UserText>
        {timeStatement} {user?.info?.username}
      </UserText>
      <SectionTitle> התקדמות הפרוייקט</SectionTitle>
      <FlexAlignItems>
        <KeyWrapper>
          <IconKey />
          תאריך משוער לקבלת מפתח
          <DateKey>{contractInfoData?.purchaseOverview?.estimatedDate}</DateKey>
        </KeyWrapper>
      </FlexAlignItems>
    </>
  )
}

export default TopSection
