import React from 'react'
import _ from 'lodash'
import { BorderBox, BorderBoxWrapper, StepText } from './style'

type Props = {
  currentStep?: any
}
function ProgressBar({ currentStep }: Props) {
  return (
    <BorderBoxWrapper>
      <BorderBox style={{ width: `${currentStep}%` }} className={currentStep ? 'current' : ''}>
        {_.isNumber(currentStep) ? (
          <StepText>{currentStep}%</StepText>
        ) : (
          _.isBoolean(currentStep) == true && <StepText>100%</StepText>
        )}
      </BorderBox>
    </BorderBoxWrapper>
  )
}

export default ProgressBar
