import React, { useMemo, useState } from 'react'
import { loginConstants } from '../../constants/login.constant'
import { initiateLogin, setLoginError } from '../../state/features/user.feature'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hooks'
import ErrorLogin from './ErrorLogin'
import { Typography, Box } from '@mui/material'
import { useThemeMobile } from '../../hooks/themeMobile.hook'
import {
  InputWrapper,
  RadioGroupWrapper,
  LoginTitle,
  FirstLoginMainContainer,
  LoginSubtitle,
  TextFieldLogin,
  LoginRadio,
  InitialLoginButton,
  RadioLoginLabel
} from './Login.styles'

type LoginMethod = keyof typeof loginConstants

function FirstLoginStep() {
  const isMobile = useThemeMobile()

  const dispatch = useAppDispatch()
  const { loginError } = useAppSelector((state) => state.user)
  const [loginMethod, setLoginMethod] = useState<LoginMethod>('personalId')
  const [inputValue, setInputValue] = useState('')

  const handleSelectRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLoginError(null))
    setLoginMethod(event.target.value as LoginMethod)
    setInputValue('')
  }

  const getPlaceHolder = useMemo(() => {
    return loginConstants[loginMethod]?.placeholder || ''
  }, [loginMethod])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setInputValue(value)
    if (loginError) {
      dispatch(setLoginError(null))
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      handleInitialLogin()
    }
  }

  const handleInitialLogin = async () => {
    try {
      const response = await dispatch(
        initiateLogin({
          data: inputValue,
          type: loginMethod
        })
      )
    } catch (error) {
      console.error('InitialLogin failed:', error)
    }
  }

  return (
    <FirstLoginMainContainer isMobile={isMobile}>
      <Box sx={{ marginBottom: isMobile ? 'unset' : '16px' }}>
        <LoginTitle isMobile={isMobile}>כמה פרטים ונתחיל</LoginTitle>
        <LoginTitle isMobile={isMobile} className='bold custom-margin'>
          חווית הקניה שלך מתחילה עכשיו
        </LoginTitle>
        <LoginSubtitle isMobile={isMobile}>
          כדי שנוכל לשמור על פרטיותך ולהגן על הנתונים שלך נבקש ממך לקבל סיסמה חד פעמית בתהליך קל ומהיר
        </LoginSubtitle>
      </Box>

      <Box>
        <Box sx={{ marginTop: isMobile ? '34px' : 'unset' }}>
          <RadioGroupWrapper
            row
            aria-labelledby='login-group-label'
            name='login-group'
            defaultValue='personalId'
            onChange={handleSelectRadio}
          >
            <RadioLoginLabel value='personalId' control={<LoginRadio />} label='מספר ת.ז' />
            <RadioLoginLabel value='passport' control={<LoginRadio />} label='מספר דרכון' />
            <RadioLoginLabel value='companyId' control={<LoginRadio />} label='ח.פ (חברה פרטית)' />
          </RadioGroupWrapper>
        </Box>

        <InputWrapper isMobile={isMobile}>
          <Box sx={{ position: 'relative' }}>
            <TextFieldLogin
              type={loginMethod !== 'passport' ? 'number' : 'text'}
              placeholder={getPlaceHolder}
              name={loginMethod}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              sx={{
                borderColor: loginError ? '#FDCD97' : 'transparent',
                borderWidth: loginError ? '1px' : '1px',
                borderStyle: 'solid'
              }}
            />
            {loginError && <ErrorLogin loginError={loginError} />}
          </Box>
          <InitialLoginButton disabled={!inputValue.trim()} onClick={handleInitialLogin}>
            <Typography>הבא</Typography>
          </InitialLoginButton>
        </InputWrapper>
      </Box>
    </FirstLoginMainContainer>
  )
}

export default FirstLoginStep
