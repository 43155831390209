import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import API from '../../services/ajax.service'
import { API_ENDPOINTS } from '../../constants/api.constant'

export interface IFaq {
  id: number
  title: string
  content: string
  featureImage?: boolean
  featureImageMobile?: boolean
  subject: string
  show: string[],
  preferred: boolean
}

interface ILoading {
  getFaq: boolean
}

interface IError {
  getFaq: boolean
}

interface IFaqState {
  faqs: IFaq[] | []
  loading: ILoading
  error: IError
}

const initialState: IFaqState = {
  faqs: [],
  loading: {
    getFaq: false
  },
  error: {
    getFaq: false
  }
}

export const getFaq = createAsyncThunk('faq/getFaq', async (_, { rejectWithValue }) => {
  try {
    const response = await API.getInstance().get(`${API_ENDPOINTS.FAQ}?show=App`);
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
})

export const faqSlice = createSlice({
  name: 'faqs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFaq.pending, (state) => {
        state.loading.getFaq = true
      })
      .addCase(getFaq.fulfilled, (state, action) => {
        state.loading.getFaq = false
        state.faqs = action.payload
      })
      .addCase(getFaq.rejected, (state) => {
        state.loading.getFaq = false
        state.error.getFaq = true
      })
  }
})

export default faqSlice.reducer
