import { styled, Tab, Tabs } from '@mui/material'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'

export const TabWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& p': {
    padding: '3px 10px',
    color: Colors.blue
  },
  '& span': {
    backgroundColor: Colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    color: Colors.blue
  }
})

export const CustomTabs = styled(Tabs)({
  '.MuiTabs-flexContainer': {
    display: 'inline-block',
    background: Colors.lightBlueBG,
    borderRadius: '30px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '3px 16px'
    },
    '& .Mui-selected .tab-wrapper': {
      display: 'flex',
      alignItems: 'center',
      background: Colors.white,
      padding: '7px 0px 7px 3px',
      borderRadius: '19px',
      [theme.breakpoints.down('md')]: {
        padding: '3px 16px'
      },
      '& span': {
        backgroundColor: Colors.blue,
        color: Colors.white
      }
    }
  },
  '.MuiTabs-indicator': {
    display: 'none'
  }
})

export const CustomTab = styled(Tab)({
  [theme.breakpoints.down('md')]: {
    padding: '0',
    minHeight: '36px'
  }
})

export const InnerAccordion = styled('div', {
  shouldForwardProp: (prop) => prop !== 'withScroll'
})<{
  withScroll: boolean
}>(({ withScroll }) => ({
  padding: '0 8px 0 0',
  overflowY: 'auto',
  direction: 'ltr',
  height: withScroll ? '240px' : 'auto',
  [theme.breakpoints.down('md')]: {
    padding: '0'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    borderRadius: '10px',
    backgroundColor: '#f5f5f5'
  },
  '&::-webkit-scrollbar': {
    width: '9px',
    backgroundColor: '#f5f5f5',
    margin: '0 8px'
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    boxShadow: 'inset 0 0 6px rgba(0, 0, 0, 0.3)',
    backgroundColor: Colors.primaryBrowncolor
  }
}))
