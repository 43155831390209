import React from 'react'

interface LoginConfig {
  placeholder: string;
  errorMessage: string;
  dbField: string;
}

export const loginConstants: Record<string, LoginConfig> = {
  personalId: {
    placeholder: 'ת.ז',
    errorMessage: '',
    dbField: 'id_number'
  },
  passport: {
    placeholder: 'מספר דרכון',
    errorMessage: '',
    dbField: 'passport_number'
  },
  companyId: {
    placeholder: 'ח.פ',
    errorMessage: '',
    dbField: 'hp'
  }
}
