import React, { useEffect, useState } from 'react'
import _ from 'lodash'

import { Box, Stack, Typography } from '@mui/material'
import { useThemeMobile } from '../../../hooks/themeMobile.hook'
import { FaqSubTitle, FaqTitle, MoreQuestionsButton } from './styles'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux.hooks'
import { getFaq } from '../../../state/features/faq.feature'
import FaqSearch from './FaqSearch'
import FaqSubject from './FaqSubject'
import CustomAccordion from './Accordion'
import { Colors } from '../../../constants/styles.constants'

const FaqPage: React.FC = () => {
  const isMobile = useThemeMobile()
  const dispatch = useAppDispatch()
  const [chosenSubject, setChosenSubject] = useState('הכל')
  const [showMore, setShowMore] = useState(false)
  const [questionsToShow, setQuestionsToShow] = useState(5)
  const [searchTerm, setSearchTerm] = useState('')

  const { faqs } = useAppSelector((state) => state.faq)

  useEffect(() => {
    dispatch(getFaq())
  }, [dispatch])

  const subjects = _.uniq(_.map(faqs, 'subject'))

  const allQuestions = _.map(faqs, (faq) => ({
    title: faq.title,
    content: faq.content,
    subject: faq.subject
  }))

  const filteredQuestions = allQuestions
    .filter((q) => chosenSubject === 'הכל' || q.subject === chosenSubject)
    .filter(
      (q) =>
        q.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        q.subject.toLowerCase().includes(searchTerm.toLowerCase())
    )

  const questionsCounter = filteredQuestions.length

  const handleShowMore = () => {
    setShowMore(true)
    setQuestionsToShow(filteredQuestions.length)
  }

  return (
    <Stack direction={'column'}>
      <Box sx={{ padding: isMobile ? 'unset' : '0 25px' }}>
        {isMobile ? null : <FaqTitle variant='h2'>שאלות ותשובות</FaqTitle>}
        <FaqSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <FaqSubject subjects={subjects} setChosenSubject={setChosenSubject} />
        <Box sx={{ padding: isMobile ? '40px 0 0 0' : '55px 0 15px 0' }}>
          <FaqSubTitle isMobile={isMobile}>{`נמצאו ${questionsCounter} תוצאות`}</FaqSubTitle>
          <Stack spacing={2} paddingTop={'18px'}>
            {filteredQuestions
              .slice(0, showMore ? filteredQuestions.length : questionsToShow)
              .map((question, index) => (
                <CustomAccordion
                  key={index}
                  title={question.title}
                  details={question.content}
                  detailsColor={Colors.brown}
                  titleColor={Colors.DarkerBlue}
                />
              ))}

            {!showMore && filteredQuestions.length > 5 && (
              <MoreQuestionsButton onClick={handleShowMore}>
                <Typography fontSize={isMobile ? '20px' : '24px'}>טען עוד שאלות...</Typography>
              </MoreQuestionsButton>
            )}
          </Stack>
        </Box>
      </Box>
    </Stack>
  )
}

export default FaqPage
