import { styled } from 'styled-components'
import { theme } from '../../../constants/theme.constants'

export const WrapperList = styled('div')({
  height: '100%',
  padding: '0 0px'
})

export const HeaderContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    padding: '0 0 8px 0'
  }
})

export const MobileWrapper = styled('div')({
  [theme.breakpoints.down('md')]: {
    display: 'block',

    '&.MuiAccordionDetails-root': {
      padding: '0px 5px 16px'
    },
    '& h2.mobile-view': {
      display: 'none'
    },
    '& .MuiAccordionDetails-root': {
      padding: '10px'
    }
  }
})

export const AccordionSummaryInner = styled('div')({
  width: '35%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    '& .mobile-view': {
      display: 'none'
    }
  }
})
