import { Accordion, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

export const CustomAccordionContainer = styled(Accordion)({
  borderRadius: '8px',
  '&:before': {
    backgroundColor: 'transparent'
  },
  '&.Mui-expanded': {
    margin: '16px 0 0 0 !important'
  }
})

export const AccordionTitle = styled(Typography, {
  shouldForwardProp: (props: string) => !['isMobile', 'titleColor'].includes(props)
})<{
  isMobile?: boolean
  titleColor: string
}>(({ isMobile, titleColor }) => ({
  color: titleColor,
  fontSize: isMobile ? '20px' : '24px',
  fontWeight: 400,
  lineHeight: isMobile ? '28px' : '30px',
  letterSpacing: '-0.24px'
}))

export const AccordionSubTitle = styled(Typography, {
  shouldForwardProp: (props: string) => !['isMobile', 'detailsColor'].includes(props)
})<{
  isMobile?: boolean
  detailsColor: string
}>(({ isMobile, detailsColor }) => ({
  color: detailsColor,
  fontSize: isMobile ? '20px' : '22px',
  fontWeight: 300,
  lineHeight: isMobile ? '28px' : '30px'
}))
