import React, { useEffect, useRef, useState } from 'react'
import ArrowIcon from '../../../../assets/icons/arrow'
import { IFaqSubjectProps } from './types'
import { IconWrapper, SubjectContainer, SubjectText, SubjectWrapper } from './styles'
import { Colors } from '../../../../constants/styles.constants'
import { Box } from '@mui/material'
import { useThemeMobile } from '../../../../hooks/themeMobile.hook'
import _ from 'lodash'

const DEFAULT_WIDTH = 180

const FaqSubject: React.FC<IFaqSubjectProps> = ({ subjects, setChosenSubject }) => {
  const isMobile = useThemeMobile()
  const [showMore, setShowMore] = useState(false)
  const [visibleItemsCount, setVisibleItemsCount] = useState(subjects.length)
  const [showArrow, setShowArrow] = useState(false)
  const [activeSubject, setActiveSubject] = useState('הכל')

  const containerRef = useRef<HTMLDivElement>(null)
  const subjectRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const updateVisibleItems = () => {
      const containerWidth = (containerRef.current?.offsetWidth || 0) - DEFAULT_WIDTH
      const margin = 10
      let totalWidth = 0
      let itemsToShow = subjects.length

      _.forEach(subjectRefs.current, (ref, i) => {
        if (ref) {
          const itemWidth = ref.offsetWidth || 0
          totalWidth += itemWidth + (i > 0 ? margin : 0)

          if (totalWidth > containerWidth) {
            itemsToShow = i
            return false
          }
        }
      })

      setVisibleItemsCount(itemsToShow)
      setShowArrow(itemsToShow < subjects.length)
    }

    updateVisibleItems()
    window.addEventListener('resize', updateVisibleItems)

    return () => {
      window.removeEventListener('resize', updateVisibleItems)
    }
  }, [subjects])

  const toggleShowMore = () => {
    setShowMore((prev) => !prev)
  }

  const handleSubjectClick = (subject: string) => {
    setActiveSubject(subject)
    setChosenSubject(subject)
  }

  return (
    <SubjectContainer ref={containerRef}>
      {showArrow && (
        <IconWrapper
          onClick={toggleShowMore}
          style={{ cursor: 'pointer', transform: showMore ? 'rotate(180deg)' : 'rotate(0deg)' }}
        >
          <ArrowIcon fill={Colors.DarkerBlue} />
        </IconWrapper>
      )}

      <Box sx={{ display: 'block' }}>
        <SubjectWrapper
          isMobile={isMobile}
          onClick={() => handleSubjectClick('הכל')}
          ref={(el: HTMLDivElement | null) => (subjectRefs.current[0] = el)}
          isActive={activeSubject === 'הכל'}
        >
          <SubjectText isMobile={isMobile} key={'all'}>
            הכל
          </SubjectText>
        </SubjectWrapper>

        {subjects.slice(0, showMore ? subjects.length : visibleItemsCount).map((subject, index) => (
          <SubjectWrapper
            isMobile={isMobile}
            key={subject}
            onClick={() => handleSubjectClick(subject)}
            ref={(el: HTMLDivElement | null) => (subjectRefs.current[index + 1] = el)}
            isActive={activeSubject === subject}
          >
            <SubjectText isMobile={isMobile}>{subject}</SubjectText>
          </SubjectWrapper>
        ))}
      </Box>
    </SubjectContainer>
  )
}

export default FaqSubject
