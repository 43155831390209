import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks/redux.hooks'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import CardInfo from '../../UI/CardInfo/cardInfo'
import CircularProgress from './circularProgress'
import PaymentList from './PaymentList'
import { Button, Stack } from '@mui/material'
import Loader from '../../UI/Loader/Loader'
import { PaymentsText, SectionTitle } from '../../../constants/styled.components.constants'
import MobileAccordion from '../../UI/MobileAccordion/MobileAccordion'
import NextPayment from './NextPayments'
import {
  MainWrapper,
  ListPayment,
  ListWrapper,
  MobileWrapper,
  NextPaymentModal,
  NextpaymentWrapper,
  SpaceBetweenWrapper,
  SpanArrow
} from './style'

const UNFOUND_TEXT = 'לא נמצא תוכן זמין לצפייה '
const BUTTON_TEXT = 'פירוט תשלומים'
const LABEL_TEXT = 'תשלומים'

const Payments = () => {
  const [showElement, setShowElement] = useState(false)
  const { width, breakpoint } = UseResizeWindow()
  const { loading, paymentsInfoData } = useAppSelector((state) => state.payments)

  const percentage = parseInt(paymentsInfoData?.percentage || '0', 10)

  if (loading) {
    return <Loader />
  }

  return (
    <>
      {width > breakpoint ? (
        <MainWrapper id='payments'>
          <SectionTitle>{LABEL_TEXT}</SectionTitle>
          {!paymentsInfoData ? (
            <p> {UNFOUND_TEXT}</p>
          ) : (
            <ListWrapper>
              {showElement && (
                <>
                  <NextPaymentModal>
                    <SpaceBetweenWrapper>
                      <PaymentsText>{BUTTON_TEXT}</PaymentsText>
                      <SpanArrow onClick={() => setShowElement(!showElement)}>&#9747;</SpanArrow>
                    </SpaceBetweenWrapper>
                    <NextPayment />
                  </NextPaymentModal>
                </>
              )}
              <CardInfo className={'payments'}>
                <ListWrapper>
                  <ListPayment>
                    <CircularProgress totalPrice={paymentsInfoData?.totalPrice} percentage={percentage} />
                    <PaymentList />

                    <NextpaymentWrapper style={{ zIndex: 0 }}>
                      <Button variant='contained' onClick={() => setShowElement(!showElement)}>
                        {BUTTON_TEXT}
                      </Button>
                    </NextpaymentWrapper>
                  </ListPayment>
                </ListWrapper>
              </CardInfo>
            </ListWrapper>
          )}
        </MainWrapper>
      ) : (
        <MobileWrapper>
          {paymentsInfoData?.totalPrice && (
            <MobileAccordion
              title={LABEL_TEXT}
              extraData={paymentsInfoData?.totalPrice}
              className={'payment-moblie-view'}
              component={<CircularProgress totalPrice={paymentsInfoData?.totalPrice} percentage={percentage} />}
            >
              {!paymentsInfoData ? (
                <p>{UNFOUND_TEXT}</p>
              ) : (
                <CardInfo className={'payments'}>
                  <ListWrapper>
                    {showElement ? (
                      <>
                        <Stack direction='row' justifyContent='space-between'>
                          <PaymentsText>{BUTTON_TEXT}</PaymentsText>
                          <SpanArrow onClick={() => setShowElement(!showElement)}>&#9747;</SpanArrow>
                        </Stack>
                        <NextPayment />
                      </>
                    ) : (
                      <ListPayment>
                        <CircularProgress totalPrice={paymentsInfoData.totalPrice} percentage={percentage} />
                        <PaymentList />
                        <NextpaymentWrapper>
                          <Button variant='contained' onClick={() => setShowElement(!showElement)}>
                            {BUTTON_TEXT}
                          </Button>
                        </NextpaymentWrapper>
                      </ListPayment>
                    )}
                  </ListWrapper>
                </CardInfo>
              )}
            </MobileAccordion>
          )}
        </MobileWrapper>
      )}
    </>
  )
}

export default Payments
