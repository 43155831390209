import React from 'react'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import _ from 'lodash'
import Loader from '../../../UI/Loader/Loader'
import UpcomingPayments from './upcomingPayments'
import AmountPaid from './amountPaid'
import AmountLeftToPay from './amountLeftToPay'

const PaymentList = () => {
  const loading = useAppSelector((state) => state.payments.loading)

  if (loading) return <Loader />

  return (
    <>
      <AmountPaid />
      <AmountLeftToPay />
      <UpcomingPayments />
    </>
  )
}

export default PaymentList
