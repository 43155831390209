export const CONTRACT = 'חוזה '
export const SHORT_CONTRACT = 'תקציר החוזה'
export const APARTMENT = 'הדירה'
export const ADDRESS = 'כתובת'
export const BUILDING_NAME = 'שם מבנה'
export const PROPERTY = 'נכס'
export const STORAGE = 'מחסן'
export const PROPERTY_NAME = 'שם הנכס '
export const FLOOR = 'קומה'
export const PARKING = 'חניה'
export const PARKING_NUM = 'חניה מס.'
export const NO_INFO = 'אין מידע נוסף'
export const SIGN_DATE = 'תאריך חתימה'
export const APROX_DATE = 'תאריך מסירה משוער'
export const APARTMENT_COST = 'מחיר דירה כולל מע״מ'
export const FROM_LOT = 'ממגרש'
export const DELIVERY_CHANGE_DATE = 'תאריך מסירה בפועל'
