import React from 'react'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import InnerWrapperCard from '../../../UI/InnerWrapperCard/InnerWrapperCard'
import CardInfo from '../../../UI/CardInfo/cardInfo'
import ContractSummary from './contractSummary'
import { CardInnerFlex, CardInfoInnerWrapper, SectionTitle } from '../../../../constants/styled.components.constants'
import Loader from '../../../UI/Loader/Loader'
import * as CONSTS from './consts'

const Contract = () => {
  const { contractInfoData: contractDataSlice, loading } = useAppSelector((state) => state.contract)
  if (loading) return <Loader />
  return (
    <>
      <SectionTitle className='mobile-view'>{CONSTS.CONTRACT}</SectionTitle>

      {contractDataSlice ? (
        <>
          <CardInfo title={CONSTS.SHORT_CONTRACT}>
            <ContractSummary />
          </CardInfo>
          <CardInfo title={CONSTS.APARTMENT}>
            {contractDataSlice?.apartment && (
              <React.Fragment>
                <InnerWrapperCard title={CONSTS.ADDRESS} subTitle={contractDataSlice?.apartment?.address} />
                <InnerWrapperCard title={CONSTS.BUILDING_NAME} subTitle={contractDataSlice?.apartment?.PropertyName} />
                <InnerWrapperCard
                  title={CONSTS.PROPERTY}
                  subTitle={CONSTS.APARTMENT + contractDataSlice?.apartment?.name}
                />
              </React.Fragment>
            )}
          </CardInfo>

          <CardInfo title={CONSTS.STORAGE}>
            {contractDataSlice?.storeRoom && (
              <React.Fragment>
                <InnerWrapperCard title={CONSTS.PROPERTY_NAME} subTitle={contractDataSlice?.storeRoom?.name} />
                <InnerWrapperCard title={CONSTS.FLOOR} subTitle={contractDataSlice?.storeRoom?.floor} />
              </React.Fragment>
            )}
          </CardInfo>

          <CardInfo title={CONSTS.PARKING}>
            <CardInfoInnerWrapper style={{ width: '100%' }}>
              {contractDataSlice?.parking &&
                contractDataSlice?.parking?.map((data, index) => (
                  <CardInnerFlex key={index}>
                    <InnerWrapperCard title={CONSTS.FLOOR} subTitle={data.floor} />
                    <InnerWrapperCard subTitle={CONSTS.PARKING_NUM + data.name} />
                  </CardInnerFlex>
                ))}
            </CardInfoInnerWrapper>
          </CardInfo>
        </>
      ) : (
        <p>{CONSTS.NO_INFO}</p>
      )}
    </>
  )
}

export default Contract
