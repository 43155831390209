import React from 'react'
import { IconButton, Stack, Tooltip } from '@mui/material'
import { Text } from '../../../../constants/styled.components.constants'
import { GreenPaymentConfirmation, Info } from '../../../../constants/icons.constants'
import { CardText } from './style'
import { IAmountPaidData } from '../../../../constants/interfaces.constant'
import { useAppSelector } from '../../../../hooks/redux.hooks'
import { getTooltipCardText, getTooltipKeyString, PaymentKeys } from './consts'

interface PaymentCardLabelProps {
  date: string
  receptionNumber: string
  selected: boolean
}

interface PaymentTooltipProps {
  paymentItem: IAmountPaidData
  openTooltipKey: string | null
  setOpenTooltipKey: (value: React.SetStateAction<string | null>) => void
  paymentKey: PaymentKeys
}
interface PaymentInfoLineProps {
  children?: React.ReactNode
  paymentKey: PaymentKeys
  paymentItem: IAmountPaidData
}

export const PaymentCardLabel = ({ date, receptionNumber, selected }: PaymentCardLabelProps) => {
  return (
    <Stack
      direction='row'
      justifyContent={selected ? 'center' : 'flex-start'}
      paddingTop={selected ? '.5rem' : '0'}
      paddingRight='0'
    >
      {date !== '01.01.1900' && receptionNumber !== '-' && <GreenPaymentConfirmation />}
      <Text>{date === '01.01.1900' ? 'יתרת חוב' : date}</Text>
    </Stack>
  )
}

export const PaymentInfoLine = ({ children, paymentKey, paymentItem }: PaymentInfoLineProps) => {
  const getCardTextString = () => {
    switch (paymentKey) {
      case PaymentKeys.RECEIPT_TOTAL_AMOUNT:
        return paymentItem.fund
      case PaymentKeys.PROPERTY_ACQUISITION_INTEREST:
        return paymentItem.interest
      case PaymentKeys.PROPERTY_ACQUISITION_LINKAGE:
        return paymentItem.linkage
        case PaymentKeys.TOTAL_PAYMENT:
          return paymentItem.totalPayment
      default:
        console.error('Invalid payment key:', paymentKey)
        return
    }
  }
  const CardTextString = getCardTextString()
  return (
    <Stack direction='row' justifyContent='space-between'>
      <Stack direction='row' alignItems='center'>
        <CardText>{getTooltipCardText(paymentKey)}</CardText>
        {children}
      </Stack>
      <CardText>{`₪${(CardTextString && parseFloat(CardTextString).toLocaleString()) || '-'}`}</CardText>
    </Stack>
  )
}

export const PaymentInfoTooltipLine = ({
  paymentItem,
  openTooltipKey,
  setOpenTooltipKey,
  paymentKey
}: PaymentTooltipProps) => {
  const tooltips = useAppSelector((state) => state.global?.tooltipsList)
  const currentTooltipKey = getTooltipKeyString(paymentKey)
  const handleTooltipToggle = (tooltipKey: string) => {
    setOpenTooltipKey((prevKey) => (prevKey === tooltipKey ? null : tooltipKey))
  }
  const getTooltipTextValueByKey = (tooltipKey: string) => {
    return tooltips?.find((tooltip) => tooltip.key === tooltipKey)?.value || ''
  }
  return (
    <PaymentInfoLine paymentKey={paymentKey} paymentItem={paymentItem}>
      <Tooltip
        dir='rtl'
        disableTouchListener
        open={openTooltipKey === currentTooltipKey}
        onClose={() => setOpenTooltipKey(null)}
        title={getTooltipTextValueByKey(currentTooltipKey)}
        onMouseEnter={() => setOpenTooltipKey(currentTooltipKey)}
        onMouseLeave={() => setOpenTooltipKey(null)}
        onClick={() => handleTooltipToggle(currentTooltipKey)}
        onMouseDown={(e) => {
          e.preventDefault()
          handleTooltipToggle(currentTooltipKey)
        }}
        componentsProps={{
          tooltip: {
            sx: {
              marginTop: '0px !important',
              maxWidth: '200px'
            }
          }
        }}
      >
        <IconButton
          aria-label='More info'
          style={{ width: '24px', height: '24px', padding: '0px', marginRight: '5px' }}
        >
          <Info />
        </IconButton>
      </Tooltip>
    </PaymentInfoLine>
  )
}
