import { styled } from '@mui/material/styles'
import { TextField } from '@mui/material'

export const SearchField = styled(TextField)(() => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none'
    },
    '&:before': {
      borderBottom: '1px solid #F5F4F2'
    },
    '&.Mui-focused:before': {
      borderBottom: '1px solid #F5F4F2'
    },
    '&:hover:before': {
      borderBottom: '1px solid #F5F4F2'
    },
    borderBottom: '1px solid #F5F4F2'
  },
  '& input': {
    textAlign: 'right',
    color: '#817870',
    fontSize: '24px',
    fontWeight: 300
  }
}))
