import React, { useState, useMemo } from 'react'
import { useAppSelector } from '../../../hooks/redux.hooks'
import Slider from 'react-slick'
import FifthStep from '../../../assets/images/ProgressImages/FifthStep.png'
import { Confirmation } from '../../../constants/icons.constants'
import _ from 'lodash'
import TopSection from './topSection'
import ProgressBar from './progressBar'
import { UseResizeWindow } from '../../../hooks/UseResizeWindow'
import ProgressBorderMobile from './Mobile/ProgressBorderMobile'
import Loader from '../../UI/Loader/Loader'
import Status1 from '../../../assets/images/ProgressImages/status-1.svg'
import Status2 from '../../../assets/images/ProgressImages/status-2.svg'
import Status3 from '../../../assets/images/ProgressImages/status-3.svg'
import Status4 from '../../../assets/images/ProgressImages/status-4.svg'
import {
  Text,
  Dot,
  Image,
  ImageBox,
  InnerContent,
  InnerDot,
  InnerWrapper,
  MainWrapper,
  MainWrapperInner,
  MobileWrapper,
  StepProgress,
  TempWrapper,
  CurrentStepText,
  MobileBackGroundWrapper,
  ProgressText
} from './style'

const ProgressStages = () => {
  const { width, breakpoint } = UseResizeWindow()
  const { contractInfoData } = useAppSelector((state) => state.contract)
  const { progressStatusData, loading } = useAppSelector((state) => state?.finishingMaterials)
  const STEPS = useMemo(
    () => [
      ...(progressStatusData?.status?.basement
        ? [
            {
              title: 'מרתף',
              imageUrl: FifthStep,
              currentStep: progressStatusData?.status?.basement
            }
          ]
        : []),
      {
        title: 'תחילת ביצוע',
        imageUrl: Status1,
        currentStep: progressStatusData?.status?.permit
      },
      {
        title: 'בניית שלד',
        imageUrl: Status2,
        currentStep: progressStatusData?.status?.structure
      },
      {
        title: 'עבודות גמר',
        imageUrl: Status3,
        currentStep: progressStatusData?.status?.finishing
      },
      {
        title: 'טופס 4/מסירת דירה',
        imageUrl: Status4,
        currentStep: progressStatusData?.status?.form4
      }
    ],
    [progressStatusData?.status]
  )

  const [currentSlide, setCurrentSlide] = useState(0)

  const settings = {
    initialSlide: currentSlide,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: setCurrentSlide
  }

  if (loading) return <Loader />

  return (
    <>
      {width > breakpoint ? (
        <MainWrapper id='progressStages'>
          <TopSection contractInfoData={contractInfoData} />
          <MainWrapperInner>
            <InnerWrapper>
              {STEPS.map(({ currentStep, ...rest }: any) => ({
                currentStep: currentStep === true ? 100 : currentStep === false ? null : currentStep,
                ...rest
              })).map(({ currentStep, title, imageUrl }, index) => (
                <StepProgress key={index} className={currentStep > 0 && currentStep < 100 ? 'current-step' : ''}>
                  <ImageBox>
                    <Image
                      className={currentStep === 100 ? 'small' : currentStep > 0 && currentStep < 100 ? 'current' : ''}
                      src={imageUrl}
                      alt={title}
                    />
                  </ImageBox>

                  <ProgressBar currentStep={currentStep} />

                  <InnerContent className='landscape'>
                    <Dot>
                      {currentStep > 0 && currentStep < 100 && <InnerDot />}
                      {currentStep === 100 && <Confirmation />}
                    </Dot>
                    <TempWrapper>
                      <Text>{title} </Text>
                      <CurrentStepText>
                        שלב {STEPS.length} - {currentStep}
                        {'% '}
                      </CurrentStepText>
                    </TempWrapper>
                  </InnerContent>
                </StepProgress>
              ))}
            </InnerWrapper>
          </MainWrapperInner>
        </MainWrapper>
      ) : (
        <MobileWrapper className='progressStagesmb'>
          <TopSection contractInfoData={contractInfoData} />

          <MainWrapperInner>
            <Slider {...settings}>
              {STEPS.map(({ currentStep, ...rest }: any) => ({
                currentStep: currentStep === true ? 100 : currentStep === false ? null : currentStep,
                ...rest
              })).map(({ currentStep, title, imageUrl }, index) => (
                <StepProgress key={index} className={currentStep > 0 && currentStep < 100 ? 'current-step' : ''}>
                  <ImageBox>
                    <Image
                      className={currentStep === 100 ? 'small' : currentStep > 0 && currentStep < 100 ? 'current' : ''}
                      src={imageUrl}
                      alt={title}
                    />
                  </ImageBox>
                  <MobileBackGroundWrapper>
                    <Text>{title}</Text>
                    <ProgressText>
                      {STEPS.length} שלב {index + 1} מתוך
                    </ProgressText>
                    <ProgressBorderMobile currentStep={currentStep} />
                  </MobileBackGroundWrapper>
                </StepProgress>
              ))}
            </Slider>
          </MainWrapperInner>
        </MobileWrapper>
      )}
    </>
  )
}

export default ProgressStages
