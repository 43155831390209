import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Colors } from '../../../../constants/styles.constants'
import { theme } from '../../../../constants/theme.constants'
import { ItemText } from '../../ChatSideBar/ChatSideBar'
import styled from 'styled-components'
import Wallpaper from '../../../../assets/icons/wallpaper.svg'
import { Box } from '@mui/material'
import formatText from './formatTextUtils'
import { useAppDispatch } from '../../../../hooks/redux.hooks'
import { getActivityFile } from '../../../../state/features/chat.feature'
import { isString } from 'lodash'
import _ from 'lodash'
type Props = {
  title?: string | any
  text?: string
  timestamp?: Date | any
  owner?: string
  subject?: string
  Notes?: string
  CntctDate?: string
  item?: string | {}
  fileMessage?: any
  fileResponse?: any
  className?: string
  url?: string
  filename?: string | any
  fileId?: string
}
function checkFileURL(url, fileName) {
  return new Promise((resolve) => {
    // Determine file type based on the extension
    const fileType = fileName.split('.').pop().toLowerCase();

    // Check for image extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
    const videoExtensions = ['mp4', 'webm', 'ogg', 'avi', 'mov', 'mkv'];

    if (imageExtensions.includes(fileType)) {
      const img = new Image();
      img.onload = () => resolve({ type: 'image', valid: true });
      img.onerror = () => resolve({ type: 'image', valid: false });
      img.src = url;
    } else if (videoExtensions.includes(fileType)) {
      const video = document.createElement('video');
      video.onloadeddata = () => resolve({ type: 'video', valid: true });
      video.onerror = () => resolve({ type: 'video', valid: false });
      video.src = url;
    } else {
      resolve({ type: 'unknown', valid: false });
    }
  });
}

function ChatCard({ fileId, filename, url, item, Notes, subject, title, text, timestamp, owner, className }: Props) {
  const formattedText = formatText(url)
  const dispatch = useAppDispatch()
  const filenameString = filename
  const maxLength = 15
  const truncatedFilename = filename?.length > maxLength ? `${filenameString.substring(0, maxLength)}...` : filename
  const [imageUrl, setImageUrl] = useState(Wallpaper)
  const [fileType, setFileType] = useState('img')

  const handleFileDownload = () => {
    if (fileId) {
      dispatch(getActivityFile({ id: fileId }))
    }
  }

  useEffect(() => {
    if (_.isString(url) && _.isString(text)) {
      checkFileURL(url, text).then((result) => {
        if (result.type === 'image' && result.valid) {
          setImageUrl(url)
        } else if (result.type === 'video' && result.valid) {
          setFileType('video')
          setImageUrl(url)
        } else {
          console.log('The file is either invalid or unsupported.');
        }
      });
    }
  }, [url, text])

  return (
    <FormattedTextWrapper className={formattedText ? 'formattedTextWrapper' : ''}>
      <ChatSingle
        className={classNames(
          {
            'user-chat': owner === 'user',
            'chat show-box': owner !== 'user'
          },
          className
        )}
      >
        {isNaN(title) && title && <ItemText>{title}</ItemText>}

        <ChatSingleInnerWrapper className='inner-file'>
          {formattedText && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >
              <InnerWrapper>
                {fileType === 'video' ?
                  <video style={{ width: 60, height: 60 }} controls src={imageUrl} /> :
                  <img style={{ width: 60, height: 60 }} src={imageUrl} alt='Wallpaper icon' />
                }
              </InnerWrapper>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>{truncatedFilename}</span>
                <span className='formattedText' onClick={handleFileDownload}>
                  להורדת הקובץ
                </span>
              </Box>
            </Box>
          )}

          <ItemWrapper>{!formattedText && text}</ItemWrapper>
          {/* <p>{subject}</p> */}
          {/*<ItemText className='timemsg'>
            <>
              {owner ? (
                <>{new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</>
              ) : (
                <>{new Date(timestamp).toLocaleDateString()}</>
              )}
            </>
              </ItemText>*/}
        </ChatSingleInnerWrapper>
      </ChatSingle>
    </FormattedTextWrapper>
  )
}

export default ChatCard

const FormattedTextWrapper = styled('div')({
  '&.formattedTextWrapper': {
    '& .user-chat ': {
      width: '30%',
      border: `1px solid ${Colors.lightBlueTwo}`,
      [theme.breakpoints.down('md')]: {
        width: '70%'
      }
    }
  }
})

const ChatSingle = styled('div')({
  // minHeight: '62px',
  width: 'calc( 50% - 80px)',
  margin: '15px 40px',
  padding: '12px 16px 4px',
  borderRadius: '24px',
  background: Colors.lightBlueTwo,
  '&.file-message-wrapper': {
    background: Colors.white,
    border: `1px solid ${Colors.lightBlueTwo}`,
    width: '30%',
    [theme.breakpoints.down('md')]: {
      width: '90%'
    },
    '& .inner-file': {
      textAlign: 'center',
      '& div': {
        width: '100%',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          textAlign: 'center'
        }
      },
      '& .timemsg': {
        width: '100%',
        padding: '2px 8px',
        borderRadius: 6
      }
    },
    '& p': {
      paddingBottom: 6,
      marginBlockStart: 0
    }
  },
  '&.user-chat': {
    background: Colors.white,
    margin: '10px auto 1rem 5%',
  },
  '& .formattedText': {
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
      color: Colors.activeLink
    }
  },
  '&.classOption': {
    display: 'none'
  },

  [theme.breakpoints.down('md')]: {
    width: '80%',
    margin: '1rem 2rem'
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
    margin: '1rem'
  }
})
const ChatSingleInnerWrapper = styled('div')({})

const ItemWrapper = styled('div')({
  fontSize: '16px',
  fontWeight: 'normal',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  color: Colors.darkBrown,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '&.timemsg': {
    direction: 'ltr',
    padding: '5px 0'
  },
  '& svg': {
    borderRadius: '50%'
  },
  [theme.breakpoints.down('md')]: {
    textAlign: 'right'
  }
})

const InnerWrapper = styled('div')({
  width: '100%',
  textAlign: 'center'
})
