import { Typography, TypographyProps, styled } from '@mui/material'
import { theme } from '../../../../../../constants/theme.constants'

export const MainTitle = styled(Typography)({
  fontSize: '22px',
  fontWeight: 600,
  color: '#4295E5',
  padding: '11px 0 15px 0',
  [theme.breakpoints.down('md')]: { padding: '9px 0 16px 0s' }
})
export const NormalText = styled(Typography)<TypographyProps>((props) => ({
  fontSize: '22px',
  fontWeight: 400,
  color: '#473B33',
  lineHeight: '28px',
  ...props
}))

export const SmallText = styled(Typography)<TypographyProps>((props) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: '#6F655B',
  lineHeight: '28px',
  ...props
}))
