import React, { useState } from 'react'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import { styled } from '@mui/material/styles'
import { AccordionWrapper, DynamicText, ItemText } from '../../../../../constants/styled.components.constants'
import TenantsChangesModal from '../TenantsModal'
import { IMaterialsChange } from '../../../../../constants/interfaces.constant'
import { AccordionWrapperInner, DataWrapper, DataWrapperInner } from './styles'

const TenantsChangesItem = ({ changesPreview }: { changesPreview: IMaterialsChange['changes'] }) => {
  const { addonsDate, changeEstimateID, totalWorkingDays, total } = changesPreview
  const [openProfileModal, setOpenProfileModal] = useState(false)

  return (
    <>
      <AccordionWrapper onClick={() => setOpenProfileModal(true)}>
        <Accordion>
          <AccordionSummary aria-controls='panel1d-content' id='panel1d-header'>
            <AccordionWrapperInner>
              <DynamicText> הצעה מס’ {changeEstimateID} </DynamicText>
              <DataWrapper>
                <DataWrapperInner>
                  <ItemText>תאריך הצעת מחיר</ItemText>
                  <DynamicText>{addonsDate}</DynamicText>
                </DataWrapperInner>
                <DataWrapperInner>
                  <ItemText>עלות השינוי</ItemText>
                  <DynamicText> ₪{parseFloat(total).toLocaleString()}</DynamicText>
                </DataWrapperInner>
                <DataWrapperInner>
                  <ItemText>זמן לביצוע</ItemText>
                  <DynamicText> {totalWorkingDays} ימי עבודה </DynamicText>
                </DataWrapperInner>
              </DataWrapper>
            </AccordionWrapperInner>
          </AccordionSummary>
        </Accordion>
      </AccordionWrapper>
      <TenantsChangesModal
        open={openProfileModal}
        setOpenModal={setOpenProfileModal}
        changesPreview={changesPreview}
      ></TenantsChangesModal>
    </>
  )
}

export default TenantsChangesItem

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))
