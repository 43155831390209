import React from 'react'
import { IFaqSearchProps } from './types'
import { InputAdornment } from '@mui/material'
import SearchIcon from '../../../../assets/icons/search'
import { SearchField } from './styles'

const FaqSearch: React.FC<IFaqSearchProps> = ({ searchTerm, setSearchTerm }) => {
  return (
    <SearchField
      fullWidth
      placeholder='חיפוש חופשי לפי מילות מפתח'
      variant='outlined'
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  )
}

export default FaqSearch
