import { styled, Box, RadioGroup, Button, Typography, TextField, Radio, FormControlLabel } from '@mui/material'
import { Colors } from '../../constants/styles.constants'
import { theme } from '../../constants/theme.constants'

export const FirstLoginMainContainer = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: isMobile ? 'none' : '365px',
  padding: isMobile ? '0 18px 63px 18px' : '0 0 103px 0'
}))

export const SecondLoginMainContainer = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  width: isMobile ? '100%' : '365px',
  padding: isMobile ? '0 27px 15px 27px' : '0 0 65px 0'
}))

export const ThirdLoginMainContainer = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  width: isMobile ? '100%' : 'unset',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '& .top-section': {
    width: isMobile ? '100%' : '62.43%',
    '& .subtitle': {
      marginTop: '14px',
      fontSize: '18px',
      lineHeight: '20px'
    }
  },
  '& .otp-container': {
    marginTop: isMobile ? '25px' : '56px',
    padding: isMobile ? '0 9px' : 'unset',
    width: '100%',
    display: 'flex'
  },
  '& .submit-container': {
    width: '100%',
    marginTop: isMobile ? '52px' : '64px',
    display: 'flex',
    flexDirection: 'column',
    gap: isMobile ? '104px' : '32px',
    alignItems: 'center',
    '& .checkbox-container': {
      display: 'flex',
      gap: '8px',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      '& .MuiCheckbox-root': {
        color: Colors.checkboxOrange,
        width: '20px',
        height: '20px',
        '&.Mui-checked': {
          color: Colors.checkboxOrange
        }
      },
      '& .MuiTypography-root': {
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '20px',
        color: Colors.darkBrown
      }
    }
  },
  '& .bottom-section': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: isMobile ? '30px' : '113px',
    padding: isMobile ? '0 16px 19px 16px' : '0 56px',
    '& .MuiTypography-root': {
      fontSize: '18px',
      fontWeight: '400',
      lineHeight: '20px',
      color: Colors.darkBrown,
      textDecoration: 'underline'
    }
  }
}))

export const LoginOptionWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  width: '100%',
  padding: isMobile ? 'unset' : '0 27px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '18px',
  marginTop: isMobile ? '17px' : '60px'
}))

export const StyledButton = styled(Button, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  maxWidth: '335px',
  marginTop: isMobile ? '81px' : '60px',
  fontSize: isMobile ? '16px' : '20px',
  color: Colors.darkBrown,
  textDecoration: 'underline',
  '&:hover': {
    background: 'transparent'
  }
}))

export const InputWrapper = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  display: 'flex',
  marginTop: isMobile ? '12px' : 'unset',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: isMobile ? '54px' : '79px',
  position: 'relative'
}))

export const HeroWrapper = styled('div')({
  display: 'flex',
  marginTop: '89px',
  margin: '89px auto 0',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    margin: '76px 14px 18px 14px',
    minWidth: '339px'
  }
})

export const HeroImage = styled('img')({
  height: '664px',
  width: 'auto',
  [theme.breakpoints.down('md')]: {
    display: 'none'
  }
})

export const FormEnterWrapper = styled('div')<{ isMobile: boolean }>(({ isMobile }) => ({
  height: isMobile ? '528px' : '664px',
  display: 'flex',
  justifyContent: 'center',
  width: isMobile ? '100%' : '34.86%',
  padding: isMobile ? '53px 0 0 0' : '90px 78px 0 78px',
  textAlign: 'center',
  background: Colors.cardBackground
}))

export const RadioGroupWrapper = styled(RadioGroup)({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '31px'
})

export const LoginTitle = styled('h3')<{ isMobile: boolean }>(({ isMobile }) => ({
  fontSize: isMobile ? '30px' : '32px',
  fontWeight: '400',
  lineHeight: '2.25rem',
  color: Colors.darkBrown,
  marginBlockStart: 0,
  marginBlockEnd: 0,
  '&.custom-margin': {
    marginTop: isMobile ? '26px' : '16px'
  },
  '&.bold': {
    fontWeight: '600'
  }
}))

export const LoginSubTitle = styled(Typography)<{ isMobile: boolean }>(({ isMobile }) => ({
  fontSize: isMobile ? '24px' : '32px',
  fontWeight: '400',
  color: Colors.darkBrown,
  '&.custom-margin': {
    marginTop: isMobile ? '26px' : 'none'
  }
}))

export const LoginSubtitle = styled('h3')<{ isMobile: boolean }>(({ isMobile }) => ({
  fontSize: isMobile ? '18px' : '20px',
  fontWeight: '400',
  lineHeight: isMobile ? '24px' : 'unset',
  color: Colors.veryLightBrown
}))

export const SecondStepChoiceBox = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  width: '100%',
  padding: isMobile ? '12px 0 0 0' : '18px 0 9px 0',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  background: Colors.white,
  borderRadius: '8px',
  boxShadow: '-4px 4px 12px 0px rgba(50, 71, 84, 0.12)',
  alignItems: 'center',
  border: `1px solid ` + Colors.white,
  '& .title': {
    fontWeight: '600',
    color: Colors.darkBrown,
    marginTop: '6px'
  },
  '& .subtitle': {
    fontWeight: '400',
    color: Colors.veryLightBrown,
    direction: 'ltr'
  },
  '&.selected': {
    borderColor: Colors.yellowBorderLogin
  }
}))

export const OtpBox = styled(Box, {
  shouldForwardProp: (props: string) => !['isMobile'].includes(props)
})<{
  isMobile?: boolean
}>(({ isMobile }) => ({
  display: 'flex',
  gap: isMobile ? '2px' : '9px',
  direction: 'ltr',
  width: '100%',
  margin: isMobile ? 'none' : '0 25px',
  justifyContent: isMobile ? 'space-around' : 'center',
  '& .MuiInput-root': {
    '& input[type=number]': {
      '::-webkit-outer-spin-button, ::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0
      },
      MozAppearance: 'textfield'
    },
    borderBottom: 'none',
    '&:before': {
      borderBottom: 'none'
    },
    '&:after': {
      borderBottom: 'none'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: 'none'
    },
    '&.Mui-focused:after': {
      borderBottom: 'none'
    }
  },
  '& .MuiInput-input': {
    background: 'white',
    height: '56px',
    width: '48px'
  }
}))

export const TextFieldLogin = styled(TextField)({
  width: '100%',
  marginTop: '49px',
  borderRadius: '8px',
  background: '#FFF',
  boxShadow: '-3.462px 3.462px 10.386px 0px rgba(50, 71, 84, 0.12)',
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none'
    }
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiInputBase-root': {
    height: '48px',
    color: Colors.veryLightBrown
  },
  '& .MuiInputBase-input': {
    '&::placeholder': {
      color: Colors.veryLightBrown,
      opacity: 1
    }
  },
  '& input[type=number]': {
    MozAppearance: 'textfield'
  },
  '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  }
})

export const LoginRadio = styled(Radio)({
  padding: '0 0 0 16px',

  '& .MuiRadio-root': {
    color: Colors.veryLightBrown
  },
  '&.Mui-checked': {
    color: Colors.lightBlueRadio + '!important'
  },
  '& .MuiSvgIcon-root': {
    width: '16px',
    height: '16px'
  },
  '&:hover': {
    background: 'transparent'
  }
})

export const InitialLoginButton = styled(Button)({
  height: '48px',
  padding: '7px 0',
  color: Colors.darkBlue,
  borderRadius: '8px',
  background: 'var(--Button-gradient, linear-gradient(90deg, #8DBBDF 0%, #BBD6EC 50.52%, #8DBBDF 100%))',
  boxShadow: '-4px 4px 8px 0px rgba(50, 71, 84, 0.24)',
  '& .MuiTypography-root': {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: '28px'
  },
  '&.Mui-disabled': {
    background: Colors.disabledBlueLoginButton,
    boxShadow: '-4px 4px 8px 0px rgba(50, 71, 84, 0.12)',
    color: Colors.disabledLoginButtonText
  },
  '&.third-step-login': {
    width: '88.62%'
  }
})

export const RadioLoginLabel = styled(FormControlLabel)({
  margin: 'unset !important',
  display: 'flex',
  '& .MuiTypography-root': {
    fontFamily: 'Almoni, sans-serif !important',
    fontSize: '18px',
    fontWeight: '400',
    color: Colors.radioButtonBrown
  }
})

export const SecondStepLoginImg = styled('img')({
  width: '28px',
  height: '28px'
})
